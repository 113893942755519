import React, { useState, useEffect } from "react";
import axios from "axios";
import * as Style from "./TeamFilter.style";
import TabItem from "./TabItem";
import TabMenu from "./TabMenu";

const TeamFilter = (props) => {
  const [items, setItems] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [TabMenus, setTabMenus] = useState([]);

  const [showItemName, setShowItemName] = useState("All Classes");
  const [tabIndex, setTabIndex] = useState(0);

  // category fetch
  useEffect(() => {
    // category fetch
    axios({
      method: "get",
      url: process.env.REACT_APP_ADMIN_API_BASE_URL + "/coaches/categories",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        const allTabMenu = [
          "All",
          ...new Set(response.data.categories.map((curElem) => curElem.title)),
        ];
        setTabMenus(allTabMenu);
      })
      .catch((error) => {
        console.log(error);
      });
    // category fetch end

    // coaches fetch
    axios({
      method: "get",
      url: process.env.REACT_APP_ADMIN_API_BASE_URL + "/coaches/all",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        var rawItems = response.data.data.data;
        console.log(rawItems);
        setItems(rawItems);
        setAllItems(rawItems);
      })
      .catch((error) => {
        console.log(error);
      });
    // coaches fetch end
  }, []);

  const filterItem = (categItem, index) => {
    console.log(categItem);
    setShowItemName(categItem);
    setTabIndex(index);
    if (categItem === "All") {
      setItems(allItems);
    } else {
      const upadtedItems = allItems.filter((curElem) => {
        if (curElem.category_title.indexOf(categItem) !== -1) {
          return curElem;
        }
      });

      setItems(upadtedItems);
    }
    return;
  };

  return (
    <>
      <Style.FilterWrapper>
        <TabMenu
          filterItem={filterItem}
          TabMenus={TabMenus}
          TabName={showItemName}
          tabIndex={tabIndex}
        />
        <TabItem items={items} />
      </Style.FilterWrapper>
    </>
  );
};

export default TeamFilter;
