import React from 'react';
import { SimpleGrid, Box, Flex, Heading as Heading2, GridItem } from '@chakra-ui/react';
import Text from '../SubHeading/SubHeading';
import Heading from '../Heading/Heading';
import PriceCard3 from './PriceCard3';

const JustGymPricingWrapper = () => {
    return (
        <>
            <Box padding={{ sm: "0 0 30px", md: '0 0 50px' }}>
                <Heading title='Full Access' variant='extrasmall' />
                <Heading2 paddingTop='7px' textTransform="uppercase" letterSpacing='0px' fontSize={{ sm: "30px", md: "60px", lg: "90px", }} lineHeight="76%" fontFamily='Poppins'>JustGYM Memberships</Heading2>
                <SimpleGrid columns={{ sm: "1", md: "3" }} spacing={10} margin='30px 0px'>
                    <GridItem>
                        <Heading title='Windmill Lane Studio' variant='smallmid' padding='10px 0' />
                        <Text title='Our JustGYM facility is located at our Windmill Lane location' color='#000' />
                    </GridItem>
                    <GridItem>
                        <Heading title='Private Introduction' variant='smallmid' padding='10px 0' />
                        <Text title=' Each new member can avail of an introduction so you can understand how to use all the equipment safely' color='#000' />
                    </GridItem>
                    <GridItem>
                        <Heading title='Shower & Towel Service' variant='smallmid' padding='10px 0' />
                        <Text title='Grab your shower or sweat towel when you check in and enjoy our changing facilities after your workout' color='#000' />
                    </GridItem>
                </SimpleGrid>
            </Box>
            <SimpleGrid columns={{ sm: "1", md: "2" }} spacing={10}>
                <PriceCard3 key="22" offer="" cardTitle="" currency="€99/mo" planname="JustGYM - 3 Months Contract" description="Available at our Windmill location only. Our JustGYM membership offers you unlimited access to our JustGYM space.This JustGYM Membership is an initial 3 month contract from the start date; after that, you'll enjoy a rolling monthly membership where 30 days notice is required to cancel your membership. Please note, this membership is only valid in our Windmill Lane Location."
                    buttonTitle='Buy Now' link='https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=40&prodid=172' targetBlank='blank' />

                <PriceCard3 key="23" offer="" cardTitle="" currency="€89/mo" planname="JustGYM - 6 Months Contract" description="Available at our Windmill location only. Our JustGYM membership offers you unlimited access to our JustGYM space. This JustGYM Membership is an initial 6 month contract from the start date; after that, you'll enjoy a rolling monthly membership where 30 days notice is required to cancel your membership. Please note, this membership is only valid in our Windmill Lane Location."
                    buttonTitle='Buy Now' link='https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=40&prodid=171' targetBlank='blank' />

                <PriceCard3 key="24" offer="" cardTitle="" currency="€119/mo" planname="JustGYM - Rolling" description="Available at our Windmill location only. Our JustGYM membership offers you unlimited access to our JustGYM space. This membership agreement is rolling and can be cancelled anytime. Please note, this membership is only valid in our Windmill Lane Location."
                    buttonTitle='Buy Now' link='https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=40&prodid=170' targetBlank='blank' />

                <PriceCard3 key="25" offer="" cardTitle="" currency="€20/mo" planname="Drop in" description="This day pass which gives you full access to our JustGYM area. This space is only available our Windmill Lane location. This pass does not give you access to any studio, HYROX or CrossFit classes."
                    buttonTitle='Buy Now' link='https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=43&prodid=105435' targetBlank='blank' />
            </SimpleGrid>
            <Box padding={{ sm: "50px 0 10px", md: '70px 0 10px' }}>
                <Heading title='Full Access' variant='extrasmall' />
                <Heading2 paddingTop='20px' textTransform="uppercase" letterSpacing='0px' fontSize={{ sm: "30px", md: "60px", lg: "90px" }} lineHeight="76%" fontFamily='Poppins'>JustGYM + Studio Credit Memberships</Heading2>
            </Box>
            <SimpleGrid columns={{ sm: "1", md: "3" }} spacing={10} margin='30px 0px 50px'>
                <GridItem>
                    <Heading title='Windmill Lane Studio' variant='smallmid' padding='10px 0' />
                    <Text title='Our JustGYM facility is located at our Windmill Lane location' color='#000' />
                </GridItem>
                <GridItem>
                    <Heading title='Private Introduction' variant='smallmid' padding='10px 0' />
                    <Text title='Each new member can avail of an introduction so you can understand how to use all the equipment safely' color='#000' />
                </GridItem>
                <GridItem>
                    <Heading title='Shower & Towel Service' variant='smallmid' padding='10px 0' />
                    <Text title='Grab your shower or sweat towel when you check in and enjoy our changing facilities after your workout' color='#000' />
                </GridItem>
            </SimpleGrid>
            <SimpleGrid columns={{ sm: "1", md: "2", lg: "2" }} spacing={10}>
                <PriceCard3 key="26" offer="" cardTitle="" currency="€212/mo" planname="JustGym + 8 Studio Credits – 3 Months" description="This membership offers all the benefits of the JustGYM membership + 8 Studio Credits a month to take your training to a whole new level! Studio credits can be used at both locations - Windmill Lane & Lennox Street. Our JustGYM space is available at our Windmill location only and offers you unlimited access. "
                    buttonTitle='Buy Now' link='https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=40&prodid=174' targetBlank='blank' />

                <PriceCard3 key="27" offer="" cardTitle="" currency="€199/mo" planname="JustGym + 8 Studio Credits – 6 Months" description="This membership offers all the benefits of the JustGYM membership + 8 Studio Credits a month to take your training to a whole new level! Studio credits can be used at both locations - Windmill Lane & Lennox Street. Our JustGYM space is available at our Windmill location only and offers you unlimited access."
                    buttonTitle='Buy Now' link='https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=40&prodid=173' targetBlank='blank' />
            </SimpleGrid>
        </>
    )
}

export default JustGymPricingWrapper;