import React from 'react';
import InnerBanner from '../component/InnerBanner/InnerBanner';
import { SimpleGrid, Box, Flex, Heading as Heading2, GridItem, Container } from '@chakra-ui/react';
import Heading from "../component/Heading/Heading";
import Text from "../component/SubHeading/SubHeading";
import GiftCardWrapper from '../component/Pricing/GiftCardWrapper';
import MetaTitle from './MetaTitle';

const ChristmasGiftVouchers = () => {

    const metaTitle = "Christmas Gift Cards | Perpetua Fitness"
    const metaDesc = "Perpetua Fitness Christmas Gift Cards. Give the gift of fitness this Christmas. Choose from our range of options, or a custom amount!"

    return (
        <>
            <MetaTitle title={metaTitle} content={metaDesc} />
            <InnerBanner
                backgroundColor='#fff'
                backgroundImage='url(../images/chrismasImage.webp)'
                title='GIVE THE GIFT OF FITNESS'
                issubtextpara={false}
                isButton={false}
                issubtitleRight={false}
                isSubtext={false}
                width={{ sm: "90%", md: '70%', lg: '80%', xl: "100%" }}
            />

            <Box padding={{ sm: '60px 0 30px', md: '100px 0 40px' }}>
                <Container maxW='container.xl'>
                    <Heading2 textAlign='center' textTransform="uppercase" letterSpacing='0px' fontSize={{ sm: "30px", md: "60px", lg: "60px", }} lineHeight="1.2" fontFamily='Poppins'>Fitness Journey with Our Gym Training Gift Card!</Heading2>
                </Container>
            </Box>

            <Box backgroundImage='url(./images/mid-bg.jpg)' backgroundPosition='center' backgroundRepeat='no-repeat' backgroundSize='cover' >
                <Container maxW={{ md: 'container.lg', xl: 'container.xl' }}>
                    <SimpleGrid
                        columns={{ sm: '1', md: '2' }}
                        spacing={{ sm: '20px', md: '50px' }}
                        width="full"
                        padding={{ sm: '50px 10px', md: '80px 0px' }}
                    >
                        <GridItem position={{ md: 'sticky' }} top='100px' height={{ sm: 'auto', md: '200px' }}>
                            <Heading title='A Personalized Gym Training Experience!' color='white' />
                        </GridItem>
                        <GridItem paddingLeft={{ sm: '0', md: '20px' }} >

                            <Box>
                                <Heading title='Flexible Training' color='#D12C41' variant='medium' padding='5px 0px 0px' />
                                <Text title="Use the credits for any of our studio class within their expiry date" color='white' padding='10px 10px 10px 0' />
                            </Box>
                            <Box>
                                <Heading title='Perpetua Gift Cards' color='#D12C41' variant='medium' padding='5px 0px 0px' />
                                <Text title="Built for our hybrid world, Perpetua Training is an app available on all devices, encompassing your health, fitness and wellness needs from training at home or in the gym to customising your own nutrition." color='white' padding='10px 10px 10px 0' />
                            </Box>
                            <Box>
                                <Heading title='Flexible' color='#D12C41' variant='medium' padding='5px 0px 0px' />
                                <Text title="Use the credits for any of our studio class within their expiry date" color='white' padding='10px 10px 10px 0' />
                            </Box>
                            <Box>
                                <Heading title='Access to all studios' color='#D12C41' variant='medium' padding='5px 0px 0px' />
                                <Text title="Attend Tread & Shred, Rumble, Rythm Ride, Ride 45 & HYROX class" color='white' padding='10px 10px 10px 0' />
                            </Box>
                        </GridItem>
                    </SimpleGrid>
                </Container>
            </Box>
            <Box padding={{ sm: "50px 0", md: "70px 0", xl: '80px 0' }}>

                <Container maxW='container.xl'>
                    <GiftCardWrapper isHeading='true' />
                </Container>
            </Box>
        </>
    )
}

export default ChristmasGiftVouchers;