import React from 'react';
import { SimpleGrid, Box, Flex, Heading as Heading2, GridItem } from '@chakra-ui/react';
import Text from '../SubHeading/SubHeading';
import Heading from '../Heading/Heading';
import PriceCard3 from './PriceCard3';

const CrossFitPricingWrapper = () => {
    return (
        <>
            <Box padding={{ sm: "0 0 30px", md: '0 0 50px' }}>
                <Heading title='CrossFit Academy' variant='extrasmall' />
                <Heading2 paddingTop='7px' textTransform="uppercase" letterSpacing='0px' fontSize={{ sm: "30px", md: "60px", lg: "90px", }} lineHeight="76%" fontFamily='Poppins'>New Starters</Heading2>
                <SimpleGrid columns={{ sm: "1", md: "3" }} spacing={10} margin='30px 0px'>
                    <GridItem>
                        <Heading title='4 Week Course – 12 Sessions' variant='smallmid' padding='10px 0' />
                        <Text title='The CrossFit Academy is the perfect introduction to you starting your CrossFit journey.' color='#000' />
                    </GridItem>
                    <GridItem>
                        <Heading title='60 Minute Classes' variant='smallmid' padding='10px 0' />
                        <Text title='You will be in a new starter group were you will all be learning the foundational movements needed to train for CrossFit.' color='#000' />
                    </GridItem>
                    <GridItem>
                        <Heading title='Access To JustGYM Area' variant='smallmid' padding='10px 0' />
                        <Text title='During the 4 weeks, you also have full access to our JustGYM area if you would like to do some extra training.' color='#000' />
                    </GridItem>
                </SimpleGrid>
            </Box>
            <SimpleGrid columns={{ sm: "1", md: "2" }} spacing={10}>
                <PriceCard3 key="19" cardTitle="" currency="€215" offer="" planname="CROSSFIT ACADEMY" description="" buttonTitle='Enquire Now' link='/join-today' />
            </SimpleGrid>
            <Box padding={{ sm: "50px 0 10px", md: '70px 0 10px' }}>
                <Heading title='Full Access' variant='extrasmall' />
                <Heading2 paddingTop='20px' textTransform="uppercase" letterSpacing='0px' fontSize={{ sm: "30px", md: "60px", lg: "90px" }} lineHeight="76%" fontFamily='Poppins'>CrossFit Memberships</Heading2>
            </Box>
            <SimpleGrid columns={{ sm: "1", md: "2", xl: "4" }} spacing={10} margin='30px 0px'>
                <GridItem>
                    <Heading title='Unlimited CrossFit Classes' variant='smallmid' padding='10px 0' />
                    <Text title=' Over 55 CrossFit a week for you to choose from.' color='#000' />
                </GridItem>
                <GridItem>
                    <Heading title='Full Access To Our JustGYM Area' variant='smallmid' padding='10px 0' />
                    <Text title='Train in our JustGYM area.' color='#000' />
                </GridItem>
                <GridItem>
                    <Heading title='Full Access To Our Specialty Classes' variant='smallmid' padding='10px 0' />
                    <Text title='Choose between our olmplic weightlifting, gymnastics and mobility classes.' color='#000' />
                </GridItem>
                <GridItem>
                    <Heading title='Workshops & Community Events' variant='smallmid' padding='10px 0' />
                    <Text title='Attend our monthly workshops and community events.' color='#000' />
                </GridItem>
            </SimpleGrid>
            <SimpleGrid columns={{ sm: "1", md: "2" }} spacing={10}>
                <PriceCard3 key="17" cardTitle="" currency="€215" offer="" planname="12 Month Membership" description="12 Month Contract - After 12 month contract is finished, 30 day's notice to end contract" buttonTitle='Enquire Now' link='/join-today' />

                <PriceCard3 key="18" cardTitle="" currency="€230" offer="" planname="6 Month Membership" description="6 Month Contract - After 6 month contract is finished, 30 day's notice to end contract" buttonTitle='Enquire Now' link='/join-today' />

                <PriceCard3 key="16" cardTitle="" currency="€250" offer="" planname="Rolling Membership" description="60 day notice period to stop the membership" buttonTitle='Enquire Now' link='/join-today' />
            </SimpleGrid>
            <Box padding={{ sm: "50px 0 10px", md: '70px 0 10px' }}>
                <Heading title='Visting CrossFit Dublin' variant='extrasmall' />
                <Heading2 paddingTop='20px' textTransform="uppercase" letterSpacing='0px' fontSize={{ sm: "30px", md: "60px", lg: "90px" }} lineHeight="76%" fontFamily='Poppins'>Drop In </Heading2>
            </Box>
            <SimpleGrid columns={{ sm: "1", md: "2" }} spacing={10} margin='30px 0px'>
                <GridItem>
                    <Heading title='6 Months + experience in a CrossFit affiliate required' variant='smallmid' padding='10px 0' />
                    <Text title='Please us the button below to organize your drop in session.' color='#000' />
                </GridItem>
            </SimpleGrid>
            <SimpleGrid columns={{ sm: "1", md: "2" }} spacing={10}>
                <PriceCard3 key="20" cardTitle="" currency="€25" offer="" planname="1 DROP" description="" buttonTitle='Enquire Now' link='/join-today' />
            </SimpleGrid>
            <Box padding={{ sm: "50px 0 10px", md: '70px 0 10px' }}>
                <Heading title='1-1 training with our experienced coaches' variant='extrasmall' />
                <Heading2 paddingTop='20px' textTransform="uppercase" letterSpacing='0px' fontSize={{ sm: "30px", md: "60px", lg: "90px" }} lineHeight="76%" fontFamily='Poppins'>Personal Training</Heading2>
            </Box>
            <SimpleGrid columns={{ sm: "1", md: "2" }} spacing={10} margin='30px 0px'>
                <GridItem>
                    <Heading title='60 minutes Sessions' variant='smallmid' padding='10px 0' />
                    <Text title=' If you would like to work with a coach 1-1 please enquire through the section below.' color='#000' />
                </GridItem>
            </SimpleGrid>
            <SimpleGrid columns={{ sm: "1", md: "2" }} spacing={10}>
                <PriceCard3 key="21" cardTitle="" currency="€70" offer="" planname="PERSONAL TRAINING" description="" buttonTitle='Enquire Now' link='/join-today' />
            </SimpleGrid>
        </>

    )
}

export default CrossFitPricingWrapper;