import React, { useEffect } from 'react';
import Heading from '../component/Heading/Heading';
import { Box, Stack, Container, Flex, SimpleGrid, VStack, Link, Image, GridItem, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel } from '@chakra-ui/react';
import Text from '../component/SubHeading/SubHeading'
import WhatsincludedCard from '../component/WhatsIncluded Card/WhatsincludedCard';
import Testimonial from '../component/Testimonial/Testimonial';
import PriceBox from '../component/Price Component/PriceBox';
import MarqueLoop from '../component/Marque Loop/MarqueLoop';
import CfProgram from '../component/CF Program/CfProgram';
import ContactForm from '../component/Form/ContactForm';
import LinkButton from '../component/Button/LinkButton';
import Button from '../component/Button/CustomeButton';
import WhyMembershipCard from '../component/WhyMemberShip/WhyMembershipCard'
import MetaTitle from './MetaTitle';
import WOW from 'wowjs';
import VideoSection from '../component/VideoSection/VideoSection';
import VideoBanner from '../component/VideoBanner/VideoBanner';

const metaTitle="Luxury Gym Dublin | State of the Art Facility | Perpetua Fitness"
const metaDesc="JustGYM at Perpetua Fitness. State of the art training facility in Dublin. We are open 7 days a week. You can train anytime you like. Book a consult."




function JustGym(props) {

  useEffect(() => {
    new WOW.WOW({
      live: true,
      offset: 0,
      mobile: true,
    }).init();
  }, [])

  const marqueItem = ["Community", "Facility", "Access", "Programs", "Friendly", "JustGYM"];

  return (
    <>
      <MetaTitle title={metaTitle} content={metaDesc}/>
     
      <VideoBanner 
        videobanner='../video/Justgym-banner.mp4'
        subtitle='LUXURY GYM DUBLIN CITY CENTRE'
        title='JUST GYM'
        subtitleRight={<Box display='inline-block'><Image src='../images/map-white.svg' display='inline-block' top='12px' position='relative'></Image>Available at our Windmill Lane Facility</Box>}
        isButton={true} 
        issubtextpara={false} 
        buttontitle="Book a consult"
        margin="0px 0 0"
        Link="/join-today"
        videoModal='https://www.youtube.com/embed/pBP-UGi7jxI' 
        targetBlank={false}
        modalButtontitle="View The Experience"
        issubtitleRight={true} 
      />

      <Flex backgroundColor='black' padding="55px 0px 0px">
        <Container maxW='container.xl'>
          <MarqueLoop marqueItem={marqueItem}></MarqueLoop>
        </Container>
      </Flex>
      <Flex backgroundColor='black' padding="100px 0px 60px">
        <Container maxW='container.xl'>
          <Stack direction={{ base: 'column', md: 'row' }} spacing='30px' textAlign={{ base: 'left', lg: 'left' }} alignItems='center'>
            <Box w={{ sm: '100%', lg: '50%' }} padding={{ md: '0px 20px', lg: '0px 0px' }}>
              <PriceBox
                color='white'
                currency='€'
                priceValue='89'
                time="/mo Full Access"
                description='Arrange a call with our Head of JustGYM to get our induction to our equipment'
                buttonText='BUY NOW'
                link='/pricing'
                issubHeading={false}
                width={{ base: '100%', sm: '70%' }}
                border='2px solid'
                borderColor='Red'
                buttonPading='20px 25px'
                manheadind='Starting'
              >
              </PriceBox>
            </Box>
            <Box w={{ sm: '100%', lg: '50%' }} padding={{ md: '0px 20px', lg: '0px 0px' }}>
              <Heading title='WHATS INCLUDED ' variant='small' color='white' margin='0px 0px 30px' padding={{ sm: '0px 0px 0px 0px', md: '0px 0px 0px 31px' }} textAlign={{ sm: 'center', ms: 'left' }}></Heading>
              <SimpleGrid columns={{ base: '1', lg: '2' }} spacing='0px' className='_whatsinclude_box_body'>
                <Box className='_whatsinclude_box' padding={{ base: '15px 10px', xl: '20px' }}>
                  <WhatsincludedCard
                    Imguri='/images/gym.svg'
                    title='FULL ACCESS TO THE JUST GYM AREA'
                    headingColor='white'
                    buttonColor='white'
                    ButtonMargin='0px 0px 0px auto'
                    padding='0px'
                    spacing={props.padding}
                    alt="Luxury Gym Dublin - arrow icon"
                  >
                  </WhatsincludedCard >
                </Box>
                <Box className='_whatsinclude_box' padding={{ base: '15px 10px', xl: '20px' }}>
                  <WhatsincludedCard
                    Imguri='/images/gym.svg'
                    title='PRIVATE INTRODUCTION TO THE GYM'
                    headingColor='white'
                    buttonColor='white'
                    ButtonMargin='0px 0px 0px auto'
                    padding='0px'
                    spacing={props.padding}
                    alt="Luxury Gym Dublin - arrow icon"
                  >
                  </WhatsincludedCard >
                </Box>
                <Box className='_whatsinclude_box' padding={{ base: '15px 10px', xl: '20px' }}>
                  <WhatsincludedCard
                    Imguri='/images/gym.svg'
                    title='FREE 14 DAY TRIAL TO OUR ONLINE PROGRAMMING'
                    headingColor='white'
                    buttonColor='white'
                    ButtonMargin='0px 0px 0px auto'
                    padding='0px'
                    spacing={props.padding}
                    alt="Luxury Gym Dublin - arrow icon"
                  >
                  </WhatsincludedCard >
                </Box>
                <Box className='_whatsinclude_box' padding={{ base: '15px 10px', xl: '20px' }}>
                  <WhatsincludedCard
                    Imguri='/images/gym.svg'
                    title='SHOWER AND TOWEL SERVICES'
                    headingColor='white'
                    buttonColor='white'
                    ButtonMargin='0px 0px 0px auto'
                    padding='0px'
                    spacing={props.padding}
                    alt="Luxury Gym Dublin - arrow icon"
                  >
                  </WhatsincludedCard >
                </Box>
              </SimpleGrid>
            </Box>
          </Stack>
          <Stack direction={{ base: 'column', md: 'row' }} spacing='30px' textAlign={{ base: 'left', lg: 'left' }} alignItems='center'
           marginTop={{sm: "50px", md:"50px"}} borderTop='1px solid  rgba(255,255,255,0.15)' paddingTop="50px">
            <Box w={{ sm: '100%', lg: '50%' }} padding={{ md: '0px 20px', lg: '0px 0px' }}>
              <PriceBox
                color='white'
                manheadind='Starting'
                currency='€'
                priceValue='199'
                time=" /mo JustGYM+8 Studio Credits"
                description='Arrange a call with our Head of JustGYM to get an induction to our equipment'
                buttonText='BUY NOW'
                link='/pricing'
                issubHeading={false}
                width={{ base: '100%', sm: '70%' }}
                border='2px solid'
                borderColor='Red'
                buttonPading='20px 25px'
              >
              </PriceBox>
            </Box>
            <Box w={{ sm: '100%', lg: '50%' }} padding={{ md: '0px 20px', lg: '0px 0px' }}>
              <Heading title='WHATS INCLUDED ' variant='small' color='white' margin='0px 0px 30px' padding={{ sm: '0px 0px 0px 0px', md: '0px 0px 0px 31px' }} textAlign={{ sm: 'center', ms: 'left' }}></Heading>
              <SimpleGrid columns={{ base: '1', lg: '2' }} spacing='0px' className='_whatsinclude_box_body'>
                <Box className='_whatsinclude_box' padding={{ base: '15px 10px', xl: '20px' }}>
                  <WhatsincludedCard
                    Imguri='/images/gym.svg'
                    title='FULL ACCESS TO THE JUST GYM AREA'
                    headingColor='white'
                    buttonColor='white'
                    ButtonMargin='0px 0px 0px auto'
                    padding='0px'
                    spacing={props.padding}
                    alt="Luxury Gym Dublin - arrow icon"
                  >
                  </WhatsincludedCard >
                </Box>
                <Box className='_whatsinclude_box' padding={{ base: '15px 10px', xl: '20px' }}>
                  <WhatsincludedCard
                    Imguri='/images/gym.svg'
                    title='PRIVATE INTRODUCTION TO THE GYM'
                    headingColor='white'
                    buttonColor='white'
                    ButtonMargin='0px 0px 0px auto'
                    padding='0px'
                    spacing={props.padding}
                    alt="Luxury Gym Dublin - arrow icon"
                  >
                  </WhatsincludedCard >
                </Box>
                <Box className='_whatsinclude_box' padding={{ base: '15px 10px', xl: '20px' }}>
                  <WhatsincludedCard
                    Imguri='/images/gym.svg'
                    title='Shower and Towel Services'
                    headingColor='white'
                    buttonColor='white'
                    ButtonMargin='0px 0px 0px auto'
                    padding='0px'
                    spacing={props.padding}
                    alt="Luxury Gym Dublin- arrow icon"
                  >
                  </WhatsincludedCard >
                </Box>
                <Box className='_whatsinclude_box' padding={{ base: '15px 10px', xl: '20px' }}>
                  <WhatsincludedCard
                    Imguri='/images/gym.svg'
                    title='8 Studio Credits per month'
                    headingColor='white'
                    buttonColor='white'
                    ButtonMargin='0px 0px 0px auto'
                    padding='0px'
                    spacing={props.padding}
                    alt="Luxury Gym Dublin - arrow icon"
                  >
                  </WhatsincludedCard >
                </Box>
              </SimpleGrid>
            </Box>
          </Stack>
        </Container>
      </Flex>
     
      <Box padding={{ sm: '0px 0px 50px', md: '30px 0px 100px' }} backgroundColor='black'>
        <Container maxW='container.xl'>
          <Heading
            variant="extralarge"
            title='How to get started'
            margin={{ sm: '0px auto 50px', md: '0px auto 86px' }}
            width={{ base: '90%', md: '60%' }}
            textAlign='center'
            lineHeight='1'
            color='white'
          />
          <SimpleGrid
            columns={{ sm: '1', md: '3' }}
            spacing={5}
            textAlign='center'
            justifyContent='center'
          >
            <GridItem>
              <Heading title='01' color='Red' textAlign='center' />
              <Heading
                title='POP INTO OUR FACILITY'
                color='white'
                variant='small'
                padding={{ sm: '15px 20px', md: '15px 20px 55px' }}
              />
              <Text
                title='We would love to meet you at our Windmill facility and show you around our facility. We can then set up your membership or you can purchase a membership by downloading the Perpetua Fitness App.'
                color='white'
                padding='15px 20px'

              />
            </GridItem>
            <GridItem>
              <Heading title='02' color='Red' textAlign='center' />
              <Heading
                title='SET UP THE INTRODUCTION'
                color='white'
                variant='small'
                padding={{ sm: '15px 20px', md: '15px 20px 55px' }}
              />
              <Text
                title='Prior to starting your first session, a member of Perpetua can bring you through all of the equipment in the JustGYM so you can understand how to use everything correctly.'
                padding='15px 20px'
                color='white'
              />
            </GridItem>
            <GridItem>
              <Heading title='03' color='Red' textAlign='center' />
              <Heading
                title='FOLLOW OUR ONLINE TRAINING PROGRAMS'
                color='white'
                variant='small'
                padding='15px 20px'
              />
              <Text
                title="What's next? Try one of our online training programs with a 14-day free trial before committing. Designed by our Head Coach, Eoghan McGregor, these programs are suitable for all fitness levels."
                color='white'
                padding='15px 20px'
              />
            </GridItem>
          </SimpleGrid>
        </Container>
      </Box>

      <Container maxW='container.xl' padding={{ sm: '60px 30px 0px', md: '80px 30px 0px' }}>
        <Stack direction={{ base: 'column', md: 'row' }} spacing='30px' textAlign={{ base: 'left', lg: 'left' }} alignItems='top' paddingBottom={{ sm: '40px', md: '80px' }}>
          <Box w={{ lg: '50%' }} position={{ base: 'relative', md: 'sticky' }} top={{ base: '0', md: '100px' }} height={{ base: 'auto', md: '150px' }} textAlign={{ sm: 'center', md: 'left' }}>
            <Heading title='WHATS INCLUDED IN OUR MEMBERSHIP' lineHeight='1'></Heading>
          </Box>
          <Box width={{ md: '50%', lg: '50%' }}>

            <SimpleGrid
              columns={{ sm: '1', lg: '2' }}
              spacing={8}
            >
              <WhyMembershipCard Imguri='/images/check.png' title='UNLIMITED JUSTGYM ACCESS' paragraph='We are open 7 days a week and with your JustGYM membership you can train anytime you like. Get ready to enjoy your training session and experience a facility which has everything you need to achieve your goals' />
              <WhyMembershipCard Imguri='/images/check.png' title='14 DAY FREE TRIAL FOR ONLINE PROGRAMMING ' paragraph="What's next? Try one of our online training programs with a 14-day free trial before committing. Designed by our Head Coach, Eoghan McGregor, these programs are suitable for all fitness levels." />
              <WhyMembershipCard Imguri='/images/check.png' title='EQUIPMENT & FACILITY WALK THROUGH ' paragraph='Prior to starting your first session, a member of Perpetua can bring you through all of the equipment in the JustGYM so you can understand how to use everything correctly.' />
              <WhyMembershipCard Imguri='/images/check.png' title='SHOWER AND TOWEL SERVICES ' paragraph='We have showers in both men’s and women’s changing rooms. We also provide shampoo, conditioner, body wash, hair dryers & straighteners (plus a few extra goodies you might need to replenish after your sweaty session with us!)' />
            </SimpleGrid>
          </Box>
        </Stack>
      </Container>

      <VideoSection heading='A Premium' headingSub='training experience' video1='https://youtube.com/embed/T4iPSAYkivo?si=grUvWuNR1y17YwMV' video2='https://youtube.com/embed/pBP-UGi7jxI?si=lIWQERQEXDm1CfUm' smallHeading='Take a tour' smallHeading2='Whats Included' midHeading='' midHeading2=''/>

      {/*  */}
      <Flex flexDirection='column' padding={{ sm: '10px 0px ', md: '20px 0px' }} marginTop='50px' position="relative">
        <Container maxW='container.xl'>
          <Testimonial headingWidth={{ sm: '97%', md: '90%' }} iconUri='/images/comment-quote-outline.svg' isname={true}
            title='“THE JUSTGYM FACILITY HAS EVERYTHING I NEED TO GET A GREAT SESSION IN. I ALSO FOUND THE INITIAL CONSULTATION VERY HELPFUL SO I UNDERSTOOD HOW TO USE ALL THE MACHINES”' name='- JUSTGYM MEMBER' />
        </Container>
      </Flex>

      <Container maxW={{ sm: 'Container.sm', md: 'container.md', lg: 'container.lg', xl: 'container.xl' }} p={{ sm: '50px 15px 30px', md: '90px 15px 70px' }}>
        <Stack direction={{ base: 'column', md: 'row' }} spacing='30px' textAlign={{ base: 'left', lg: 'left' }} alignItems='top'>
          <Box w={{ base: '100%', lg: '50%' }} position={{ base: 'relative', md: 'sticky' }} top={{ base: '0', md: '100px' }} height={{ base: 'auto', md: '522px', lg: '580px' }}
          >
            <Heading as='h2' title='OUR ONLINE PROGRAMS' variant="extralarge" margin='0px 0px 40px' lineHeight='1' width={{ sm: "100%", md: '80%' }} ></Heading>
          </Box>
          <Box w={{ base: '100%', lg: '50%' }} padding={{ sm: '0', lg: '0px 40px' }}>

            <Heading as='h5' title='WE KNOW THAT PROGRAMMING IS NOT ONE SIZE FITS ALL' variant="smallmid" margin='0px 0px 15px'></Heading>
            <Text as='p' title='With years of experience and coaching thousands of members, the team at CrossFit Dublin have developed 
              programs to suit all fitness levels. You can start any of the programs with a 14 day free trial.'></Text>

            <VStack spacing={2} align='stretch' width={"full"} marginTop='25px' >
              <Stack p='0px 0px 50px' borderBottom='1px solid #D9D9D9'>
                <CfProgram
                  title='Perpetua Strength & Conditioning'
                ></CfProgram>
                <Text title='Perpetua Strength & Conditioning is our signature program which has helped 1000’s of members. This program is designed for individuals who have previous experience in compound lifting and conditioning.
                 This is for people who enjoy a mixture of strength and cardio training, and need to keep their training window to 60 min or less. In our 60 path, we structure your workout from warmup to cool down to allow you to be as productive as possible. The training itself will look like classic Crossfit. A balance of strength work, skill progressions, mixed modal testers while avoiding heavy Olympic Lifting. '
                  margin='0px 0px 15px' color='Grey'></Text>

              </Stack>

              <Stack p='50px 0px 50px' borderBottom='1px solid #D9D9D9'>
                <CfProgram
                  title='Perpetua Endurance'
                >
                </CfProgram>
                <Text title="Endurance days at Perpetua Fitness are a favorite among our members, and now we're bringing them to you online. "
                  margin='0px 0px 15px' color='Grey'></Text>
                <Text title='This program is designed for those looking to develop a broad endurance base across multiple modalities. Following this path will prepare you and boost your confidence to tackle any conditioning event or workout.' margin='0px 0px 15px' color='Grey'></Text>
              </Stack>

              <Stack p='50px 0px 50px'>
                <CfProgram
                  title='Perpetua Build'
                >
                </CfProgram>
                <Text title='Our endurance days in Perpetua Fitness are one of our most popular days with members and now we are delivering this to individuals online. '
                  margin='0px 0px 15px' color='Grey'></Text>
                <Text title='Not every training day requires high intensity, making our Perpetua Build program perfect for you. Perpetua Build is an exclusive functional strength and full-body bodybuilding program available only at Perpetua Fitness. These workouts emphasize a controlled burn and aesthetics over intensity. Each session includes functional strength training followed by a bodybuilding segment. The goal of Perpetua Build is simple – all gains, zero stress.' margin='0px 0px 15px' color='Grey'></Text>
              </Stack>
            </VStack>
          </Box>
        </Stack>
      </Container>

      <Flex
        flexDirection='column'
        padding={{ sm: '40px 0px 45px', md: '100px 0px 100px' }}
        textAlign='center'
        justifyContent='center'
        justifyItems='center'
        background='#eee'
      >
        <Container maxW='container.xl'>
          <Box>
            <Heading
              as="h2"
              title="14 day free trial"
              variant='extralarge'
              textAlign="center"
              lineHeight="1"
              padding={{ base: '0px 0px 15px', sm: '0px 0px 30px' }}
            >
            </Heading>
            <Text
              title="Trial our online programs for free for 14 days"
              as='p'
            >
            </Text>
            <Link href='online-gym-programming' _hover={{ textDecoration: 'none' }}><Button title='Get Started' color='black' margin='40px 0px 0px' className='wow animate fadeInUp' /> </Link>
          </Box>
        </Container>
      </Flex>
   
      {/*  */}
      <Flex p={{ sm: '50px 0px', md: '80px 0px 90px' }}>
        <Container maxW={{ sm: 'Container.sm', md: 'container.lg', lg: 'container.lg', xl: 'container.xl' }}>
          <Stack direction={{ sm: 'column', md: 'row' }} spacing='24px'>
            <Box width={{ sm: '100%', md: '50%' }}>
              <Box paddingLeft='30px' position={'relative'} _after={{ position: 'absolute', content: '""', width: '3px', height: '100%', top: '0', left: '0', background: 'Red' }}>
                <Heading title='FREQUENTLY ASKED QUESTIONS' lineHeight='1' padding='0px 0px 30px' margin='0px auto' ></Heading>
                <Text title='Questions about the workout or our gyms? We got you. Check out the FAQ below or contact us.' width='80%' ></Text>
              </Box>
            </Box>
            <Box width={{ sm: '100%', md: '50%' }}>
              <Accordion defaultIndex={[0]} allowMultiple className='Accor_item _black_acording  white_acording'>
                <AccordionItem >
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title='If I have a studio pack can I also use the JustGYM area?' variant='extrasmall'></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title='Studio packs are for use in our studios only. But on a JustGYM+ membership, you also receive 8 credits per month that you can use!' margin='0px 0px 20px'></Text>

                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title="What length of memberships do you offer for JustGYM?" variant='extrasmall'></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title='We offer JustGYM Memberships for an initial 3/6-month contract from the start date; after that, you will enjoy a rolling monthly membership where 30 days notice is required to cancel your membership. We also offer a rolling membership which you can cancel anytime.' margin='0px 0px 20px'></Text>

                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title='What do I get for my monthly membership fee?' variant='extrasmall'></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title=" You get access to our JustGYM space anytime Perpetua is open! Additionally, new JustGYM members get a complimentary consultation with a trainer who will help you put together a program." margin='0px 0px 20px'></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title="How do I become a JustGYM member?" variant='extrasmall'></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title={<Box>Please pop out JustGYM team an email to get started<Link href='mailto:justgym@perpetua.ie'> justgym@perpetua.ie</Link></Box>} margin='0px 0px 20px'></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title="What are your hours of operation?" variant='extrasmall'></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title='WML: Monday-Thursday 6am-9pm | Friday 6am-8pm | Saturday-Sunday 8am-2pm' margin='0px 0px 20px'></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title="Do you have a place I can securely leave my stuff while I work out?" variant='extrasmall'></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title="We have lockers in our men’s and women’s changing rooms where you can leave your belongings. Each locker allows you to create a single-use code while you’re in the facility." margin='0px 0px 20px'></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title=" Do you have showers?" variant='extrasmall'></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title=' Yes, we have showers in both men’s and women’s changing rooms. We also provide shampoo, conditioner, body wash, hair dryers & straighteners (plus a few extra goodies you might need to replenish after your sweaty session with us!)' margin='0px 0px 20px'></Text>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Box>
          </Stack>
        </Container>
      </Flex>

      {/*  */}

      <VStack backgroundColor='black' padding={{ sm: '50px 0px', md: '96px 0px' }} borderBottom='1px solid #232323'>
        <Container
          display='flex'
          maxW='container.xl'
          flexDirection={{ base: 'column', md: 'row' }}
          alignItems='top'
          alignContent='center'
          justifyContent='center'
          justifyItems='center'
        >
          <Box w={{ sm: '100%', md: '50%', lg: '40%' }} textAlign={{ base: 'center', lg: 'left' }} marginBottom={{ base: '50px', lg: '0' }}>
            <Heading title='LOOKING FOR MORE INFORMATION' variant='extralarge' lineHeight='1' margin='0px 0px 40px' color='white'></Heading>
            <Heading title='Chat with a member of the team ' variant='smallmid' lineHeight='1' margin='0px 0px 30px' color='white'></Heading>
            <Link href='/join-today' textDecoration='none' display='inline-block' _hover={{ textDecoration: 'none' }}>
              <LinkButton
                title='Enquire Now'
                Imguri='/images/red_arrow.svg'
                border='2px solid' borderColor='Red'
                padding='20px 25px'
                color='White'
              ></LinkButton>
            </Link>
          </Box>
          <Box w={{ sm: '100%', md: '50%', lg: '60%' }}>
            <ContactForm pageName="JustGym"></ContactForm>
          </Box>
        </Container>
      </VStack>

    </>
  );
}

export default JustGym;