import React from "react";
import { Flex } from '@chakra-ui/react';
import * as Style from './PodcastCard.style';
import Heading from "../Heading/Heading";
import Text from '../SubHeading/SubHeading';
function PodcastCard(props) {

    var publishDate = new Date(props.publishDate);

    const monthNames = [
        "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
    ];

    var publishDateString = monthNames[(publishDate.getMonth() + 1)] + ' ' 
    + publishDate.getDate() + ', ' 
    + publishDate.getFullYear();

    return (

        <Style.PodcastImage
            backgroundImage={props.bg}
            backgroundColor={props.backgroundColor}
            minHeight={props.minHeight}
        >

            <Style.PodcastImageInfo >
                <Heading title={props.title} cfSize={{ sm: '57px', md: '70px' }} width={{ sm: '53%', lg: '45%', xl: "65%" }} lineHeight={0.9} color='white'></Heading>
            </Style.PodcastImageInfo>
            <Style.PodcastImageContent>

                <Style.PodcastContent>
                    <Text padding='0px 0px 17px' title={props.subtitle} color='white' width='90%' ></Text>
                </Style.PodcastContent>
                <Flex>
                    <Text title={publishDateString} textTransform="uppercase" color="#DC143C" fontSize="11px" padding="0px" />
                    <Text title=" - " fontSize="13px" color="#DC143C" padding="0px 5px" />
                    <Text title={props.authorName} fontSize="11px" color="white" padding="0px" />
                </Flex>

            </Style.PodcastImageContent>

        </Style.PodcastImage>
    )
}

export default PodcastCard;