import React from 'react'
import * as Style from './GuestListing.style';

function FaqBox(props) {
  return (
    <Style.faqBox allowToggle>
        <Style.faqItem >
          <Style.itemButton>
              <Style.h2 variant='extrasmall' title={props.title}/>
              <Style.itemIcon/>
          </Style.itemButton>
          <Style.itemArea>
            <Style.description title={props.para}/>
            <Style.linkWrapper>
              {props.linkPara && <Style.description title={props.linkPara}/>}
              {props.linkTitle && <Style.link href={props.pageLink}>{props.linkTitle}</Style.link>}
            </Style.linkWrapper>
          </Style.itemArea>
        </Style.faqItem>
    </Style.faqBox>
  )
}

export default FaqBox