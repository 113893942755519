import React from 'react';
import * as Style from './PodcastEpisode.style';
import { Box, Container, SimpleGrid,Link } from '@chakra-ui/react';
import Heading from '../Heading/Heading';
import PodcastCard from './PodcastCard';
import Button from '../Button/CustomeButton';
function PodcastEpisode(props) { 

    return (
        <>
            <Style.PodcastEpisodeInfo>
                <Container maxW='container.xl'>
                    <Box>
                        <Heading title={props.title} cfSize={{ sm: '40px', md: '60px' }} lineHeight='1' textAlign='center' />
                    </Box>
                    <SimpleGrid columns={{ sm: '1', md: '2', xl: '3' }} spacing={6} padding='40px 0px 0px'>

                        {props.podListItem.map((item, index) => {

                            var img_path = '/images/no-image.jpg';
                            if (item.image_path !== 'null') { var img_path = item.image_path; }

                            return (
                                <Link href={/blog/+item.slug}  key={index}>
                                    <PodcastCard
                                        backgroundColor="black"
                                        bg={img_path}
                                        subtitle={item.title}
                                        // title={<Box>{item.title.substring(0, 22)}<span style={{ color: '#D12C41' }}>!</span></Box>}
                                        title=' '
                                        authorName={item.excerpt}
                                        publishDate={item.created_at}
                                        minHeight={{ sm: '450px', md: '450px' }}
                                    />
                                </Link>
                            )
                        })
                        }



                        {/* <PodcastCard
                            backgroundColor="black"
                            bg='url(./images/podcastlisting.png)'
                            subtitle='The intro ep. 96 — max & james | the boys are back!'
                            title={<Box>THE BOYS ARE BACK<span style={{ color: '#D12C41' }}>!</span></Box>}
                            minHeight={{ sm: '450px', md: '450px' }}
                        />
                        <PodcastCard
                            backgroundColor="black"
                            bg='url(./images/podcastlisting.png)'
                            subtitle='The intro ep. 96 — max & james | the boys are back!'
                            title={<Box>THE BOYS ARE BACK<span style={{ color: '#D12C41' }}>!</span></Box>}
                            minHeight={{ sm: '450px', md: '450px' }}
                        />
                        <PodcastCard
                            backgroundColor="black"
                            bg='url(./images/podcastlisting.png)'
                            subtitle='The intro ep. 96 — max & james | the boys are back!'
                            title={<Box>THE BOYS ARE BACK<span style={{ color: '#D12C41' }}>!</span></Box>}
                            minHeight={{ sm: '450px', md: '450px' }}
                        /> */}

                    </SimpleGrid>
                    <Style.PodcastEpisodeView>
                        <Heading title='LOOKING FOR MORE?' variant='small' padding='0px 0px 20px' />
                        <Link href="/blog/category/podcast">
                            <Button title='View All' color='black' />
                        </Link>
                    </Style.PodcastEpisodeView>
                </Container>
            </Style.PodcastEpisodeInfo>

        </>
    )
}
export default PodcastEpisode;