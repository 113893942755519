import {  Box,Image} from "@chakra-ui/react";
import styled from "@emotion/styled/macro";

export const  ImageContainer = styled(Box)(props => ({

    width:'100%',
    height:'450px',
    position:'relative',
    display:'flex',
    flexDirection:'column',
    ":after":{
        position:'absolute',
        width:'100%',
        height:'200px',
        bottom:'30px',
        left:'0',
        margin:'auto',
        background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%)',
        content:'""',

        "@media screen and (max-width:767px)": {
            bottom:'0px',
        },
    },

    

    "@media screen and (max-width:767px)": {
        marginBottom:'20px',
        height:'550px',
    },
    "@media screen and (max-width:480px)": {
        height:'450px',
    }

}))


export const  ImagePlace = styled(Image)(props => ({
    objectFit:'cover',
    objectPosition:'center',
    width:'100%',
    height:'100%',
}))

export const  ImageHeading = styled(Box)(props => ({
    width:'100%',
    position:' absolute',
    zIndex: '1',
    bottom: '80px',
    left: 0,
    right: 0,
    margin:' auto',
    textAlign:'center',
    position:"relative",
    zIndex:"11"
}))

export const  ImageBox = styled(Box)(props => ({
    width:'100%',
}))