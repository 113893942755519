import React from 'react';
import { Box, Container, Link, Text } from '@chakra-ui/react';
import * as Style from './LandingStyle.style';

function UnbrokenPoweredByWhoop(props) {
    return (
        <>
            <Style.PageBanner backgroundImage={{ sm: 'url(./images/landing-page-desktop.webp)', md: 'url(./images/landing-page-desktop.webp)' }} href="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=107884" target='_blank'></Style.PageBanner>

            <Style.CenterContent>
                <Container maxW={{ md: 'container.lg', xl: 'container.xl' }}>
                    <Style.CenterContentWrapper marginBottom='20px'>
                        <Style.CenterContentHeader>
                            <Style.h2 variant='medium' title='Get ready to go ‘Unbroken Powered by WHOOP’ MARCH 2-9  ' />
                            <Style.p title='8 Studio Classes. 8 Days. Unlock Yourself.' fontSize={{ lg: '16px', xl: '18px' }} />
                        </Style.CenterContentHeader>
                        <Style.p title='Complete the challenge and get a discounted WHOOP Membership Extension as well as exclusive UNBROKEN X WHOOP goodies as a reward. '></Style.p>
                        <Style.p title='This is one you don’t want to miss.'></Style.p>
                    </Style.CenterContentWrapper>
                    <Style.pageButton href='https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=107884' target='_blank'>I’m ready</Style.pageButton>
                </Container>
            </Style.CenterContent>

            <Style.midContent>
                <Container maxW={{ md: 'container.lg', xl: 'container.xl' }}>
                    <Style.midcontentWrapper>
                        <Style.imageBox>
                            <Style.image alt='image' src="./images/mid-content-img.webp" />
                        </Style.imageBox>
                        <Style.contentBox>
                            <Style.h2 variant='small' title='Want to know what it takes to complete the challenge and earn your reward? ' marginBottom='20px' />
                            {/* <Style.description title={props.subHeading} fontSize={{lg:'16px', xl:'18px'}}/> */}
                            <Style.pointList>
                                <Style.pointlistItem>Grab your Unbroken Challenge Pack  <Link href="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=107884" textDecoration='underline' target='_blank'>here</Link> and use it to book into class every day from March 2nd – 9th   </Style.pointlistItem>
                                <Style.pointlistItem>If you’re new to WHOOP, we’re giving you 1 month free <Link href="https://join.whoop.com/en-eu/perpetua" textDecoration='underline' target='_blank'>here</Link> Make sure you sign up at least 7-10 days prior to the challenge start date so you can get all the insights in relation to your Recovery, Strain & Sleep throughout the challenge.  </Style.pointlistItem>
                                <Style.pointlistItem>Once you’ve purchased your Challenge Pack, keep an eye on your email for details on joining our Unbroken Challenge WHOOP Community! </Style.pointlistItem>
                                <Style.pointlistItem>HIIT the studio every day for 8 days and get your Unbroken stamp card marked for each class that you do (the important part!). </Style.pointlistItem>
                                <Style.pointlistItem>We’ll have WHOOP & Perpetua spot prizes over the 8 days based on the highest Recovery, Strain & Sleep score in our WHOOP Community Group each day.  </Style.pointlistItem>
                                <Style.pointlistItem>If you complete all 8 days, you’ll receive your UNBROKEN x WHOOP goodies & your WHOOP Membership extension code redeemable online.  </Style.pointlistItem>
                                <Style.pointlistItem>For everyone that takes part, we’ll have our Recovery station from 9am in Windmill Lane on the final day so you can relax, recover and reflect on your 8 days in the Studio. </Style.pointlistItem>
                            </Style.pointList>
                            <Style.pageButton href='https://join.whoop.com/en-eu/perpetua' target='_blank' marginTop='25px'>Get 1 month free of WHOOP</Style.pageButton>
                        </Style.contentBox>
                    </Style.midcontentWrapper>
                </Container>
            </Style.midContent>

            <Style.faqSection>
                <Container maxW={{ md: 'container.lg', xl: 'container.xl' }}>
                    <Style.h2 variant='extralarge' title='FAQS' textAlign='center' />
                    <Style.p title='Check out our FAQ below for all the juicey details:' fontSize='18px' paddingBottom='30px' textAlign='center' />

                    <Style.faqBox allowToggle>
                        <Style.faqItem >
                            <Style.itemButton>
                                <Style.h2 variant='extrasmall' title="What is Unbroken? " />
                                <Style.itemIcon />
                            </Style.itemButton>
                            <Style.itemArea>
                                <Text fontFamily='Poppins' fontSize='16px'>A challenge for your mind & body (with a spicey reward!). A challenge that originated back in 2020 which encourages you to smash 8 studio classes in 8 days. Your Unbroken Challenge pack includes Tread & Shred, Ride, Rumble and HYROX! The perfect way to add some motivation into your fitness routine. Get sweaty and challenge yourself in different classes and get to know new coaches.  </Text>
                                <Text fontFamily='Poppins' fontSize='18px' fontWeight='bold' marginTop='15px'>How it works:   </Text>
                                <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px'>Grab your UNBROKEN Class pack - 8 credits for €98 <Link href="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=107884" textDecoration='underline' target='_blank'>here</Link>  and use it to book into one class each day from March 2-9 This is the perfect chance to try out different classes and coaches. Commit to yourself and smash it!  </Text>                    
                                <Text fontFamily='Poppins' fontSize='16px' fontWeight='500' marginBottom='10px'><span fontSize='18px' style={{fontFamily:"Poppins", fontWeight:"bold"}}>See how far you can get in the 8-day challenge! Participants get access to our</span> Recovery station from 9am in Windmill Lane on the final day so you can relax, recover and reflect on your 8 days in the Studio. </Text>                       
                                <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px'>Those who complete all 8 days, will receive UNBROKEN x WHOOP goodies & a WHOOP Membership extension code redeemable online. </Text>
                                <Text fontFamily='Poppins' fontSize='16px' fontWeight='bold' marginBottom='15px'>To participate in the challenge, you must have an Unbroken class pack.  </Text>   
                            </Style.itemArea>

                        </Style.faqItem>

                        <Style.faqItem >
                            <Style.itemButton>
                                <Style.h2 variant='extrasmall' title="Do I need WHOOP to participate in Unbroken?" />
                                <Style.itemIcon />
                            </Style.itemButton>
                            <Style.itemArea>
                                <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px'>It’s not necessary but we recommend that you use WHOOP throughout the Unbroken Challenge. WHOOP is a wearable personalized coach that tracks your sleep, strain, and recovery. Join the Unbroken WHOOP community to see how your strain stacks up against fellow Unbroken Participants each time you HIIT the Studio. </Text>
                                <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px'>Download the WHOOP and get a 1-month free trial (with device included!) to track your challenge and optimize your recovery.  </Text>
                            </Style.itemArea>
                        </Style.faqItem>

                        <Style.faqItem >
                            <Style.itemButton>
                                <Style.h2 variant='extrasmall' title="Can I use my regular credits to take part?  " />
                                <Style.itemIcon />
                            </Style.itemButton>
                            <Style.itemArea>
                                <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px'>Only classes attended under your 8 unbroken credits will count towards the challenge. All other studio credit packs and studio memberships will be put on hold while you complete the challenge.  </Text>
                                <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px' fontWeight='500'>Save your regular credits for after!  </Text>
                            </Style.itemArea>
                        </Style.faqItem>

                        <Style.faqItem >
                            <Style.itemButton>
                                <Style.h2 variant='extrasmall' title="What do I get for completing all 8 Days?  " />
                                <Style.itemIcon />
                            </Style.itemButton>
                            <Style.itemArea>
                                <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px'>(Apart from bragging rights)   </Text>
                                <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px'>Those who complete all 8 days, will receive UNBROKEN x WHOOP goodies & a WHOOP Membership extension code redeemable online.    </Text>
                                <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px'><span style={{fontFamily:"Poppins", fontWeight:"bold"}}>Participants get access to our </span> Recovery station from 9am in Windmill Lane on the final day so you can relax, recover and reflect on your 8 days in the Studio.    </Text>
                            </Style.itemArea>
                        </Style.faqItem>
                    </Style.faqBox>

                </Container>
            </Style.faqSection>

            <Style.CenterContent>
                <Container maxW={{ md: 'container.lg', xl: 'container.xl' }}>
                    <Style.CenterContentWrapper>
                        <Style.CenterContentHeader>
                            <Style.h2 variant='medium' title='TERMS & CONDITIONS' />
                            <Text fontFamily='Poppins' fontSize='18px' marginBottom='10px' marginTop='20px' fontWeight='700'>Do you accept the challenge? </Text>
                            <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px' textAlign='left' >UNBROKEN Class Packs are valid for use from 2-9 March. Credits cannot be extended or paused. You must use the UNBROKEN Class Credits to participate in the challenge.  Any other studio credit packs and studio memberships will be paused while you complete the challenge.</Text>
                            <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px' textAlign='left' >Challenge pack valid for use in Ride, Tread & Shred, Rumble & HYROX classes in both Windmill Lane and Lennox Street locations. </Text>
                            <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px' textAlign='left' >Get 1 month free of WHOOP, rolling membership thereafter. </Text>
                            <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px' textAlign='left' >Challenge pack valid for use in Ride, Tread & Shred, Rumble & HYROX classes in both Windmill Lane and Lennox Street locations.</Text>
                        </Style.CenterContentHeader>
                    </Style.CenterContentWrapper>
                </Container>
            </Style.CenterContent>
        </>
    )
}

export default UnbrokenPoweredByWhoop;