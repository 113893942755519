import React from 'react';
import GuestList from '../component/LandingPage/GuestList'

const LandingPage = () => {
  return (
    <div>
        <GuestList/>
    </div>
  );
}

export default LandingPage;