import { Box, Container, Flex, Stack, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Link } from '@chakra-ui/react';
import React from 'react';
import Heading from '../component/Heading/Heading';
import Text from '../component/SubHeading/SubHeading';
import Button from '../component/Button/CustomeButton';
// import{Link} from 'react-router-dom';
import { Helmet } from "react-helmet";
import MetaTitle from './MetaTitle';
function TimeTable() {
    return (
        <>
            <MetaTitle title='Time Table | Perpetua Fitness' />
            <Box textAlign='center' paddingTop={{ sm: '50px', md: '50px' }}>
                <Heading title='Timetable' />
                <Text title='Please wait a few moments for our class timetable to load' />
            </Box>
            <Container  minH={{ sm: '50vh', md: '80vh' }} maxW={{ sm: 'Container.sm', md: 'container.md', lg: 'container.lg', xl: 'container.xl' }} padding={{ md: '50px 0px', lg: '100px 0px' }}>
                <Helmet>
                    <script src="https://widgets.mindbodyonline.com/javascripts/healcode.js" type="text/javascript"></script> 
                </Helmet>
                <healcode-widget data-type="schedules" data-widget-partner="object" data-widget-id="701608660014" data-widget-version="1"></healcode-widget>
                
            </Container>


            <Flex background='black'>
                <Container maxW={{ sm: 'Container.sm', md: 'container.md', lg: 'container.lg', xl: 'container.xl' }} padding={{ sm: '50px 15px', md: '50px 0px', lg: '100px 0px' }}>
                    <Box textAlign='center' gridGap='30px' display='flex' flexDirection='column'>
                        <Heading title='WE OFFER AN ENVIRONMENT WHERE EACH MEMBER FEEL COMFORTABLE TO TRAIN AND BE LED BY ELITE LEVEL COACHES' lineHeight='1' color='white' variant='medium' cfSize={{ sm:'sm' , md:'md' , lg:'lg'}}  width={{ md: '70%' }} margin='0px auto'></Heading>
                        <Heading as='h6' title='2 LOCATIONS. UNLIMITED VARIETY.' lineHeight='1' color='Deepgre' variant='smallmid' cfSize={{sm:'xs' , md:'sm'}}></Heading>
                        <Link href='/contact-us' _hover={{ textDecoration: 'none' }}>
                            <Button title='Book Now' className="wow fadeInUp" color='white' />
                        </Link>
                        <Heading as='h4' title={<Box>OR <Link href='/contact-us'>CONTACT US</Link> FOR MORE INFO</Box>} lineHeight='1' color='white' variant='smallmid' cfSize={{sm:'xs' , md:'sm'}}></Heading>
                    </Box>
                </Container>
            </Flex>

            <Flex p={{ sm: '50px 0px', md: '150px 0px' }}>
                <Container maxW={{ sm: 'Container.sm', md: 'container.lg', lg: 'container.lg', xl: 'container.xl' }}>
                    <Stack direction={{ sm: 'column', md: 'row' }} spacing='24px'>
                        <Box width={{ sm: '100%', md: '50%' }}>
                            <Box paddingLeft='30px' position={'relative'} _after={{ position: 'absolute', content: '""', width: '3px', height: '100%', top: '0', left: '0', background: 'Red' }}>
                                <Heading title='FREQUENTLY ASKED QUESTIONS' lineHeight='1' padding='0px 0px 30px' margin='0px auto' ></Heading>
                                <Text title='Questions about the classes or next steps? We got you. If you need more information, please feel free to contact us hello@perpetua.ie' width='80%' ></Text>
                            </Box>
                        </Box>
                        <Box width={{ sm: '100%', md: '50%' }}>
                            <Accordion defaultIndex={[0]} allowMultiple className='Accor_item _black_acording  white_acording'>
                                <AccordionItem >
                                    <h2>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left' >
                                                <Heading title='WHAT KIND OF MEMBERSHIPS DO YOU OFFER?' variant='extrasmall'></Heading>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text title='You can purchase memberships for CrossFit, HYROX, Studios and our JustGYM area. You can find out the details of on our pricing page.' margin='0px 0px 20px'></Text>
                                        <Text title='We also offer Class Packs' margin='0px 0px 20px'></Text>
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left' >
                                                <Heading title='WHERE AT THE GYMS LOCATED?' variant='extrasmall'></Heading>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text title='We have two Perpetua locations  conveniently located in Dublin City.  We have a location in Windmill Lane and Lennox Street.' margin='0px 0px 20px'></Text>

                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left' >
                                                <Heading title='WHAT CAN I FIND CLASS SCHEDULES?' variant='extrasmall'></Heading>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text title='Schedules are unique to each location. Please visit our timetable page or download the Perpetua Fitness app to see the most up to date schedule.' margin='0px 0px 20px'></Text>
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left' >
                                                <Heading title='DO YOU OFFER FREE TRIAL CLASSES?' variant='extrasmall'></Heading>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text title='Looking to try us out first? Try 3 studio classes with us for 33 euro or if you would like to try CrossFit, please email CrossFit@perpetua.ie to arrange a meeting with the head coach.' margin='0px 0px 20px'></Text>
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left' >
                                                <Heading title='DO YOU OFFER DROP INS?' variant='extrasmall'></Heading>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text title='Yes, we offer drop-ins for our CrossFit classes or JustGYM for 25 euros a session or you can purchase one studio credit for 23 euros.' margin='0px 0px 20px'></Text>
                                    </AccordionPanel>
                                </AccordionItem>

                            </Accordion>
                        </Box>
                    </Stack>
                </Container>
            </Flex>
            {/* <Flex p={{sm:'30px 0px',md:'50px 0px',lg:'80px 0px'}} background='black'>
          <Container maxW={{ sm:'Container.sm', md:'container.md',lg:'container.lg',xl:'container.xl'}}>
            <Stack direction={{sm:'column', md:'row'}} spacing='24px' alignItems='center'>
                <Box width={{sm:'100%',md:'50%'}} textAlign={{sm:'center', md:'left'}}>
                  <Heading title='2 LOCATIONS.' lineHeight='1' color='white' variant='extralarge' margin='0px auto'></Heading>
                  <Heading title='UNLIMITED' lineHeight='1' color='white' variant='extralarge' margin='0px auto'></Heading>
                  <Heading title='VARIETY.' lineHeight='1' color='white' variant='extralarge' margin='0px auto'></Heading>

                  <Heading title='Are you ready to make your move?' lineHeight='1' color='white' variant='extrasmall' margin='20px auto 0px'></Heading>
                </Box>
                <Box width={{sm:'100%',md:'50%'}} textAlign={{sm:'center', md:'left'}} display='flex' flexDirection='column' gridGap={{sm:'10px' , md:'20px'}} justifyItems='center'>
                  <Heading title='JOIN TODAY' lineHeight='1' color='white' variant='medium'></Heading>
                  <Heading title='3 FOR 33 EURO INTRO PACK' lineHeight='1' color='white' variant='medium'></Heading>
                  <Heading title='BUY DROP INS' lineHeight='1' color='white' variant='medium'></Heading>
                </Box>
            </Stack>  
          </Container>
        </Flex> */}

            {/* <OwlCarouselComponent /> */}

            <Flex background='black' borderBottom='1px solid #2E2E2E' >
                <Container maxW={{ sm: 'Container.sm', md: 'container.md', lg: 'container.lg', xl: 'container.xl' }} padding={{ sm: '50px 15px', md: '50px 0px', lg: '100px 0px' }}>
                    <Box textAlign='center' gridGap={{ sm: '15px', md: '30px' }} display='flex' flexDirection='column'>
                        <Heading title='GET STARTED WITH 3 FOR 33 INTRO PACK OR START WITH ONE OF OUR MEMBERSHIPS.' lineHeight='1' color='white' variant='medium' width={{ md: '70%' }} margin='0px auto'></Heading>
                        <Heading as='h6' title='2 LOCATIONS. UNLIMITED VARIETY.' lineHeight='1' color='Deepgre' variant='smallmid'></Heading>
                        <Link href='/contact-us' _hover={{ textDecoration: 'none' }}>
                            <Button title='GET STARTED' className="wow fadeInUp" color='white' />
                        </Link>
                        <Heading as='h4' title='OR CONTACT US FOR MORE INFO' lineHeight='1' color='white' variant='smallmid'></Heading>
                    </Box>
                </Container>
            </Flex>
        </>
    );
}

export default TimeTable;
