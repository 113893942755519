import React from 'react';
import { Box, Container, SimpleGrid, ListItem, UnorderedList, Image } from '@chakra-ui/react';
import InnerBanner from '../component/InnerBanner/InnerBanner';
import Text from '../component/SubHeading/SubHeading';
import Heading from '../component/Heading/Heading';
import MetaTitle from '../pages/MetaTitle';
import PriceCard3 from '../component/Pricing/PriceCard3';

const SummerClosingParty = () => {
    return (
        <>
            <MetaTitle title='Summer Closing Party | Perpetua Fitness' />
            <InnerBanner
                backgroundColor='black'
                class='summer-closing-party-banner'
                backgroundImage={{ sm: 'url(./images/summerClosingPartyMobile1.webp)', md: 'url(./images/summerClosingParty.webp)' }}
                isHeading={false}
                issubtextpara={false}
                isButton={false}
                isButton2={false}
                issubtitleRight={false}
                isSubtext={false}
            />
            
            {/* <Image src='./images/summerClosingParty.webp' alt='summer Closing Party' display={{sm:"none", md:"block"}} /> */}
        

            <Box backgroundColor='#000' padding={{ sm: "50px 0", md: "80px 0" }}>
                <Container maxW='container.xl'>
                    <Text title='The summer might be over but the party isn’t yet.' color='#fff' fontSize='18px' textAlign='center' />
                    <Heading title='Grab your exclusive 6, 9 & 12 Summer Closing Packs  before the temperature drops. ' variant='medium' color='#fff' textAlign='center' padding='10px 0 0' />
                </Container>
            </Box>
            <Box padding={{ sm: "50px 0", md: "80px 50px 30px", xl: "80px 70px 30px" }}>
                <Container maxW='100%'>
                    <Heading title='Pricing Options for' variant='large' textAlign='center' padding='0 0 20px' />
                    <SimpleGrid columns={{ sm: "1", md: "2", xxl: "3" }} spacing={10} padding={{ md: '50px 0' }}>
                        <PriceCard3 key="1" currency="€114" planname="Summer Closing 6-Pack" offer="€19 per credit" link="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=107828" targetBlank={true} description="30 Day expiry" buttonTitle='Buy Now' />

                        <PriceCard3 key="2" currency="€162" planname="Summer Closing 9-Pack" offer="€18 per credit" link="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=107829" targetBlank={true} description="45 Day expiry" buttonTitle='Buy Now' />

                        <PriceCard3 key="3" currency="€204" planname="Summer Closing 12-Pack" offer="€17 per credit" link="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=107830" targetBlank={true} description="2 Month expiry" buttonTitle='Buy Now' />
                    </SimpleGrid>
                </Container>
            </Box>

            <Box backgroundColor='#F4F4F3'  >
                <Container maxW='container.xl' padding={{ sm: "50px 15px", md: "80px 20px" }}  >
                    <Heading title='Terms & Conditions: ' variant='large' />
                    <Text title='Expiry Dates from Date of Purchase:' fontSize={{ sm: "18px", md: '22px' }} fontWeight='700' padding={{ sm: "15px 0 0", md: "20px 0 0" }} />
                    <UnorderedList padding={{ sm: "15px 0", md: "20px 0" }} >
                        <ListItem fontFamily='Poppins' fontWeight='500' fontSize={{ sm: "16px", md: '18px' }}>Summer Closing 6-Pack - €114 (30 day expiry) (€19 per credit).</ListItem>
                        <ListItem fontFamily='Poppins' fontWeight='500' fontSize={{ sm: "16px", md: '18px' }} padding='10px 0' >Summer Closing 9-Pack - €162 (45 day expiry) (€18 per credit).</ListItem>
                        <ListItem fontFamily='Poppins' fontWeight='500' fontSize={{ sm: "16px", md: '18px' }}>Summer Closing 12-Pack - €204 (2 Month expiry) (€17 per credit). </ListItem>
                    </UnorderedList>
                    <Text title='Credits activate as of purchase date. For example, if you purchase a Summer Closing 6-Pack on August 28th 2024, the expiry date is September 27th 2024.' fontSize={{ sm: "16px", md: '18px' }} padding='15px 0 0' fontWeight='500' />
                    <Text title='Summer Closing Pack Credits valid to use in both Windmill Lane in Lennox Street in Tread & Shred, RIDE, Rumble & HYROX.' fontSize={{ sm: "16px", md: '18px' }} padding='10px 0 0' fontWeight='500' />
                    <Text title='Standard Studio Credit T&Cs apply. No pauses or extensions & credits are non-transferable.' fontSize={{ sm: "16px", md: '18px' }} padding='10px 0 0' fontWeight='500' />
                </Container>
            </Box>
        </>
    )
}

export default SummerClosingParty;