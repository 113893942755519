import { Box, Flex,OrderedList,ListItem  } from "@chakra-ui/react";
import styled from "@emotion/styled/macro";
import { device } from "../../Device.types";



export const CorporateCard = styled(Flex)(props => ({
    alignItems:'top',
    gap:'30px',
    padding:'50px 0px',
    flexDirection: 'column',

    [`@media ${device.tablet}`]: {
        flexDirection: 'row',
        padding:'100px 20px',
    },



}))


export const CorporateCardImage = styled(Box)(props => ({
    backgroundPosition:'center',
    backgroundRepeat:'no-repeat',
    backgroundSize:'cover',
    width:'100%',
    height:'400px',
    position:'relative',
    marginRight:'100px',
    top:'0',
    zIndex:'1',
    marginBottom:'30px',

    [`@media ${device.tablet}`]: {
        marginRight:'0px',
        width:'50%',
        position:'sticky',
        marginBottom:'0px',
        top:'100px',
    },
}))

export const CorporateContent = styled(Box)(props => ({
    margin:'0px',
    padding:'0px', 
}))


export const CorporateimageBox = styled(Flex)(props => ({
    backgroundPosition:'center',
    backgroundRepeat:'no-repeat',
    backgroundSize:'cover',
    width:'100%',
    alignItems:'center',
    padding:'100px 0px',

    [`@media ${device.tablet}`]: {
        padding:'50px 20px',
    }

}))

export const CorporateOrderlist = styled(OrderedList)(props => ({
   margin:'0px',
   padding:'0px', 
   marginTop:'30px',
}))

export const CorporatelistItem = styled(ListItem)(props => ({
    listStyle:'none',
    paddingBottom:'10px',
    fontFamily:' poppins',
    fontSize:'16px',
    paddingLeft:'25px',
    position:'relative',

    ":after":{
        position:'absolute',
        left:'0',
        content:'""',
        width:'10px',
        height:'10px',
        background:'#D12C41',
        borderRadius:'100%',
        top:'6px',
    }
 }))

 export const  CorporateimageCard = styled(Flex)(props => ({
    margin:'0px',
    padding:'0px', 
    flexDirection:'column',
 }))
 




