import React from 'react'
import CenterContent from './LandingComponent/CenterContent';
import TwoclumnContent from './LandingComponent/TwoclumnContent';
import PageBanner from './LandingComponent/PageBanner';
import FaqContent from './LandingComponent/FaqContent';
import { Link } from '@chakra-ui/react';

const bannerContent = {
  bg: "/images/langing-banner.webp",
  bgmobile: "/images/langing-banneSamll.webp"
  // heading:"About Us",
  // subheading:"Four wheels fuel a billion dreams and for every dream there is a Marnix"
}

const centerContent = {
  heading: "Get ready to sweat with your Plus One in the studio! ",
  subheading: "Invite a newbie to class with you on an Intro Offer - you’ll get a free class on your account, and they’ll get a shake after your first class together!",
}

const twoclumnContent = {
  image: "/images/landingleft-image.jpg",
  heading: "Want to know what it takes to Join the Guest List with your #PlusOne? ",
}

const faqcontent = [
  {
    title: 'What is Join the Guest List? ',
    para: "Our ongoing referral programme that allows you to earn free credits by referring a newbie to purchase our 'Intro Offer 3 for €33' and attending class together during the month of April.",
  },
  {
    title: 'What will I receive for bringing along a first-timer to Perpetua? ',
    para: "For each first-timer (Plus One) that you take to class with you, you'll receive a complimentary class to use next time. ",
  },
  {
    title: 'Who is considered a Plus One? ',
    para: 'Anyone who has not been to Perpetua before and legible for an Intro Offer 3 for €33.',
    linkPara: 'Create an account',
    link: 'here.',
    pageLink: '/sign-up'
  },
  {
    title: 'What will my Plus One receive?  ',
    para: 'Your Plus One will receive a shake upon completion of their first class on their Intro Offer. ',
  },
  {
    title: 'How do I avail of the #JoinTheGuestList promotion? ',
    para: "Check in together at the front desk & our team will confirm it's your Plus One's first time.",
  },
  {
    title: 'When will the complimentary studio class show on my account? ',
    para: 'Within 7 days of completing a class with your Plus One.',
  },
  {
    title: 'My friend and I are both new. Can we avail of the promotion together? ',
    para: 'Yes, absolutely! You will both get a free shake when you complete your first class. ',
    linkPara: 'Purchase your Intro offer',
    link: 'here.',
    pageLink: '/pricing'
  },
]

const tremsContent = {
  heading: "Terms & Conditions",
  subheading: "Offer valid only for first-time referral clients on an Intro Offer 3 for €33. Promotion can only be used for one #PlusOne at a time. First-timer must purchase an Intro Offer online and take a class with the existing client. Both parties must be present and check in at the front of house together to be eligible for the promotion. Not applicable for private events or corporate buy-outs, including full and/or partial buy-outs. All complimentary class credits expire 30 days from the date credited. Promotion ends 30/04/2024. ClassPass & GymPass not included in the promotion. ",
}

const GuestList = (props) => {
  return (
    <>

      <PageBanner bannerImage={bannerContent.bg} bannerImage2={bannerContent.bgmobile} bannerHeading={bannerContent.heading} bannerDescription={bannerContent.subheading} />
      <CenterContent heading={centerContent.heading} subHeading={centerContent.subheading} />
      <TwoclumnContent imageUri={twoclumnContent.image} heading={twoclumnContent.heading} />
      <FaqContent faqcontent={faqcontent} title='FAQs' smallTittle='Check out our FAQ below for all the juicey details:' />
      <CenterContent heading={tremsContent.heading} subHeading={tremsContent.subheading} />
    </>

  )
}

export default GuestList