import React from 'react';
import { Box, Container, SimpleGrid } from '@chakra-ui/react';

import * as Style from "../Style/Blog.style"

import moment from 'moment';
import Heading from '../Heading/Heading';
import BlogCard from './BlogCard';


function LatestBlog(props) {

    return (
        <>
            <Box background={'#F0F0F0'} >
                <Container maxW="container.xl" >
                    <Heading title={props.title} textAlign="center" variant="extralarge" padding="40px 0px" />

                    <Style.BlogListEpisode>
                        <SimpleGrid columns={{ sm: '1', md: '3' }} spacing={6} >
                            {props.latestBlogs.map((item, index) => {

                                var img_path = '/images/blog-box.jpg';
                                if (item.image_path !== 'null') { img_path = item.image_path; }

                                return (
                                    <BlogCard
                                        key={index}
                                        uri={img_path}
                                        height='260px'
                                        date={moment(item.created_at).format('MMMM Do YYYY')}
                                        title={item.title}
                                        link={'/blog/' + item.slug}
                                        tag={item.category.title}
                                        taglink={'/blog/category/' + item.category.slug}
                                    />
                                )
                            })
                            }
                        </SimpleGrid>
                        {props.relatedPageSlug &&
                            <Box textAlign='center' marginTop='30px'>
                                <Style.ViewRelatedPage href={'/'+props.relatedPageSlug}>View Related Page</Style.ViewRelatedPage>
                            </Box>
                        }
                        {props.coachBlogsSeeMore &&
                            <Box textAlign='center' marginTop='30px'>
                                <Style.ViewRelatedPage href={`/blog/by-coach/${props.coachBlogsSeeMore}`}>See more</Style.ViewRelatedPage>
                            </Box>
                        }
                    </Style.BlogListEpisode>
                </Container>
            </Box>
        </>
    )
}

export default LatestBlog;