import PropTypes from 'prop-types';

export const ButtonProps = {
    title: PropTypes.string,
}

export const defaultBottonProps = {
    title: 'Defualt Button',
    color:'white',
    width:'auto'
}