import React from 'react'
import {Box, Image} from '@chakra-ui/react';
import MyImage from '../LazyLoadImage/MyImage';

const ImagePalce = (props) => {
  return (
    <Box>
        <MyImage alt={props.alt} src={props.src} width={props.width} height={props.height} />
    </Box>
  )
}

export default  ImagePalce