import React from 'react';
import * as Style from './Location.style';
import LandingBanner from './LandingBanner/LandingBanner';
import { Box, Container, Stack, Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton, useDisclosure } from '@chakra-ui/react';
import Heading from '../Heading/Heading';
import Text from '../SubHeading/SubHeading';
import ContentboxCard from './ContentBox/ContentboxCard';
import VideoCard from '../VideoCard/VideoCard';
import OutStudio from '../OutStudio/OutStudio';
import MetaTitle from '../../pages/MetaTitle';
import LazyLoad from "react-lazy-load";


const banners = [
  {
    "heading1": "GYM PORTOBELLO, RATHMINES",
    "heading2": "Lennox Street",
    "heading3": "THE LENNOX BUILDING,LENNOX ST,SAINT KEVIN'S,DUBLIN 2 D02 FK02",
    "Link1": "/timetable",
    "Link2": "/contact-us",
  },
]

const classbox = [
  {
    "image": "./images/tread-banner.jpg",
    "heading": "Tread & Shred",
    "description": "Intelligent programming, world class coaches, & the perfect beats. Our signature tread & shred class is a group fitness experience unmatched in Ireland. You’ll alternate between treadmill and floor exercises that are focused on strength, power, and endurance. You’ll burn calories and get stronger and faster every class. ",
    "link": "/tread-and-shred",
  },
  {
    "image": "./images/ride.jpg",
    "heading": "RIDE",
    "description": "More than a spin class. An experience. Fully equipped with Keiser m3i bikes and the best lighting and sound system in Ireland. Our ride45, and rhythm ride classes offer something for everyone.",
    "link": "/spin-class-dublin",
  },

  {
    "image": "./images/Rumble-banner.jpg",
    "heading": "Rumble",
    "description": "Each class you’ll be learning power moves from famous boxers, but you’ll need some determination, self-discipline, and effort to take on the challenge.",
    "link": "/boxing-class-dublin",
  },
  {
    "image": "./images/personal_training_banner.jpg",
    "heading": "Personal Training",
    "description": "Get personal with one of our coaches so you can work towards your fitness goals in a fun, safe and motivating environment. When you begin your sessions, you start with a movement assessment. This allows us to find out everything about you and discuss where you want to start. We also perform an in-body analysis to get a starting point.",
    "link": "/personal-training-dublin",
  },


]
const aminitibox = [
  {
    "icon": "./images/sport-bag.png",
    "heading": "DIRTY CLOTHES BAG",
    "description": "Free bags to keep your sweaty clothes in",
    "alt":"gym portobello - Dirty clothes bag"
  },
  {
    "icon": "./images/wifi.png",
    "heading": "WI-FI",
    "description": "Free Wi-Fi for our guests, connect to PerpetuaGuest",
    "alt":"gym portobello - Wi Fi"
  },
  {
    "icon": "./images/changing-room.png",
    "heading": "CHANGING ROOMS",
    "description": "Separate men’s and women’s changing areas.",
    "alt":"gym portobello - Changing rooms"
  },
  {
    "icon": "./images/shake.png",
    "heading": "MOJO Health Bar",
    "description": "Delicious, protein-packed shakes that come in chocolate, fruit, vanilla and more. Coffee, water and snacks too.",
    "alt":"gym portobello - MOJO Health Bar"
  },
  {
    "icon": "./images/toiletries.png",
    "heading": "GROOMING PRODUCTS",
    "description": "All the toiletry bells and whistles including shampoo, conditioner, face wash, body wash, body lotion, tampons and deodorant. * *Some items may not be available at all locations.",
    "alt":"gym portobello - Grooming products"
  },
  {
    "icon": "./images/towel.png",
    "heading": "COMPLIMENTARY TOWELS",
    "description": "Mini towels for class and lovely big towels for showers after class.",
    "alt":"gym portobello - Complimentary towels"
  },
  {
    "icon": "./images/store.png",
    "heading": "SHOP",
    "description": "Perpetua’s latest and greatest clothing collection available for purchase. Tanks,tees, shorts, pants, jackets, hats, socks and more...check it out.",
    "alt":"gym portobello - Shop"
  },
  {
    "icon": "./images/public-toilet.png",
    "heading": "RESTROOMS",
    "description": "Fully functioning restrooms.",
    "alt":"gym portobello - Restrooms"
  },
  {
    "icon": "./images/lockers.png",
    "heading": "LOCKERS",
    "description": "Secure lockers that work by you choosing a four digit code.",
    "alt":"gym portobello - Lockers"
  },
  {
    "icon": "./images/shower.png",
    "heading": "HOT SHOWERS",
    "description": "High pressure, private showers with fabulous soap, shampoo, and conditioner.",
    "alt":"gym portobello - Hot showers"
  },

]

const metaTitle = "Gym Portobello, Rathmines | Perpetua Fitness - Lennox Street"
const metaDesc = "Perpetua Fitness - Lennox Street, set within the beautiful suburbs of Portobello. 3 studio concepts and a personal training studio. Get in touch."


const Location2 = (props) => {

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <MetaTitle title={metaTitle} content={metaDesc}/>
      <Style.LocationBody>
        <LandingBanner banners={banners} />

        <Box background='#000' padding={{ lg: '100px 0px', md: '50px 0px', sm: '50px 0px' }}>
          <Container maxW="container.xl">
            <Stack direction={{ sm: 'column', md: 'column', lg: 'row' }} spacing='24px'>
              <Box w={{ md: '100%', lg: '50%' }}>
                <Heading title="COACHING. COMMUNITY. PASSION." variant="large" color='#fff' width='70%' lineHeight='1' />
              </Box>
              <Box w={{ md: '100%', lg: '50%' }} padding={{ lg: '20px 0px', md: '8px 0px' }}>
                <Text title="Lennox Street, located a stones throw away from the city centre, and set within the beautiful suburbs of Portobello." as='p' fontSize="18px" color='#fff' margin='0px 0px 10px' fontWeight='300' />
                <Text title="Sweat it out in 3 Studio Concepts or get Personal with one of our coaches in our Personal Training Studio. Then get ready in our luxury changing rooms before grabbing a shake and coffee on your way out at MOJO" as='p' fontSize="18px" color='#fff' fontWeight='300' />
              </Box>
            </Stack>
          </Container>
        </Box>
        <ContentboxCard classbox={classbox} />
        <OutStudio aminitibox={aminitibox} />

        <Box background='#000' padding={{ lg: '100px 0px', md: '50px 0px', sm: '50px 0px' }} borderTop='1px solid #232323'
          borderBottom=' 1px solid #232323'>
          <Container maxW="container.xl">
            <Stack direction={{ sm: 'column', md: 'row' }} spacing='10px'>
              <Box width={{ sm: '100%', md: '40%' }}>
                <Heading title="LENNOX STREET OPENING HOURS:" variant="medium" color='#fff' width='70%' lineHeight='1' margin='0px 0px 20px' />
                <Text title="MONDAY - THURSDAY: 6:00AM - 2:30PM 4:00PM - 8.30PM" as='p' fontSize="18px" color='#fff' margin='0px 0px 10px' fontWeight='300' />
                <Text title="TUESDAY - THURSDAY: 6:00AM - 8.30PM" as='p' fontSize="18px" color='#fff' margin='0px 0px 10px' fontWeight='300' />
                <Text title="FRIDAY: 6:00AM - 8.00PM" as='p' fontSize="18px" color='#fff' margin='0px 0px 10px' fontWeight='300' />
                <Text title="SATURDAY: 7:30AM - 1:00PM" as='p' fontSize="18px" color='#fff' margin='0px 0px 10px' fontWeight='300' />
                <Text title="SUNDAY: 8:00AM - 1:00PM" as='p' fontSize="18px" color='#fff' margin='0px 0px 10px' fontWeight='300' />
                <Style.ButtonModal onClick={onOpen} color='white' marginTop='20px'>View Gym</Style.ButtonModal>
              </Box>
              <Box width={{ sm: '100%', md: '60%' }}>
                <LazyLoad offset={300}>
                  <Box className='selector' width={{ sm: '100%', md: '100%' }}
                    as='iframe'
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9530.637667595407!2d-6.2651619!3d53.3314512!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48670d53db8a847f%3A0x4240de17a24c8cb6!2sPerpetua%20Fitness%20-%20Lennox%20Street!5e0!3m2!1sen!2sin!4v1702994962241!5m2!1sen!2sin"
                  >
                  </Box>
                </LazyLoad>
              </Box>
              <Box marginLeft={{ md: '10px' }} marginTop={props.ButtonModalmarginTop}>
                <Modal isOpen={isOpen} onClose={onClose} size={{ sm: 'sm', md: '3xl' }} >
                  <ModalOverlay />
                  <ModalContent marginTop='100px'>
                    {/* <ModalHeader>Modal Title</ModalHeader> */}
                    <ModalCloseButton />
                    <ModalBody >
                      <VideoCard src='https://www.youtube.com/embed/wuxj2I_lYc0' width={{ sm: '100%', md: '700px' }} margin={{ sm: '30px 3px', md: '30px 9px' }} maxHeight='540px'></VideoCard>
                    </ModalBody>
                  </ModalContent>
                </Modal>
              </Box>
            </Stack>
          </Container>
        </Box>

      </Style.LocationBody>
    </>

  )
}

export default Location2

