import { Flex, Box, Link } from "@chakra-ui/react";
import styled from "@emotion/styled/macro";
import { NavLink } from "react-router-dom";


export const LandindBanner = styled(Box)(props => ({
    padding: 0,
    margin: 0,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',

    "@media screen and (max-width:991px)": {
        backgroundPosition: 'Center'
    },
    "@media screen and (max-width:767px)": {
        backgroundPosition: 'top'
    },

}))
export const BannerWrapper = styled(Box)(props => ({
    position: 'relative',
    height: '650px',

    "@media screen and (max-width:1440px)": {
        height: '630px',
    },

    "@media screen and (max-width:1366px)": {
        height: '550px',
    },
    "@media screen and (max-width:1199px)": {
        height: '450px',
    },
    "@media screen and (max-width:991px)": {
        height: '350px',
    },
    "@media screen and (max-width:767px)": {
        height: '850px',
    },
    "@media screen and (max-width:640px)": {
        height: '750px',
    },
    "@media screen and (max-width:480px)": {
        height: '82vh',
    },
    "@media screen and (max-width:400px)": {
        height: '79vh',
    },

}))
export const ButtonWrapper = styled(Flex)(props => ({
    padding: 0,
    //margin: '0 auto',
    position: 'absolute',
    // top: 0,
    // right: 0,
    bottom: '80px',
    left: '0px',
    width: '100%',
    alignItems: 'center',
    textAlign: "center",
    justifyContent: 'center',
    gap: 20,
    //marginTop: '130px',

    //flexDirection: 'column',

    "@media screen and (max-width:767px)": {
        bottom: '20px',
        alignItems: 'center',
        right: '0',
        textAlign: 'center',
        paddingLeft: '0',
        gap: '10px'
    },
    "@media screen and (max-width:480px)": {
        bottom: '20%',
    },
    "@media screen and (max-width:1199px)": {
        bottom: '15px',

    },
}))

export const captionwrapper = styled(Flex)(props => ({
    display: 'flex',
    alignItems: 'flex-start',
    gap: '15px',
    flexDirection: 'column',

    "@media screen and (max-width:767px)": {
        alignItems: 'center',
        textAlign: 'center'
    },
}))

export const ButtonWrap = styled(Flex)(props => ({
    flexDirection: "row",
    gap: '15px',
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginTop: "13%",
    marginRight: "11%",
    "@media screen and (max-width:1770px)": {
        marginTop: "15%",
        marginRight: "5%",
    },
    "@media screen and (max-width:1536px)": {
        marginTop: "15%",
        marginRight: "2%",
    },
    "@media screen and (max-width:1440px)": {
        marginTop: "17%",
        marginRight: "0%",
    },
    "@media screen and (max-width:1366px)": {
        marginRight: "4%",
    },
    "@media screen and (max-width:1024px)": {
        marginRight: "0",
    },
    "@media screen and (max-width:991px)": {
        marginRight: "2%",
        marginBottom: "0"
    },
    "@media screen and (max-width:767px)": {
        marginBottom: "60px",
        marginRight: "0",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    "@media screen and (max-width:480px)": {
        marginBottom: "0",
    },
}))

export const Button = styled(NavLink)(props => ({
    padding: 0,
    margin: 0,
    marginRight: '20px',
    padding: '9px 20px',
    color: '#fff',
    border: '2px solid #d12c41',
    width: '100%',
    maxWidth: '208px',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: 20,
    borderRadius: 300,
    '&:hover': {
        backgroundColor: '#d12c41',
        color: '#fff'
    },
    "@media screen and (max-width:767px)": {
        marginRight: '0',
    },
    "@media screen and (max-width:480px)": {
        fontSize: 17,
    },
}))


export const externalBtn = styled(Link)(props => ({
    padding: 0,
    margin: 0,
    marginRight: '20px',
    padding: '9px 20px',
    color: '#fff',
    border: '2px solid #d12c41',
    width: '100%',
    maxWidth: '208px',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: 20,
    borderRadius: 300,
    '&:hover': {
        backgroundColor: '#d12c41',
        color: '#fff'
    },
    "@media screen and (max-width:767px)": {
        marginRight: '0',
    },
    "@media screen and (max-width:480px)": {
        fontSize: 17,
    },
}))