import React from "react";
import * as Style from "./TeamFilter.style";
import Heading from "../Heading/Heading";
import LinkButton from "../Button/LinkButton";
import { Link } from "@chakra-ui/react";

const TabItem = ({ items,display}) => {
  return (
    <Style.TeamWrapper display={display?display:"grid"}>
      {items.map((elem) => {
        const { id, image, name, slug } = elem;
        return (
          <Link href={"/team/" + slug}>
            <Style.TeamCardWrapper key={id}>
              <Style.TeamCard
              width={display==="flex"?"300px":"100%"}
                backgroundImage={
                  image !== "null"
                    ? image
                    : "url('/images/team-listing-defult.jpg')"
                }
              >
                <Style.TeamCardContent className="TeamCardContent">
                  <Heading variant="small" color="#fff" title={name}></Heading>
                </Style.TeamCardContent>
              </Style.TeamCard>
              <Style.LinkWraper className="LinkWraper">
                <Link href={"/team/" + slug}>
                  <LinkButton
                    title="View Profile"
                    color="white"
                    Imguri="/images/Arrow.svg"
                    padding="0px"
                  />
                </Link>
              </Style.LinkWraper>
            </Style.TeamCardWrapper>
          </Link>
        );
      })}
    </Style.TeamWrapper>
  );
};

export default TabItem;
