import React, { useEffect } from 'react';
import { Skeleton, Stack, Container } from '@chakra-ui/react'

const HealthBar = () => {

    useEffect(() => {
        window.location.href = '/images/healthbar-menu.pdf';
    })

    return (
        <>
            <Container maxW={{ md: 'container.lg', xl: 'container.xl' }} textAlign='center'>
                <Stack marginY='100px'>
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                </Stack>
            </Container>
        </>
    );
}

export default HealthBar;
