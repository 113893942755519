import { Flex, Box, Image, UnorderedList, ListItem, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Link } from "@chakra-ui/react";
import Heading from "../../Heading/Heading";
import Text from "../../SubHeading/SubHeading";
import styled from "@emotion/styled/macro";
import { device } from "../../../Device.types";

export const PageBanner = styled(Box)(props => ({
    padding: '0',
    margin: '0',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
}))

export const CaptionWrapper = styled(Flex)(props => ({
    padding: '0',
    margin: '0',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '600px',
    [`@media ${device.mobileS}`]: {
        height: '400px',
    },
}))

export const Caption = styled(Flex)(props => ({
    padding: '0',
    margin: '0',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
}))

export const CenterContent = styled(Box)(props => ({
    padding: '80px 0px',
    margin: '0',
    textAlign: 'center',
    [`@media ${device.tabletS}`]: {
        padding: '40px 0px',
    },
}))

export const CenterContentWrapper = styled(Flex)(props => ({
    padding: '0',
    margin: '0',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '20px',
}))

export const CenterContentHeader = styled(Flex)(props => ({
    padding: '0',
    margin: '0',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '15px',
}))

export const h2 = styled(Heading)(props => ({
    padding: '0',
}))

export const p = styled(Text)(props => ({
    margin: '0',
    width: '80%',
    margin: '0px auto',
    [`@media ${device.mobileS}`]: {
        width: '100%',
    },
}))

export const midContent = styled(Box)(props => ({
    padding: '0px 0px 0px',
    margin: '0',
}))

export const midcontentWrapper = styled(Flex)(props => ({
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexDirection: 'row',
    gap: '30px',
    [`@media ${device.tabletS}`]: {
        flexDirection: 'column',
    },
}))

export const imageBox = styled(Box)(props => ({
    alignItems: 'center',
    width: '50%',
    minHeight: '400px',
    [`@media ${device.tabletS}`]: {
        width: '100%',
        minHeight: 'auto',
    },
}))

export const image = styled(Image)(props => ({
    objectFit: 'cover',
    objectPosition: 'center',
    width: '100%',
    height: '100%',

}))

export const contentBox = styled(Box)(props => ({
    alignItems: 'center',
    width: '50%',
    [`@media ${device.tabletS}`]: {
        width: '100%',
    },
}))

export const description = styled(Text)(props => ({
    padding: '0',
    margin: '0',
}))

export const linkWrapper = styled(Flex)(props => ({
    padding: '0',
    margin: '0',
}))

export const link = styled(Link)(props => ({
    padding: '0',
    margin: '0',
    color: '#000',
    fontFamily: ' Poppins',
    paddingLeft: '5px',
    textDecoration: 'underline'
}))

export const pointList = styled(UnorderedList)(props => ({
    padding: '0',
    margin: '0',
    fontFamily: ' Poppins',
    marginTop: '20px',
    paddingLeft: '20px',
}))

export const pointlistItem = styled(ListItem)(props => ({
    padding: '0',
    margin: '0',
    listStyle: 'decimal',
    marginBottom: '15px',
    fontFamily: "Poppins",
    fontSize: '16px',
    fontWeight: '500',
    [`@media ${device.mobileS}`]: {
        fontSize: '14px',
    },

}))

export const faqSection = styled(Box)(props => ({
    padding: '40px 0px 20px',
    [`@media ${device.tablet}`]: {
        padding: '80px 0px 20px',
    },
}))

export const faqBox = styled(Accordion)(props => ({

}))

export const faqItem = styled(AccordionItem)(props => ({
    border: '0',
    borderBottom: '1px solid #d8d8d8',

}))
export const itemButton = styled(AccordionButton)(props => ({
    justifyContent: 'space-between',
    border: '0',
    padding: '20px 10px',
    "h2": {
        fontFamily: ' Poppins',
        fontWeight: '500',
        textTransform: 'capitalize',
    },
    [`@media ${device.tabletS}`]: {
        textAlign: 'left',
    },
}))

export const itemIcon = styled(AccordionIcon)(props => ({
    fontSize: '18px'
}))

export const itemArea = styled(AccordionPanel)(props => ({

}))

