import PropTypes from 'prop-types';

export const TextProps = {
    title: PropTypes.string,
}
export const defaultTextProps = {
    title: 'Sub heading',
    color:'black',
    fontFamily:'Poppins',

}