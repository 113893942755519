import React from "react";
import { Box } from '@chakra-ui/react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure
} from '@chakra-ui/react';
import * as Style from './ModalSignUp.style';
import './Modal.css';

function ModalSignUp() {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const nltrClose = () => {
        onClose();
        window.location.reload(false);
    }



    return (
        <>
            <Box marginLeft={{ lg: "25px", xl: "70px" }} marginBottom='35px' className='JoinOurNewsletter'>
                <Style.ButtonModalSignUp onClick={onOpen}>Join Our Newsletter</Style.ButtonModalSignUp>

                <Modal isOpen={isOpen} onClose={nltrClose} size={{ sm: 'sm', md: 'md' }}>
                    <ModalOverlay />
                    <ModalContent marginTop="150px">
                        <Box background='white' padding='10px 0 40px'>
                            <ModalHeader fontSize='25px' textAlign='center' >JOIN OUR NEWSLETTER</ModalHeader>
                            <ModalCloseButton color='black' />

                            <ModalBody className='JoinOurNewsletterBody'>
                                <div class="klaviyo-form-UQT422"></div>
                            </ModalBody>
                        </Box>
                    </ModalContent>
                </Modal>
            </Box>

        </>
    )
}
export default ModalSignUp;