import React, { useEffect } from 'react';
import { Flex, Container, Image, Box, Stack, HStack, Accordion, SimpleGrid, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Link } from '@chakra-ui/react';
import AppDownload from '../component/AppDownload/AppDownload';
import Heading from '../component/Heading/Heading';
import Text from '../component/SubHeading/SubHeading';
import ClassCard from '../component/Classcard/ClassCard';
import Button from '../component/Button/CustomeButton';
import { FaStar } from "react-icons/fa";
import MetaTitle from './MetaTitle';
import WOW from 'wowjs';

import BlogSection from '../component/Blog/BlogSection';



const metaTitle = "Online Gym Programming | Perpetua Fitness"
const metaDesc = "Choose any of our online gym programs to follow and be ready to work hard, have fun and get the results you’ve always wanted. 4 daily programs."



function OnlinePrograming() {

    useEffect(() => {
        new WOW.WOW({
            live: true,
            offset: 0,
            mobile: true,
        }).init();
    }, [])

    return (
        <>
            <MetaTitle title={metaTitle} content={metaDesc} />            

            <AppDownload
                bg="url('/images/App-section-bg.webp')"
                heading='PERPETUA ONLINE GYM PROGRAMMING'
                para='The Best Online Gym Program By Experienced Coaches'
                imageUri='/images/iphone.webp'
                className="_appdownload_section"
                isAppButton={false}
                isButton={false}
                isExternalButton={true}
                targetblank={true}
                link="https://perpetuatraining.com/"
                alt="perpetuatraining.com"
            />


            <Flex textAlign='center' p={'70px 0px'} background='LightGrey'>
                <Container maxW='container.xl'>
                    <Heading cfSize={{ sm: 'md', md: 'md', lg: 'lg', xl: 'xl' }} title='4 DAILY PROGRAMS. 7 DAYS A WEEK' lineHeight='1' padding='0px 0px 30px' width='90%' margin='0px auto'></Heading>
                    <Text title='Choose any of our online gym programs to follow and be ready to work hard, have fun and get the results you’ve always wanted. ' width={{ sm: '100%', md: '62%' }} margin='0px auto'></Text>
                </Container>
            </Flex>

            <Flex p={{ sm: '20px 0px', md: '20px 0px' }} className="opAccoSec">
                <Container maxW='container.xl'>
                    <Stack direction={{ sm: "column", md: "row" }} spacing='24px' marginBottom={{ sm: '30px', md: '60px' }} alignItems='top'>
                        <Box w={{ sm: "100%", md: "40%" }} className='no-overlay'>
                            <ClassCard
                                //title='PERPETUA ENDURANCE'
                                isHeading={false}
                                subtitle={false}
                                bg="url('/images/mobile1.webp')"
                                margin='0 auto'
                                link="/about"
                                isButton={false}
                                transform="uppercase"
                                minHeight={{ sm: '400px', md: '300px', lg: '350px', xl: '400px' }}
                                width={{ sm: '100%', md: "390px" }}
                            />
                        </Box>
                        <Box w={{ sm: "100%", md: "60%" }} paddingLeft={{ sm: '0', md: "30px" }}>
                            <Heading lineHeight='1' title="Perpetua Conditioning" variant="Large" margin="0 0 20px"></Heading>
                            <Text title='Our conditioning days in Perpetua Fitness are one of our most popular days with members and now we are delivering this to individuals online. ' margin='0px 0px 20px'></Text>

                            <Text title='This program is for people who want to develop a broad endurance base across multiple mono-structural modalities. Following this path should provide the preparation and confidence necessary to be able to jump into any conditioning event/workout.'></Text>

                            <Accordion margin='30px 0 0' /* defaultIndex={[0]} */ allowMultiple className='Accor_item'>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton paddingLeft='0' paddingRight='0'>
                                            <Box as="span" flex='1' textAlign='left' >
                                                <Heading title='Equipment Needed' variant='extrasmall'></Heading>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4} paddingLeft='0' paddingRight='0'>
                                        <Text title='Bike Workouts: Assault, Echo, or Concept2 BikeErg.' margin='0px 0px 20px'></Text>
                                        <Text title='Row Workouts: Concept2 Rower.' margin='0px 0px 20px'></Text>
                                        <Text title='Run Workouts: Any track, open road, or trail as well as the Assault AirRunner, TrueForm, any motorised treadmill.' margin='0px 0px 20px'></Text>
                                        <Text title='Ski Workout: Concept2 SkiErg' margin='0px 0px 20px'></Text>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                        </Box>
                    </Stack>

                    <Stack direction={{ sm: "column", md: "row" }} spacing='24px' marginBottom={{ sm: '30px', md: '60px' }} alignItems='top'>
                        <Box w={{ sm: "100%", md: "40%" }} className='no-overlay'>
                            <ClassCard
                                title={false}
                                subtitle={false}
                                bg="url('/images/mobile2.webp')"
                                link="/about"
                                isButton={false}
                                transform="uppercase"
                                minHeight={{ sm: '400px', md: '300px', lg: '350px', xl: '477px' }}
                                width={{ sm: '100%', md: "390px" }}
                                margin='0px auto'
                            />
                        </Box>
                        <Box w={{ sm: "100%", md: "60%" }} paddingLeft={{ sm: '0', md: "30px" }}>
                            <Heading lineHeight='1' title="Perpetua Strength" variant="Large" margin="0 0 20px"></Heading>

                            <Text title='Perpetua Strength is our signature program which has helped 1000’s of members. The program is for
                    individuals who have previous experience in compound lifting. This is for people who enjoy a mixture of strength and gymnastics and need to keep their training window to 60 min or less. We
                    structure your workout from warmup to cool down to allow you to be as productive as possible with your time.' margin='0px 0px 20px'>
                            </Text>

                            <Accordion margin='30px 0 0' /* defaultIndex={[0]} */ allowMultiple className='Accor_item'>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton paddingLeft='0' paddingRight='0'>
                                            <Box as="span" flex='1' textAlign='left' >
                                                <Heading title='Equipment Needed' variant='extrasmall'></Heading>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel paddingLeft='0' paddingRight='0' pb={4}>
                                        <Text title='You can expect a variety of machine work on rower, bike erg, ski erg and echo bike as well as use of barbells and plates, dumbbells, kettlebells and access to a rig will be required.' margin='0px 0px 20px'></Text>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                        </Box>
                    </Stack>
                    <Stack direction={{ sm: "column", md: "row" }} spacing="24px" marginBottom={{ sm: '30px', md: '60px' }} alignItems='top'>
                        <Box w={{ sm: "100%", md: "40%" }} className='no-overlay'>
                            <ClassCard
                                title={false}
                                subtitle={false}
                                bg="url('/images/mobile3.webp')"
                                link="/about"
                                isButton={false}
                                transform="uppercase"
                                minHeight={{ sm: '400px', md: '300px', lg: '350px', xl: '477px' }}
                                width={{ sm: '100%', md: "390px" }}
                                margin='0px auto'
                            />
                        </Box>
                        <Box w={{ sm: "100%", md: "60%" }} paddingLeft={{ sm: '0', md: "30px" }}>
                            <Heading lineHeight='1' title="Perpetua Bodybuilding" variant="Large" margin="0 0 20px" paddingTop='25px'></Heading>

                            <Text title='The program to suit all your hypertrophy needs is designed by Head Coach of Perpetua Fitness, Eoghan McGregor who has over 15 years experience in the fitness industry. This is a program which has been tested, followed and got results for each individual. Join this program which is created by someone who actually has experience in the industry and follows the program.' margin='0 0 20px'></Text>
                            <Text title='Not every training day needs high intensity so this is where our Perpetua Build program can be perfect for you. Perpetua Build is a functional strength and full-body bodybuilding program only available at Perpetua Fitness! The goal of Perpetua Build is simple – all gains, zero stress!' />
                            <Accordion margin='30px 0 0' /* defaultIndex={[0]} */ allowMultiple className='Accor_item'>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton paddingLeft='0' paddingRight='0'>
                                            <Box as="span" flex='1' textAlign='left' >
                                                <Heading title='Equipment Needed' variant='extrasmall'></Heading>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel paddingLeft='0' paddingRight='0' pb={4}>
                                        <Text title='You will need a barbell (although you can get by without it!), plates, dumbbells, kettlebell, jump box, jump rope, pull-up bar and resistance bands. In this program, you can very easily adapt it to perform with only a few key pieces – dumbbells, resistance bands, and a pull-up bar.' margin='0px 0px 20px'></Text>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                        </Box>
                    </Stack>
                    <Stack direction={{ sm: "column", md: "row" }} spacing="24px" marginBottom={{ sm: '30px', md: '60px' }} alignItems='top'>
                        <Box w={{ sm: "100%", md: "40%" }} className='no-overlay'>
                            <ClassCard
                                title={false}
                                subtitle={false}
                                bg="url('/images/mobile4.webp')"
                                link="/about"
                                isButton={false}
                                transform="uppercase"
                                minHeight={{ sm: '400px', md: '300px', lg: '350px', xl: '477px' }}
                                width={{ sm: '100%', md: "390px" }}
                                margin='0px auto'
                            />
                        </Box>
                        <Box w={{ sm: "100%", md: "60%" }} paddingLeft={{ sm: '0', md: "30px" }}>
                            <Heading lineHeight='1' title="Perpetua HYROX" variant="Large" margin="0 0 20px" paddingTop='25px'></Heading>

                            <Text title='The program is designed by Head Coach of Perpetua Fitness, Eoghan McGregor who has over 15 years experience in the fitness industry. This is a program which has been tested, followed and got results for everyone who entered a HYROX race.' margin='0 0 20px'></Text>
                            <Accordion margin='30px 0 0' /* defaultIndex={[0]} */ allowMultiple className='Accor_item'>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton paddingLeft='0' paddingRight='0'>
                                            <Box as="span" flex='1' textAlign='left' >
                                                <Heading title='Equipment Needed' variant='extrasmall'></Heading>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel paddingLeft='0' paddingRight='0' pb={4}>
                                        <Text title='Not every training day needs high intensity so this is where our Perpetua Build program can be perfect for you. Perpetua HYROX program will involve a mix between strength and conditioning work, so you are in your best shape to enter a HYROX event.' margin='0px 0px 20px'></Text>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                        </Box>

                    </Stack>
                </Container>
            </Flex>

            <Flex textAlign='center' p={{ sm: '20px 0px', md: '20px 0px' }}>
                <Container maxW='container.xl'>
                    <Heading title='WHY PEOPLE LOVE PERPETUA ONLINE GYM PROGRAM' lineHeight='1' width='100%' padding='0px 0px 30px' margin='0px auto'></Heading>
                    <Box>
                        <Heading title='5/5 - 100 Reviews' lineHeight='1' width='60%' variant='extrasmall' padding='0px 0px 10px' margin='0px auto' color='#787878'></Heading>
                        <HStack spacing='3px' margin={'0px auto'} display='inline-flex'>
                            <Box>
                                <FaStar color='Red' />
                            </Box>
                            <Box>
                                <FaStar color='Red' />
                            </Box>
                            <Box>
                                <FaStar color='Red' />
                            </Box>
                            <Box>
                                <FaStar color='Red' />
                            </Box>
                            <Box>
                                <FaStar color='Red' />
                            </Box>
                        </HStack>
                        <SimpleGrid columns={{ base: '1', md: '3' }} spacing={10} margin='80px 0px 60px '>
                            <Box textAlign={'left'} p=' 40px ' boxShadow={'0px 10px 80px 5px rgba(0, 0, 0, 0.13)'}>
                                <Image alt='online gym programming - quote icon' maxW={{ base: '100%', sm: '70%', md: '100%', xl: '100%' }} src='/images/small-quote.svg' />
                                <Heading title='Top class coaching' variant='extrasmall' margin='0px 0px 20px'></Heading>
                                <Text title='Awesome experience on this program, Top class coaching - learnt a lot about technique and form, a great platform to build on. Also some great workouts thrown in too! Looking forward to the next stage! '></Text>
                                <Heading title='- John' variant='extrasmall' margin='30px 0px 0px' color='#787878'></Heading>
                            </Box>
                            <Box textAlign={'left'} p=' 40px ' boxShadow={'0px 10px 80px 5px rgba(0, 0, 0, 0.13)'}>
                                <Image alt='online gym programming - quote icon' maxW={{ base: '100%', sm: '70%', md: '100%', xl: '100%' }} src='/images/small-quote.svg' />
                                <Heading title='Easy to follow and fun' variant='extrasmall' margin='0px 0px 20px'></Heading>
                                <Text title='The program is such a fantastic introduction to strength and conditioning, the workouts are very easy to follow and fun, and the group interaction it so much fun!'></Text>
                                <Heading title='- Grainne' variant='extrasmall' margin='30px 0px 0px' color='#787878'></Heading>
                            </Box>
                            <Box textAlign={'left'} p=' 40px ' boxShadow={'0px 10px 80px 5px rgba(0, 0, 0, 0.13)'}>
                                <Image alt='online gym programming - quote icon' maxW={{ base: '100%', sm: '70%', md: '100%', xl: '100%' }} src='/images/small-quote.svg' />
                                <Heading title='Program was perfect' variant='extrasmall' margin='0px 0px 20px'></Heading>
                                <Text title='The program was a perfect way to get started with training. Eoghan was extremely helpful and professional and made understanding the workouts very easy.'></Text>
                                <Heading title='- Emma' variant='extrasmall' margin='30px 0px 0px' color='#787878'></Heading>
                            </Box>

                        </SimpleGrid>

                    </Box>

                </Container>
            </Flex>

            <Flex p={{ sm: '55px 0px 60px', md: '150px 0px' }} background='black'>
                <Container maxW={{ sm: 'Container.sm', md: 'container.md', lg: 'container.lg', xl: 'container.xl' }}>
                    <Stack direction={{ sm: "column", md: "row" }} spacing='24px'>
                        <Box width={{ sm: '100%', md: '50%' }}>
                            <Box paddingLeft='30px' position={'relative'} _after={{ position: 'absolute', content: '""', width: '3px', height: '100%', top: '0', left: '0', background: 'Red' }}>
                                <Heading title='FREQUENTLY ASKED QUESTIONS' lineHeight='1' padding='0px 0px 30px' margin='0px auto' color='white'></Heading>
                                <Text color='white' title='Questions about the classes or next steps? We got you. If you need more information, please feel free to contact us hello@perpetua.ie' width='80%' ></Text>
                            </Box>
                        </Box>
                        <Box width={{ sm: '100%', md: '50%' }}>
                            <Accordion defaultIndex={[0]} allowMultiple className='Accor_item _black_acording'>
                                <AccordionItem >
                                    <h2>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left' >
                                                <Heading title='What is Perpetua Online Programming?' color='white' variant='extrasmall'></Heading>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text color='white' title='Perpetua Training is a fitness app which is dedicated to delivering high-quality training programs both in physical gym locations and online. Founded in 2020, Perpetua Training focuses on expert coaching and structured programs designed to achieve tangible results. They cater to a global audience by offering digital fitness solutions in addition to their local gym services.' margin='0px 0px 20px'></Text>
                                        {/* <Text color='white' title='We own and operate brick and mortar PERPETUA Gym locations in addition to providing workouts and fitness to athletes across the word, digitally.' margin='0px 0px 20px'></Text> */}
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left' >
                                                <Heading color='white' title='DO YOU OFFER A FREE TRIAL?' variant='extrasmall'></Heading>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text color='white' title='We offer a 7-day free trial to Perpetua Training app before you subscribe for 30 euros a month.' margin='0px 0px 20px'></Text>
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left' >
                                                <Heading color='white' title='What is Perpetua Strength ?' variant='extrasmall'></Heading>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text color='white' title='Perpetua Strength is our signature program which has helped 1000’s of members. The program is for
                            individuals who have previous experience in compound lifting. This is for people who enjoy a
                            mixture of strength and gymnastics and need to keep their training window to 60 min or less. We
                            structure your workout from warmup to cool down to allow you to be as productive as possible with
                            your time.' margin='0px 0px 20px'></Text>
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left' >
                                                <Heading color='white' title='What is Perpetua Bodybuilding ?' variant='extrasmall'></Heading>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text color='white' title='The program to suit all your hypertrophy needs is designed by Head Coach of Perpetua Fitness,
                            Eoghan McGregor who has over 15 years experience in the fitness industry. This is a program which
                            has been tested, followed and got results for each individual. Join this program which is created by
                            someone who actually has experience in the industry and follows the program' margin='0px 0px 20px'></Text>
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left' >
                                                <Heading color='white' title='What is Perpetua Conditioning ? ' variant='extrasmall'></Heading>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text color='white' title='Our Conditioning days in Perpetua Fitness are one of our most popular days with members and now
                                we are delivering this to individuals online.' margin='0px 0px 20px'></Text>
                                        <Text color='white' title='This program is for people who want to develop a broad endurance base across multiple monostructural modalities. Following this path should provide the preparation and confidence necessary
                            to be able to jump into any conditioning event/workout.' margin='0px 0px 20px'></Text>

                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left' >
                                                <Heading color='white' title='WHO WRITES THE PERPETUA ONLINE PROGRAM?' variant='extrasmall'></Heading>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text color='white' title='Every PERPETUA workout is written, tested, and refined by our Perpetua Programming Team which is headed up by Head Coach Eoghan McGregor. The Programming Team consists of an elite group of Perpetua Coaches with several years of coaching experience amongst them.' margin='0px 0px 20px'></Text>
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left' >
                                                <Heading color='white' title='What is Perpetua HYROX?' variant='extrasmall'></Heading>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text color='white' title='The program is designed by Head Coach of Perpetua Fitness, Eoghan McGregor who has over 15
                            years experience in the fitness industry. This is a program which has been tested, followed and got
                            results for everyone who entered a HYROX race. ' margin='0px 0px 20px'></Text>
                                        <Text color='white' title='Not every training day needs high intensity so this is where our Perpetua Build program can be
                            perfect for you. Perpetua HYROX program will involve a mix between strength and conditioning
                            work, so you are in your best shape to enter a HYROX event.' margin='0px 0px 20px'></Text>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                        </Box>
                    </Stack>

                </Container>
            </Flex>
            <BlogSection catSlug='online-training' />
            {/* <Flex textAlign='center' p={{sm:"30px 0 50px", md:'60px 0px'}}>
        <Container maxW='container.xl'>
            <Image  alt='image' maxW={{base:'100%', sm:'70%', md:'100%', xl:'100%'}} margin='0px auto' src='/images/logo-icon.svg'/>
            <Heading title='3 DAILY PROGRAMS. 7 DAYS A WEEK' lineHeight='1' variant='medium' padding='0px 0px 30px' margin='0px auto'></Heading>
            <Text title='Get access to our programs for 7 days before deciding to commit to Perpetua Online.' width={{sm:'100%', md: '40%'}} margin='0px auto'></Text>
            <Link href='timetable' _hover={{textDecoration:'none'}}>
                <Button className='Download_APP' color='black' title='Try IT Free' margin='50px 0px 0px'  />
            </Link>
        </Container>
      </Flex> */}

            <Flex p={'100px 0px'} background='url(/images/bottom-bg2.jpg)'>
                <Container maxW='container.xl'>
                    <Box width={'66%'} className='wow animate fadeInUp'>
                        <Heading title='PERPETUA ONLINE PROGRAM' color='white' lineHeight='1' variant='extrasmall' padding='0px 0px 30px' margin='0px auto'></Heading>
                        <Heading title='PROGRAM DESIGNED FOR PEOPLE OF ALL FITNESS LEVELS ' color='white' lineHeight='1' padding='0px 0px 30px' margin='0px auto'></Heading>
                        <Link href='https://perpetuatraining.com/' _hover={{ textDecoration: 'none' }} target='_blank'>
                            <Button className='Download_APP' title='SIGN UP NOW' margin='50px 0px 0px' />
                        </Link>
                    </Box>
                </Container>
            </Flex>

        </>
    );
}

export default OnlinePrograming;
