import React from 'react';
import { Box, Container, SimpleGrid, Image, Button, GridItem, color, Center, Flex } from '@chakra-ui/react';
import InnerBanner from '../component/InnerBanner/InnerBanner';
import MetaTitle from '../pages/MetaTitle';
import Text from '../component/SubHeading/SubHeading';
import Heading from '../component/Heading/Heading';
import { NavLink } from 'react-router-dom';


const SummerOpeningParties = () => {
    return (
        <>
            <MetaTitle title='Summer Opening Parties | Perpetua Fitness' />
            <InnerBanner
                backgroundColor='black'
                backgroundImage={{ sm: 'url(./images/SummerOpeningPartiesBannerSm.webp)', md: 'url(./images/SummerOpeningPartiesBanner.webp)' }}
                isHeading={false}
                issubtextpara={false}
                isButton={false}
                isButton2={false}
                issubtitleRight={false}
                isSubtext={false}
                width={{ sm: '100%', md: '100%' }}
            />
            <Box backgroundColor='#000' padding={{ sm: "50px 0", md: "80px 0" }}>
                <Container maxW='container.xl'>
                    <Text title='The opening parties you don’t want to miss. Join our events and themed classes every weekend in May in both Windmill Lane & Lennox Street! ' color='#fff' fontSize={{ sm: "16px", lg: '20px' }} textAlign='center' />
                    <Heading title='Book into your favourite studio class & join the fun ' variant='medium' color='#fff' textAlign='center' padding='10px 0' />
                    <Text title='Expect cocktails, themed classes & some surprises along the way!  ' color='#fff' fontSize={{ sm: "16px", lg: '20px' }} textAlign='center' />
                </Container>
            </Box>
            <Heading title='Check out some of the things we have planned: ' variant='extralarge' padding='50px 0 0' textAlign='center' />
            <Container maxW='container.xl' padding={{ sm: "30px 10px 0", md: '50px 20px 0' }}>
                <Heading title='LUNCH ON US' as='h2' variant='medium' padding='0 0 30px' textAlign='center' />
                <Flex alignItems='center' flexDirection={{ sm: "column", md: "row" }} >
                    <Box width={{ md: '50%' }} justifyContent={{ md: "flex-end" }} display={{ md: "flex" }} >
                        <Image alt='image' src="./images/LunchOnUs2.webp" objectFit='cover' objectPosition='center' height={{ sm: "450px", md: "650px" }} width={{ md: "550px" }} />
                    </Box>
                    <Box width={{ md: '50%' }} paddingLeft={{ md: "60px" }} paddingTop={{ sm: "25px", md: "0" }}>
                        <Heading title='Every Friday at lunch class in Lennox Steet' as='h2' lineHeight='0.8' variant='medium' />
                        <Text title='Book into any lunch time Studio class in Lennox Street on Fridays and receive a complimentary shake after class!* ' fontSize={{ sm: "16px", lg: '20px' }} padding='10px 0' />
                        <Text title='*Choice of Pure Protein Vanilla or Chocolate.' fontSize={{ sm: "16px", lg: '20px' }} />
                    </Box>
                </Flex>
            </Container>
            <Container maxW='container.xl' padding={{ sm: "30px 10px 0", md: '50px 20px 0' }}>
                <Heading title='PERPETUA & PROSECCO' as='h2' variant='medium' padding='0 0 30px' textAlign='center' />
                <Flex alignItems='center' flexDirection={{ sm: "column-reverse", md: "row" }} >
                    <Box width={{ md: '50%' }} paddingLeft={{ md: "20px", xl: "50px" }} paddingTop={{ sm: "25px", md: "0" }}>
                        <Heading title='Every Friday from 5pm in Windmill Lane & Lennox Street' as='h2' lineHeight='0.8' variant='medium' />
                        <Text title='Book into any evening class in Windmill Lane or Lennox Street on Fridays and receive a glass of bubbly after class! ' fontSize={{ sm: "16px", lg: '20px' }} padding={{ sm: "10px 0 0", md: "15px 0 0" }} />
                        <NavLink to='/timetable'>
                            <Center padding={{ sm: "20px 0 0", md: "30px 0 0" }}>
                                <Button backgroundColor='transparent' textTransform='uppercase' border='2px solid #D12C41' fontSize='16px' fontFamily='Poppins' padding='25px 10px' borderRadius='30px' fontWeight='500' _hover={{ backgroundColor: '#D12C41', color: "#fff" }} >Book your Friday class here</Button>
                            </Center>
                        </NavLink>
                    </Box>
                    <Box width={{ md: '50%' }} justifyContent={{ xl: "flex-end" }} display={{ xl: "flex" }}>
                        <Image alt='image' src="./images/summerImages4.webp" objectFit='cover' objectPosition='center' height={{ sm: "450px", md: "650px" }} width={{ md: "550px" }} />
                    </Box>
                </Flex>
            </Container>
            <Container maxW='container.xl' padding={{ sm: "40px 10px 0", md: '50px 20px 0' }}>
                <Heading title='LUCKY LOCKERS' as='h2' variant='medium' padding='0 0 30px' textAlign='center' />
                <Flex alignItems='center' flexDirection={{ sm: "column", md: "row" }} >
                    <Box width={{ md: '50%' }} justifyContent={{ md: "flex-end" }} display={{ md: "flex" }}>
                        <Image alt='image' src="./images/bar1.webp" objectFit='cover' objectPosition='center' height={{ sm: "450px", md: "650px" }} width={{ md: "550px" }} />
                    </Box>
                    <Box width={{ md: '50%' }} paddingLeft={{ md: "60px" }} paddingTop={{ sm: "25px", md: "0" }}>
                        <Heading title='Saturday 4th May in Windmill Lane & Lennox Street.' as='h2' lineHeight='0.8' variant='medium' />
                        <Text title='Check your locker for spot prizes throughout the day! We’ve got goodies from Gym+Coffee, Sláinte Saunas along with complimentary guest passes, shakes & discounted packs!' fontSize={{ sm: "16px", lg: '20px' }} padding='10px 0' />
                    </Box>
                </Flex>
            </Container>
            <Container maxW='container.xl' padding={{ sm: "30px 10px 0", md: '50px 20px 0' }}>
                <Heading title='LIQUID TO LIPS POP UP BAR' as='h2' variant='medium' textAlign='center' padding='0 0 30px' />
                <Flex alignItems='center' flexDirection={{ sm: "column-reverse", md: "row" }}  >
                    <Box width={{ md: '50%' }} paddingLeft={{ md: "20px", xl: "50px" }} paddingTop={{ sm: "25px", md: "0" }}>
                        <Heading title='Saturday 11th May in Windmill Lane' as='h2' lineHeight='0.8' variant='medium' padding='0 0 15px' />
                        <Heading title='Saturday 18th May in Lennox Street' as='h2' lineHeight='0.8' variant='medium' />
                        <Text title='Stop by our pop-up bar with our friends from Liquid to Lips shaking up alcoholic, low alcohol & non-alcoholic cocktails! ' fontSize={{ sm: "16px", lg: '20px' }} padding='10px 0 0' />
                          <Text title='Try our Perpetua cocktails from 10am!' fontSize={{ sm: "16px", lg: '20px' }} padding='10px 0' />
                    </Box>
                    <Box width={{ md: '50%' }} justifyContent={{ xl: "flex-end" }} display={{ xl: "flex" }}>
                        <Image alt='image' src="./images/summerImages5.webp" objectFit='cover' objectPosition='center' height={{ sm: "450px", md: "650px" }} width={{ md: '550px' }} />
                    </Box>
                </Flex>
            </Container>
            <Container maxW='container.xl' padding={{ sm: "30px 10px", md: '50px 20px 0' }}>
                <Heading title='ICON CLASS ' as='h2' variant='medium' padding='0 0 30px' textAlign='center' />
                <Flex alignItems='center' flexDirection={{ sm: "column", md: "row" }} >
                    <Box width={{ md: '50%' }} justifyContent={{ md: "flex-end" }} display={{ md: "flex" }}>
                        <Image alt='image' src="./images/GympassPhoto9.webp" objectFit='cover' objectPosition='center' height={{ sm: "450px", md: "650px" }} width={{ md: '550px' }} />
                    </Box>
                    <Box width={{ md: '50%' }} paddingLeft={{ md: "60px" }} paddingTop={{ sm: "25px", md: "0" }}>
                        <Heading title='Saturday 11th May in Windmill Lane' as='h2' lineHeight='0.8' variant='medium' padding='0 0 15px' />
                        <Heading title='Saturday 18th May in Lennox Street' as='h2' lineHeight='0.8' variant='medium' />
                        <Text title='If you’ve attended 75+ Studio Classes with us, you’re invited to our exclusive ICON Class dedicated to YOU!' fontSize={{ sm: "16px", lg: '20px' }} padding={{ sm: "10px 0 0", md: "15px 0 0" }} />
                        <Text title='Keep an eye on your inbox over the coming days for details.' fontSize={{ sm: "16px", lg: '20px' }} padding={{ sm: "10px 0 0", md: "15px 0 0" }} />
                    </Box>
                </Flex>
            </Container>
            <Container maxW='container.xl' padding={{ sm: "30px 10px", md: '50px 20px' }}>
                <Heading title='A FEW MORE SURPRISES!' s='h2' variant='medium' padding='0 0 20px' textAlign='center' />
                <Text title='For updates on themed classes, keep an eye on socials or our Perpetua App. '
                    fontSize={{ sm: "16px", lg: '20px' }} textAlign='center'
                />
                <NavLink to='/timetable'>
                    <Center padding={{ sm: "20px 0 0", md: "30px 0 0" }}>
                        <Button backgroundColor='transparent' border='2px solid #D12C41' fontSize='16px' fontFamily='Poppins' textTransform='uppercase' padding='25px 10px' borderRadius='30px' fontWeight='500' _hover={{ backgroundColor: '#D12C41', color: "#fff" }} >Book into class</Button>
                    </Center>
                </NavLink>
            </Container>
        </>
    )
}

export default SummerOpeningParties;