import { Box, Flex, Link} from "@chakra-ui/react";
import styled from "@emotion/styled/macro";
// import { device } from "../../Device.types";


// export const BoxWrapper= styled(Box)(props => ({
//     padding:0,
//     margin:0,
//     position:'relative',
//     width:'90%',
//     display:'flex',
//     flexDirection:'column',
//     "@media screen and (max-width:1366px)": {
//         width: '100%',
//     },
    
// }))
export const BoxWrap= styled(Flex)(props => ({
    padding:0,
    margin:0,
    position:'relative',
    width:'90%',
    display:'flex',
    flexDirection:'column',
    "@media screen and (max-width:1366px)": {
        width: '100%',
    },
    
}))

export const HeadingWrapper= styled(Box)(props => ({
    padding:0,
    margin:0,
    position:'absolute',
    left:'0',
    bottom:'30px',
    width:'100%',
    textAlign:'center',
}))

export const ParaWrapper= styled(Link)(props => ({
    padding:'0px 15px',
    paddingTop:'20px',
    margin:'0 auto',
    width:'100%',
    textAlign:'center',
    position: 'absolute',
    top: 0,
    height:' 100%',
    display: 'none',
    alignItems: 'center',
    justifyContent:'center',
    opacity:0,
    background:'rgba(0,0,0,0.8)'

}))

export const ImageBox= styled(Box)(props => ({
    padding:0,
    margin:0,
    position:'relative',
    width:'100%',
    height:'450px',
    backgroundPosition:'center',
    backgroundRepeat:'repeat',
    backgroundSize:'cover',
}))


export const LeftBox= styled(Link)(props => ({
    display:'flex',
    flexDirection:'row',
    alignItems:'center',
    gap:'20px',
    flexWrap:'wrap'
}))

export const LeftBoxContent= styled(Box)(props => ({
    width:'55%',
    textAlign:'right'

}))

export const RightBoxContent= styled(Box)(props => ({
    width:'55%',
    textAlign:'Left'

}))

export const LeftBoxImage= styled(Box)(props => ({
    width:'41%',
    height:'400px',
    backgroundPosition:'center',
    backgroundRepeat:'repeat',
    backgroundSize:'cover',
}))