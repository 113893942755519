import React from 'react';
import * as Style from './CorporateCard.style';
import CorporateContent from './CorporateContent';
import { CorporateContentProps,defaultCorporateContentProps } from './CorporateCard.types';
const CorporateimageCard  = ( props ) => {
  return (
  <> 
    <Style.CorporateimageCard >
        {props.children}
        <CorporateContent {...props}></CorporateContent>
    </Style.CorporateimageCard>
  </>
  );
}
export default CorporateimageCard;
CorporateContentProps.propTypes = CorporateContentProps;
defaultCorporateContentProps.defaultProps = defaultCorporateContentProps;