import React, { useEffect } from 'react';
import { Box, Container, Stack, Flex, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, GridItem, SimpleGrid, Image, VStack, UnorderedList, ListItem, Link, } from '@chakra-ui/react';
import InnerBanner from "../component/InnerBanner/InnerBanner";
import Heading from "../component/Heading/Heading";
import Text from "../component/SubHeading/SubHeading";
import ContactForm from "../component/Form/ContactForm";
import LinkButton from "../component/Button/LinkButton";
import MetaTitle from "./MetaTitle";
import WOW from 'wowjs';

const CorporateFitnessManagment = () => {

    useEffect(() => {
        new WOW.WOW({
            live: true,
            offset: 0,
            mobile: true,
        }).init();
    }, []);

    return (
        <>
            <MetaTitle title='Corporate Fitness Managment | Perpetua Fitness' />
            <InnerBanner
                backgroundColor='black'
                backgroundImage={{ sm: "url(./images/residential-service_m.jpg)", md: 'url(./images/residential-service.jpg)' }}
                isSubtext={false}
                title='Fitness Center Managment'
                subtextpara="Proven operations and management expertise to deliver world-class results"
                issubtextpara={true}
                isButton={false}
                issubtitleRight={false}
                width={{ sm: "100%" }}
                height={{ sm: '100vh', md: '500px', lg: '550px', xl: '767px' }}
            />

            <Box padding={{ sm: '50px 0 25px', md: '70px 0', xl: "80px 0" }}>
                <Container maxW='container.xl'>
                    <Box textAlign='center'>
                        <Heading title="About" color='black' variant='medium' />
                        <Heading title="Fitness Center Management" color='black' padding={{ sm: '0 0 10px', md: '0' }} />
                        <Text title="Our comprehensive, all-inclusive corporate gym management services will free up your time, allowing you to focus on your business or community."
                            color='black' width={{ md: '80%' }} margin='0 auto' />

                        <Text title="We have built our reputation through quality, world-class service to our clients. We focus on your goals and work tirelessly to always exceed expectations. We exemplify the highest level of excellence in our fitness centers, equipment, employees, programs, activities, and associations. These standards are what we commit to providing and what our clients have come to expect when they work with us."
                            color='black' width={{ md: '80%' }} margin='0 auto' padding='15px 0' />

                        <Text title="We offer industry leading monthly reporting, showing you vital data on usage, enrollments, class attendance, ROI and more."
                            color='black' width={{ md: '80%' }} margin='0 auto' />
                    </Box>
                </Container>
            </Box>

            <Box padding={{ sm: '50px 0', md: '70px 0', xl: "80px 0" }} backgroundColor='#000'>
                <Container maxW='container.xl'>
                    <SimpleGrid
                        columns={{ sm: '1', md: '2' }}
                        spacing='20px'
                        flexDirection={{ sm: "column-reverse", md: "row" }}
                        display={{ sm: 'flex', md: "grid" }}
                    >
                        <GridItem margin={{ sm: "30px auto 0", md: "0 auto" }}>
                            <Heading title='Our On-Site Management Services' variant='large' lineHeight='0.8' color='#fff' />
                            <Flex gap='15px'>
                                <Box>
                                    <UnorderedList listStyleType='none' fontFamily='Poppins' fontSize='12px' marginTop='25px' padding='0' marginInlineStart='0' color='#fff' >
                                        <ListItem padding='5px 0' display='flex'>
                                            <Image src="./images/Vector.png" width='12px' height='12px' marginTop='3px' marginRight='7px' /> On-site management + staffing
                                        </ListItem>
                                        <ListItem padding='5px 0' display='flex'>
                                            <Image src="./images/Vector.png" width='12px' height='12px' marginTop='3px' marginRight='7px' />Group Fitness
                                        </ListItem>
                                        <ListItem padding='5px 0' display='flex'>
                                            <Image src="./images/Vector.png" width='12px' height='12px' marginTop='3px' marginRight='7px' /> Personal Training
                                        </ListItem>
                                        <ListItem padding='5px 0' display='flex'>
                                            <Image src="./images/Vector.png" width='12px' height='12px' marginTop='3px' marginRight='7px' />Nutrition Services
                                        </ListItem>
                                        <ListItem padding='5px 0' display='flex'>
                                            <Image src="./images/Vector.png" width='12px' height='12px' marginTop='3px' marginRight='7px' />Spa Services
                                        </ListItem>
                                        <ListItem padding='5px 0' display='flex'>
                                            <Image src="./images/Vector.png" width='12px' height='12px' marginTop='3px' marginRight='7px' />Wellness Programming
                                        </ListItem>
                                        <ListItem padding='5px 0' display='flex'>
                                            <Image src="./images/Vector.png" width='12px' height='12px' marginTop='3px' marginRight='7px' />Fitness Challenges
                                        </ListItem>
                                        <ListItem padding='5px 0' display='flex'>
                                            <Image src="./images/Vector.png" width='12px' height='12px' marginTop='3px' marginRight='7px' /> Health Assessments
                                        </ListItem>
                                        <ListItem padding='5px 0' display='flex'>
                                            <Image src="./images/Vector.png" width='12px' height='12px' marginTop='3px' marginRight='7px' /> Health Screenings
                                        </ListItem>
                                    </UnorderedList>
                                </Box>
                                <Box>
                                    <UnorderedList listStyleType='none' fontFamily='Poppins' color='#fff' fontSize='12px' marginTop='25px' padding='0' marginInlineStart='0'>
                                        <ListItem padding='5px 0' display='flex'>
                                            <Image src="./images/Vector.png" width='12px' height='12px' marginTop='3px' marginRight='7px' /> Member Recruitment & Enrollment
                                        </ListItem>
                                        <ListItem padding='5px 0' display='flex'>
                                            <Image src="./images/Vector.png" width='12px' height='12px' marginTop='3px' marginRight='7px' />Preventative Maintenance
                                        </ListItem>
                                        <ListItem padding='5px 0' display='flex'>
                                            <Image src="./images/Vector.png" width='12px' height='12px' marginTop='3px' marginRight='7px' />Facility Cleanliness and Maintenance
                                        </ListItem>
                                        <ListItem padding='5px 0' display='flex'>
                                            <Image src="./images/Vector.png" width='12px' height='12px' marginTop='3px' marginRight='7px' />Membership Account Management
                                        </ListItem>
                                        <ListItem padding='5px 0' display='flex'>
                                            <Image src="./images/Vector.png" width='12px' height='12px' marginTop='3px' marginRight='7px' />Marketing
                                        </ListItem>
                                        <ListItem padding='5px 0' display='flex'>
                                            <Image src="./images/Vector.png" width='12px' height='12px' marginTop='3px' marginRight='7px' />Daily Operations
                                        </ListItem>
                                        <ListItem padding='5px 0' display='flex'>
                                            <Image src="./images/Vector.png" width='12px' height='12px' marginTop='3px' marginRight='7px' />Reporting
                                        </ListItem>
                                        <ListItem padding='5px 0' display='flex'>
                                            <Image src="./images/Vector.png" width='12px' height='12px' marginTop='3px' marginRight='7px' /> Health Assessments
                                        </ListItem>
                                        <ListItem padding='5px 0' display='flex'>
                                            <Image src="./images/Vector.png" width='12px' height='12px' marginTop='3px' marginRight='7px' /> Industry Leading Technology
                                        </ListItem>
                                    </UnorderedList>
                                </Box>

                            </Flex>
                        </GridItem>
                        <GridItem margin="0 auto">
                            <Image src='./images/PERPETUA-DISCOUNTS.jpg' className='wow animate fadeInUp' />
                        </GridItem>
                    </SimpleGrid>
                </Container>
            </Box>


            <Flex p={{ sm: '25px 0 50px', md: '70px 0 70px', xl: "80px 0" }}>
                <Container maxW={{ sm: 'Container.sm', md: 'container.lg', lg: 'container.lg', xl: 'container.xl' }}>
                    <Stack direction={{ sm: 'column', md: 'row' }} spacing='24px'>
                        <Box width={{ sm: '100%', md: '50%' }}>
                            <Box paddingLeft='30px' position={'relative'} _after={{ position: 'absolute', content: '""', width: '3px', height: '100%', top: '0', left: '0', background: 'Red' }}>
                                <Heading title='Industry Leading Management and Service' lineHeight='1' padding='0px 0px 30px' margin='0px auto' ></Heading>
                                <Text title='Find out why we have been the leader in Fitness Center Management for over 40 years!' width='80%' ></Text>
                            </Box>
                        </Box>
                        <Box width={{ sm: '100%', md: '50%' }}>
                            <Accordion defaultIndex={[0]} allowMultiple className='Accor_item _black_acording  white_acording'>
                                <AccordionItem >
                                    <h2>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left' >
                                                <Heading title='Staffing' variant='extrasmall' />
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text title='Our strength is in our people. We are proud to say we hire and train the best employees in the fitness and wellness industry. All our associates have earned a degree in Exercise Science, are certified in CPR/First Aid/Blood Borne Pathogen and are all passionate about their work. Each member of your staff will be friendly, professional, and experienced.  Your guests and employees will always be greeted by their first name, making them feel comfortable and supported, increasing their likelihood to return. Our associates are experts at helping every fitness level, from beginner to athlete. Their passion is contagious, and they will help your guests succeed.' margin='0px 0px 20px' />
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left' >
                                                <Heading title="Corporate Support" variant='extrasmall' />
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text title="Every facility we manage is supported by our experienced corporate staff. Our corporate support includes accounting, reporting, recruiting, marketing, training, and program development. It also provides oversight to our facilities, ensuring that they are meeting and exceeding the standards that have been set. By centralizing certain aspects of managing a health and fitness center, we allow our associates and onsite managers to do what they do best—motivate and inspire others to make healthy changes in their lives." margin='0px 0px 20px' />
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left' >
                                                <Heading title='Operations' variant='extrasmall' />
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text title="Between our onsite managers and our corporate support, we can handle any operational issues that arise. We can work with your existing operational procedures, or we can work with you to streamline any processes that aren’t working. We operate efficiently, effectively, and within budget—taking care of your people as if they were our own." margin='0px 0px 20px' />
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left' >
                                                <Heading title="Marketing" variant='extrasmall' />
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text title='Whether you would like to get more people to come to your facility, or if you’re looking to promote a new program or event, we can help you develop a marketing plan that will help you reach your goals. Our in-house marketing team has decades of experience marketing fitness centers, and we would love to share our knowledge with you and breathe new life into your facility with a detailed, effective marketing campaign.' margin='0px 0px 20px' />
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left' >
                                                <Heading title="Health and Wellness Programs" variant='extrasmall' />
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text title='We offer a wide variety of health, fitness and wellness programs that can be customized to the needs of your guests and your goals. All of our programs are designed to get results and to be flexible enough to help people of all fitness levels. You can choose which programs will work best for you, and we’ll work with you to ensure that they fit together seamlessly. ' margin='0px 0px 20px' />
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left' >
                                                <Heading title="Reporting and Analytics" variant='extrasmall' />
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text title="Knowledge is power, and we want to ensure that you have the data you need to make smart decisions about your facility. We provide extensive reports, delivered monthly, to give you a clear picture of every aspect of your center. Our reports detail information like overall attendance, new enrollments, high-traffic times, personal training sessions, program usage, and more. Keep up to date on everything happening with our detailed reports." margin='0px 0px 20px' />
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                        </Box>
                    </Stack>
                </Container>
            </Flex>


            <VStack backgroundColor='black' padding={{ sm: '30px 0px', md: '96px 0px' }} borderBottom='1px solid #232323'>
                <Container
                    display='flex'
                    maxW={{ md: 'container.lg', xl: 'container.xl' }}
                    flexDirection={{ base: 'column', lg: 'row' }}
                    alignItems='top'
                    alignContent='center'
                    justifyContent='center'
                    justifyItems='center'
                >
                    <Box w={{ md: '100%', lg: '45%' }} textAlign={{ base: 'center', lg: 'left' }} marginBottom={{ base: '50px', lg: '0' }}>
                        <Heading title='LOOKING FOR MORE INFORMATION' variant='extralarge' lineHeight='1' margin='0px 0px 40px' color='white'></Heading>
                        <Heading title='Chat with a member of the team' variant='smallmid' lineHeight='1' margin='0px 0px 30px' color='white'></Heading>
                        <Link href='/contact-us' textDecoration='none' display='inline-block' _hover={{ textDecoration: 'none' }}>
                            <LinkButton
                                title='BOOK A CALL'
                                Imguri='/images/red_arrow.svg'
                                border='2px solid' borderColor='Red'
                                padding='20px 25px'
                                color='White'
                            ></LinkButton>
                        </Link>
                    </Box>
                    <Box w={{ md: '100%', lg: '55%' }}>
                        <ContactForm pageName="Residential"></ContactForm>
                    </Box>
                </Container>
            </VStack>

        </>
    )
}

export default CorporateFitnessManagment;