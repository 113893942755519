import React, { useCallback, useMemo } from 'react';
import CookieConsent from "react-cookie-consent";
import Cookies from "universal-cookie"

const CookeiConsentBanner = () => {

    const cookies = useMemo(() => new Cookies(), []);

    const loadLinkedIn = () => {
        var _linkedin_partner_id = "5649572";
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
        window._linkedin_data_partner_ids.push(_linkedin_partner_id);


        setTimeout(function () {
            (function (l) {
                if (!l) {
                    window.lintrk = function (a, b) { window.lintrk.q.push([a, b]) };
                    window.lintrk.q = []
                }
                var s = document.getElementsByTagName("script")[0];
                var b = document.createElement("script");
                b.type = "text/javascript"; b.async = true;
                b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
                s.parentNode.insertBefore(b, s);
            })(window.lintrk);
        }, 1000)
    }


    const loadmetapixcelcode = () => {
        const metapixcek = document.createElement('script');
        metapixcek.innerHTML = `!function (f, b, e, v, n, t, s) {
                if (f.fbq) return; n = f.fbq = function () {
                n.callMethod ?
                n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                };
                if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
                n.queue = []; t = b.createElement(e); t.async = !0;
                t.src = v; s = b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t, s)
                }(window, document, 'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '210800775269908');
                fbq('track', 'PageView');`;

        document.body.appendChild(metapixcek);

        const noscriptPix = document.createElement('noscript');
        noscriptPix.innerHTML = `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=210800775269908&ev=PageView&noscript=1" />`;
        document.body.appendChild(noscriptPix);

    };



    const handleLoadGoogleTagManager = () => {
        const script = document.createElement('script');
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtm.js?id=GTM-588XFN33`;
        document.head.appendChild(script);

        script.onload = () => {
            window.dataLayer = window.dataLayer || [];
            function gtag() { window.dataLayer.push(arguments); }
            gtag('js', new Date());
            gtag('config', 'GTM-588XFN33');
        };

        // Also add the GTM noscript part for better functionality
        const noscript = document.createElement('noscript');
        noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-588XFN33" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
        document.body.appendChild(noscript);
    };

    const handleLoadGoogleAnalytics = () => {
        const script = document.createElement('script');
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtag/js?id=G-LKVE1T3E8M`;
        document.head.appendChild(script);

        script.onload = () => {
            window.dataLayer = window.dataLayer || [];
            function gtag() { window.dataLayer.push(arguments); }
            // function fbq() { window.dataLayer.push(arguments); }
            gtag('js', new Date());
            gtag('config', 'G-LKVE1T3E8M');
        };
    };


    function gtag() {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(arguments);
    }



    const sendConsent = useCallback((consent) => {
        gtag('consent', 'update', consent);
    }, []);

    const handleDecision = (outcome) => {
        const consent = {
            'ad_storage': outcome,
            'analytics_storage': outcome,
            'ad_user_data': outcome,
            'ad_personalization': outcome,
            'personalization_storage': outcome,
        }

        cookies.set("_perpCookies", consent, {
            expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
            path: "/",
            domain: process.env.REACT_APP_DOMAIN
        })

        if(outcome === 'granted'){
            loadLinkedIn();
            loadmetapixcelcode();
            handleLoadGoogleTagManager();
            handleLoadGoogleAnalytics();
        }       

        sendConsent(consent);

    }




    return (
        <>
            <CookieConsent
                location="bottom"
                buttonText="Allow cookies"
                cookieName="perpetuacookiesconsent"
                enableDeclineButton
                declineButtonText="Decline"
                style={{ background: "#e6374b", fontSize: "16px", fontFamily: " Poppins", alignItems: "center", justifyContent: "center" }}
                buttonStyle={{ background: "#000", color: "#fff", fontSize: "14px", padding: "15px 25px" }}
                declineButtonStyle={{ background: "#000", color: "#fff", fontSize: "14px", padding: "15px 25px" }}
                expires={365}
                onAccept={
                    () => {
                        handleDecision("granted")
                    }
                }
                onDecline={
                    () => {
                        handleDecision("denied")
                    }
                }
            >
                Your experience on this site will be improved by allowing cookies.
            </CookieConsent>
        </>
    );
}

export default CookeiConsentBanner;
