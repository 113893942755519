import React from 'react';
import InnerBanner from '../component/InnerBanner/InnerBanner';
import Text from '../component/SubHeading/SubHeading';
import Heading from '../component/Heading/Heading';
import MetaTitle from '../pages/MetaTitle';
import { Box, Container, SimpleGrid, Image, GridItem, Link } from '@chakra-ui/react';
import Button from '../component/Button/CustomeButton';

const BratSummer = () => {

    const metaTitle = "Brat Summer Themed Spin Class Dublin | Perpetua Fitness"
    const metaDesc = 'Charli XCX, remixes, all the brat vibes, and "shots" after class - embrace brat girl summer and get messy with Niamh Cullen.'

    return (
        <>
            <MetaTitle title={metaTitle} content={metaDesc} />
            <InnerBanner
                backgroundColor='black'
                backgroundImage={{ sm: 'url(./images/brat_mb_31.webp)', md: 'url(./images/brat_sbanner1.webp)' }}
                isHeading={false}
                issubtextpara={false}
                isButton={false}
                isButton2={false}
                issubtitleRight={false}
                isSubtext={false}
                class='brat-summer'
            />

            <Box padding={{ sm: "50px 5px", md: "80px 0" }} backgroundColor='#000'>
                <Container maxW='container.xl'>
                    <Heading title='brat themed spin class' textAlign='center' variant='largheading' className='brat_summer_font' color='#8ace03' />
                     <Heading title='brat and it’s completely different but also still brat ' textAlign='center' variant='extralarge' className='brat_summer_font' color='#8ace03' />
                    <Text title='Charli XCX, remixes and all the brat vibes - get messy with Niamh Cullen & Maya Duncan.' textAlign='center' width={{ md: "70%" }} margin='0 auto' className='brat_summer_fontRegular' fontSize={{ sm: "20px", md: "22px", lg: '24px', xl:"30px" }} color='#fff' />
                </Container>
            </Box>

            <Box padding={{ sm: "50px 5px", md: "80px 0" }}>
                <Container maxW='container.xl'>
                    <SimpleGrid
                        columns={{ sm: "1", md: "2" }}
                        spacing={10}
                        alignItems='center'
                        justifyContent='center'
                    >
                        <GridItem>
                            <Image src='./images/bratSummer_img.webp' />
                        </GridItem>
                        <GridItem>
                            <Heading title='wednesday, 23rd october ' variant='medium' className='brat_summer_font' />
                            <Heading title='5.45pm & 6.45pm' variant='small' padding='5px 0 0' className='brat_summer_fontRegular' />
                            <Heading title='lennox street' variant='small' padding='5px 0 0' className='brat_summer_fontRegular' />

                            <Heading title='dress code: lime green' variant='medium' className='brat_summer_font' padding='20px 0 20px' />
                            {/* <Heading title='me green “shots” provided by White Claw' variant='small' padding='5px 0 0' className='brat_summer_fontRegular' />
                            <Heading title='live dj & goodies' variant='small' padding='5px 0 40px' className='brat_summer_fontRegular' /> */}
                            <Link href='https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=-106&sTG=69&sView=day&sLoc=2&date=10/23/24' isExternal={true}>
                                <Button
                                    title='book now for the plot'
                                    color='#000'
                                    className='brat_summer_fontRegular_btn'
                                />
                            </Link>
                        </GridItem>
                    </SimpleGrid>
                </Container>
            </Box>
        </>
    )
}

export default BratSummer;