import React from 'react';
import { Box, Container, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, useDisclosure, Link } from '@chakra-ui/react';
import Button from '../Button/CustomeButton';
import Heading from '../Heading/Heading';
import Text from '../SubHeading/SubHeading';
import { defaultCaptionProps } from '../Banner/Caption.type';
import { NavLink } from 'react-router-dom';
import VideoCard from '../VideoCard/VideoCard';
import * as Style from './InnerBanner.style';
function InnerBanner(props) {

  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Box
        className={props.class}
        alignItems={"center"}
        justifyContent={"center"}
        backgroundImage={props.backgroundImage}
        backgroundColor={props.backgroundColor}
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        height={{ sm: '85vh', md: '500px', lg: '550px', xl: '600px', xxl: '767px' }}
        backgroundSize={"cover"}
        display='flex'
        flexDirection='column'
      >
        <Container maxW='container.2xl' p={{ sm: '0 20px', md: "0 70px", lg: "0 130px" }} >

          <Box
            maxW={props.maxW ? props.maxW : { sm: '100%', md: '700px' }}
            minWidth={{ sm: '100%', md: '500px' }}
            paddingTop={"40px"}
            paddingBottom={"60px"}
            textAlign={props.textAlign}
            textTransform="uppercase"
            margin={props.align}
            className='wow animate fadeInUp'
          >

            {props.isSubtext && <Heading as='h2' variant={props.SubtextVariant ? props.SubtextVariant : "smallmid"} m={"0"} p={"0"} color={props.color} title={props.subtitle} fontFamily='BlenderProBold' width={props.SubtextWidth}></Heading>}
            {props.isHeading && <Heading color={props.color} title={props.title} lineHeight="1" padding="0px 0px 5px" variant="largheading" width={props.width} as='h1'></Heading>}
            {props.issubtextpara && <Text as='strong' fontSize={{ base: 'xs', md: "sm" }} m={"0"} display='block' color={props.color} title={props.subtextpara} fontFamily='BlenderProBold'></Text>}
            {props.isButton && <Box display={{ md: 'flex' }}> <NavLink to={props.Link}><Button margin={props.margin} title={props.buttontitle} /> </NavLink>
              <Box marginLeft={{ md: '10px' }} marginTop={props.ButtonModalmarginTop}>
                <Style.ButtonModal onClick={onOpen} color='white'>View The Experience</Style.ButtonModal>
                <Modal isOpen={isOpen} onClose={onClose} size={{ sm: 'sm', md: '3xl' }} >
                  <ModalOverlay />
                  <ModalContent>
                    {/* <ModalHeader>Modal Title</ModalHeader> */}
                    <ModalCloseButton />

                    <ModalBody >
                      <VideoCard src={props.videoModal} width={{ sm: '100%', md: '700px' }} margin={{ sm: '30px 3px', md: '30px 9px' }} maxHeight='540px'></VideoCard>
                    </ModalBody>

                  </ModalContent>
                </Modal>
              </Box>
            </Box>}
            {props.issubtitleRight && <Heading as='p' variant="smallmid" m={"0"} p={"0"} color={props.color} title={props.subtitleRight} fontFamily='BlenderProBold'></Heading>}
            {props.isButton2 && <Box display={{ md: 'flex' }}> <NavLink to={props.Link2}><Button margin={props.margin} title={props.buttontitle2} /> </NavLink></Box>}
            {props.isButton3 && <Box display={{ md: 'flex' }}  margin='20px 0 0'>
              { props.Link3 &&
                <NavLink to={props.Link3}><Button  title={props.buttontitle3} margin={{ sm:"0 0 10px", md:'0 10px 0 0'}} /> </NavLink>
              }
              <Box>
                <Link href={props.Link} isExternal='true'><Button title={props.buttontitle2} /> </Link>
              </Box>
              { props.buttontitle4 &&
                <Box>
                  <Link href={props.Link4} isExternal='true'><Button title={props.buttontitle4} margin={{ sm:"10px 0 0", md:'0 0 0 10px'}} /> </Link>
                </Box>
              }
            </Box>}
          </Box>

        </Container>

      </Box>
    </>
  )
}
export default InnerBanner;
InnerBanner.defaultProps = defaultCaptionProps