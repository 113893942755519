import React, { useState, useEffect } from "react";
import * as Style from "./TeamFilter.style";
import { FaAngleDown } from "react-icons/fa";
import { Box, Button } from "@chakra-ui/react";
import Heading from "../Heading/Heading";
import "./team.css";

const TabMenu = ({ filterItem, TabMenus, TabName, tabIndex }) => {
  // const [isMobile, setMobile] = useState(window.innerWidth < 768);
  // const [priceTab, setPriceTab] = useState(isMobile ? false : true);
  const [priceTab, setPriceTab] = useState(false);

  // const updateMedia = () => {
  //     setMobile(window.innerWidth < 768);
  //     isMobile ? setPriceTab(false) : setPriceTab(true);
  // };

  // useEffect(() => {
  //     window.addEventListener("resize", updateMedia);
  //     return () => window.removeEventListener("resize", updateMedia);
  // });

  const handelTabItem = () => {
    setPriceTab(!priceTab);
  };

  const handleTabItem = (curElem, index) => {
    filterItem(curElem, index);
    setPriceTab(false);
    // if (isMobile) {
    //     setPriceTab(false);
    // }
  };

  return (
    <Style.WraperBox position="relative">
      <Style.SmallWraper>
        <Button
          onClick={handelTabItem}
          display="flex"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
          className="-team-small-dropdown-button"
        >
          <Heading as="span" title={TabName} />
          <FaAngleDown />
        </Button>
      </Style.SmallWraper>

      {priceTab ? (
        <Style.ButtonWrapper className="teamList ptListALL">
          {TabMenus.map((curElem, index) => {
            return (
              <Style.FilterButon
                key={index}
                onClick={() => handleTabItem(curElem, index)}
                className={`tabBtn ${index === tabIndex ? "activeTab" : ""}`}
              >
                {curElem}
              </Style.FilterButon>
            );
          })}
        </Style.ButtonWrapper>
      ) : null}
    </Style.WraperBox>
  );
};

export default TabMenu;
