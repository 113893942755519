import { Box, Flex} from '@chakra-ui/react';
import styled from "@emotion/styled/macro";
import { device } from "../../Device.types";

export const PodcastOurClass = styled(Box)(props =>({
    padding: '20px 0px',

    [`@media ${device.tabletM}`]: {
        padding: '60px 0px'
    },
}))
export const PodcastOurClassSocial = styled(Box)(props =>({
    textAlign: 'center',
    justifyContent: 'center'
    // bottom: '0',
    // left: '0',
    // [`@media ${device.tablet}`]: {
    //     position: 'absolute',
    //     paddingLeft: '100px'
       
    // },
}))
export const PodcastOurClassSocialContent = styled(Flex)(props =>({
    justifyContent: 'center',
    gap: '10px' ,
    color: 'black',
    padding: '5px 0px' 
}))

export const PodcastImageInfor = styled(Flex)(props =>({
    padding: '40px 0px',
    [`@media ${device.tablet}`]: {
        padding: '80px 0px',
       
    },
}))