import React from 'react';
import { Box, Container, GridItem, SimpleGrid, Image, Center, UnorderedList, ListItem } from '@chakra-ui/react';
import Heading from '../component/Heading/Heading';
import Text from '../component/SubHeading/SubHeading';

const Story = () => {
    return (
        <>
            <Box
                backgroundImage={{ sm: 'url(./images/landingpage1.png)', md: 'url(./images/landingpage1.png)' }}
                backgroundColor='black'
                backgroundPosition="center"
                backgroundRepeat="no-repeat"
                height={{ sm: '40vh', md: '350px' }}
                backgroundSize="cover"
            >
                <Container maxW='container.2xl' backgroundColor='rgba(0,0,0,0.5)' p={{ sm: '0 20px', md: "0 70px", lg: "0 130px" }}  justifyContent="center" display='flex' height='100%' alignItems="end" >
                    <Box
                        paddingBottom="30px"
                        textTransform="uppercase"
                        alignItems="center"
                        display='flex'
                        flexDirection='column'
                        className='wow animate fadeInUp'
                    >
                        <Text as='strong' fontSize={{ base: 'xs', md: "sm" }} color='#fff' title='About Us ' fontFamily='BlenderProBold'></Text>
                        <Heading title='About Perpetua Fitness' color='#fff' lineHeight="1" padding="0px 0px 5px" variant="largheading" textAlign='center' />
                    </Box>
                </Container>
            </Box>

            <Box padding={{ sm: "50px 0 30px", md: "70px 0 25px", lg: "100px 0 50px" }}>
                <Container maxW='container.xl'>
                    <Center flexDirection='column'>
                        <Heading title='Our Mission' variant='extrasmall' />
                        <Heading title='The Best Fitness Experience in Ireland under one roof' variant='large' lineHeight='0.9' padding='15px 0' textAlign='center' width={{md:"70%"}} />
                        <Text title='Community and movement is at the heart of everything we do here at perpetua. we live for intelligent programming, functional fitness and having a good time together.' textAlign='center' width={{md:"70%"}} />
                    </Center>
                </Container>
            </Box>
            <Box padding={{ sm: "35px 0", md: "50px 0 25px", lg: "50px 0 50px" }}>
                <Container maxW='container.xl'>
                    <SimpleGrid
                        columns={{ sm: '1', md: '2' }}
                        columnGap={10}
                        rowGap={2}
                        alignItems='center'
                    >
                        <GridItem>
                            <Image src='./images/CorporateWellnessWeeksmall.webp' alt='Best Fitness Experience' />
                        </GridItem>
                        <GridItem paddingTop={{sm:"30px", md:"0"}}>
                            <Heading title='Our Commitments' variant='extrasmall' color='grey' />
                            <Box paddingTop='25px'>
                                <Heading title='We Promise A Experience.' variant='small' />
                                <Text title='We’ve built an environment where doing the right thing on a daily basis is rewarded. A place where we help each other as a group become better, stronger, more educated and more tolerant people by training with professional systems and support.' padding='5px 0 0' />
                            </Box>
                            <Box paddingTop='25px'>
                                <Heading title='We Include Everyone' variant='small' />
                                <Text title='We pride ourselves on the community we have built across the last 16 years. You are not just joining a gym. You become part of a community here at Perpetua. It is a place to connect and make new friends. You will find yourself with a new social and support network that will help you on your continued path to greater health and happiness.' padding='5px 0 0' />
                            </Box>
                            <Box paddingTop='25px'>
                                <Heading title='We Bring Passion' variant='small' />
                                <Text title='Our passion is to create a safe and effective training environment with a supportive community of like-minded individuals. Our coaches are full time professionals who have dedicated their lives to helping others through health and fitness.' padding='5px 0 0' />
                            </Box>
                        </GridItem>
                    </SimpleGrid>
                </Container>
            </Box>

            <Box padding={{ sm: "35px 0", md: "50px 0 25px", lg: "50px 0 25px" }}>
                <Container maxW='container.xl'>
                    <Center flexDirection='column'>
                        <Heading title='Our Values' variant='extrasmall' />
                        <Heading title='We are a Team' variant='medium' padding='5px 0 0' />
                        <Heading title='Our Community is Everything' variant='medium' textAlign='center' padding='5px 0 0' />
                        <Heading title='Be Sound' variant='medium' padding='5px 0 0' />
                        <Heading title='We Respect Everything' variant='medium' padding='5px 0 0' />
                    </Center>
                </Container>
            </Box>

            <Box padding={{ sm: "35px 0", md: "50px 0", lg: "75px 0" }}>
                <Container maxW='container.xl'>
                    <SimpleGrid
                        columns={{ sm: '1', md: '2' }}
                        columnGap={10}
                        rowGap={2}
                    >
                        <GridItem>
                            <Image src='./images/ThredAndshredFirsTimers7.webp' alt='Diversity' />
                        </GridItem>
                        <GridItem>
                            <Image src='./images/ThredAndshredFirsTimers1.webp' alt='Diversity' />
                        </GridItem>
                    </SimpleGrid>
                    <Center flexDirection='column' paddingTop={{ sm: "30px", md: "50px" }}>
                        <Heading title='Diversity, Equity & Inclusion' variant='medium' />
                        <Text title='At Perpetua Fitness, we work with our community to collaborate, elevate and create a place for everyone to belong' padding='10px 0 0' width={{ md: '50%' }} textAlign='center' />
                    </Center>
                </Container>
            </Box>

            <Box
                backgroundColor='#000'
                padding={{ sm: "60px 15px", md: "80px 30px", xl: "0" }}
                height='100%'
            >
                <SimpleGrid
                    columns={{ sm: '1', md: '2' }}
                    columnGap={10}
                    rowGap={2}
                    alignItems='center'
                    height='100%'
                >
                    <GridItem>
                        {/* <Box position='absolute'
                            left={{ sm: "0", md: '14%' }} height='100%' top='0' bottom='0' display='flex' alignItems='center' >
                            <Image src='./images/Storyhis.webp' alt='Perpetua Fitness Today' width='40%' margin={{ sm: "0 auto", md: "0" }}
                            />
                        </Box> */}
                        <Image src='./images/storyimg4.webp' alt='Perpetua Fitness Today' />
                    </GridItem>
                    <GridItem paddingTop={{ sm: "30px", md: "0", xl: "50px" }} paddingBottom={{ sm: "0", xl: "50px" }} paddingRight={{ sm: "0", md: "50px" }}>
                        <Box className='wow animate fadeInUp'>
                            <Heading title='Perpetua In History' color='#fff' lineHeight="1" padding="0px 0px 5px" variant="medium" />
                            <Text title='Perpetua Fitness was founded with a vision to create a fitness community that went beyond the traditional gym experience. The founders, driven by a passion for fitness and well-being, wanted to establish a space where people could not only train their bodies but also nourish their minds and spirits. The journey began in a small studio, where the focus was on delivering high-quality, personalized training sessions.' color='#c79d5b' padding='15px 0 0' fontSize={{ sm: "12px", md: "14px" }} />
                            <Text title="From the outset, Perpetua Fitness emphasized community. The founders believed that a supportive and inclusive environment was key to achieving fitness goals. They organized events, challenges, and group activities that fostered a sense of belonging among members. This approach quickly gained traction, and the studio's membership grew as people were drawn to the positive and motivating atmosphere." color='#fff' padding='15px 0 0' fontSize={{ sm: "12px", md: "14px" }} />
                            <Text title="As the community expanded, so did the offerings. Perpetua Fitness introduced a variety of classes, ranging from high-intensity interval training to boxing. The goal was to cater to diverse fitness preferences and ensure that everyone could find something they enjoyed. This diversification helped attract a broader audience and solidified Perpetua Fitness's reputation as a comprehensive fitness center." color='#fff' padding='15px 0 0' fontSize={{ sm: "12px", md: "14px" }} />
                            <Text title="Perpetua Fitness was always ahead of the curve in embracing new fitness trends and technologies. They incorporated the latest equipment and training methodologies to provide the best possible experience for their members. This commitment to innovation included the use of fitness tracking apps, virtual classes, and interactive workouts, which became particularly valuable during times when physical attendance was limited." color='#fff' padding='15px 0 0' fontSize={{ sm: "12px", md: "14px" }} />
                            <Text title="Like any business, Perpetua Fitness faced its share of challenges. Economic downturns, competition, and the COVID-19 pandemic tested the resilience of the community. However, the strong foundation of member support and the adaptability of the management team allowed Perpetua Fitness to navigate these obstacles. They shifted to online classes during lockdowns, offered flexible membership options, and ensured that their community stayed connected even when physically apart." color='#fff' padding='15px 0 0' fontSize={{ sm: "12px", md: "14px" }} />
                        </Box>
                    </GridItem>
                </SimpleGrid>
            </Box>

            <Box
                backgroundImage='url(./images/corporateWelkness2.webp)'
                backgroundColor='black'
                backgroundPosition="center"
                backgroundRepeat="no-repeat"
                height={{ sm: '100vh', md: '550px', lg: '600px', xxl: '767px' }}
                backgroundSize={"cover"}
            >
                <Container maxW='container.2xl' p={{ sm: '0 20px', md: "0 70px", lg: "0 130px" }} backgroundColor='rgba(0,0,0,0.6)' width='100%' height='100%' ustifyContent="center" alignItems='center' display='flex'>
                    <Box className='wow animate fadeInUp'>
                        <Heading title='Perpetua Fitness Today' color='#fff' lineHeight="1" padding="0px 0px 5px" variant="medium" />
                        <Text title="Today, Perpetua Fitness stands as a testament to the power of community-driven fitness. With multiple locations and a diverse range of programs, it continues to inspire and support individuals in their fitness journeys. The founders' commitment to creating a welcoming and dynamic environment remains at the core of everything they do." color='#c79d5b' padding='15px 0 0' width={{ sm: "90%", md: "80%", lg: '65%', xl: "50%" }} fontSize={{ sm: "12px", md: "14px" }} />
                        <Text title='Looking to the future, Perpetua Fitness aims to expand its reach even further. Plans are in place to open new branches, introduce more specialized training programs, and leverage advanced fitness technologies. The mission remains the same: to help people achieve their best selves through fitness, community, and innovation,' color='#fff' padding='15px 0 0' width={{ sm: "90%", md: "80%", lg: '65%', xl: "50%" }} fontSize={{ sm: "12px", md: "14px" }} />

                        <UnorderedList listStyleType="none" padding='15px 0 0'>
                            <ListItem color='#fff' fontSize={{ sm: "12px", md: "14px" }} fontFamily='Poppins' marginLeft='-17px' paddingTop='7px'>
                                <span style={{ fontWeight: '700' }}>Community: </span>  Building a supportive and inclusive environment for all members.
                            </ListItem>
                            <ListItem color='#fff' marginLeft='-17px' fontFamily='Poppins' fontSize={{ sm: "12px", md: "14px" }} paddingTop='7px'>
                                <span style={{ fontWeight: '700' }}>Innovation: </span> Continuously evolving and incorporating new fitness trends and technologies.
                            </ListItem>
                            <ListItem color='#fff' fontWeight='400' marginLeft='-17px' fontFamily='Poppins' fontSize={{ sm: "12px", md: "14px" }} paddingTop='7px'>
                                <span style={{ fontWeight: '700' }}>Personalization: </span>  Offering tailored programs to meet individual needs and goals.
                            </ListItem>
                            <ListItem color='#fff' marginLeft='-17px' fontFamily='Poppins' fontSize={{ sm: "12px", md: "14px" }} paddingTop='7px'>
                                <span style={{ fontWeight: '700' }}> Well-Being: </span> Focusing on holistic health, including mental and emotional wellness.
                            </ListItem>
                            <ListItem color='#fff' marginLeft='-17px' fontFamily='Poppins' fontSize={{ sm: "12px", md: "14px" }} paddingTop='7px'>
                                <span style={{ fontWeight: '700' }}>Resilience: </span>  Overcoming challenges and adapting to changing circumstances.
                            </ListItem>
                        </UnorderedList>
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default Story;