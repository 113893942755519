import React, {lazy, Suspense, useState} from 'react';
import {
    FormControl,
    FormLabel,
    Stack,
    FormErrorMessage, Select,
    Input,
    Textarea,
    Box,
    Flex
} from '@chakra-ui/react'
import Button from '../Button/CustomeButton';
import axios from 'axios';
import Text from '../SubHeading/SubHeading';
const ReCAPTCHA = lazy(()=>import('react-google-recaptcha')) ;



const ContactusForm = () => {       
    
    const [successFrom, setsuccessFrom] = useState('');
    const [btnDisable, setBtnDisable] = useState(false);
    
    const [nameError, setnameError] = useState('');
    const [emailError, setemailError] = useState('');
    const [optionError, setoptionError] = useState('');
    const [messageError, setmessageError] = useState('');

    const [isCaptcha, setIsCaptcha] = useState(null);
    const [errorCaptcha, setErrorCaptcha] = useState('');

    const getReCaptchaVal = (value) => {
        setIsCaptcha(value);
        setErrorCaptcha('');
    }

    const [contactForm, setContactForm] = useState({
        name: "",
        email: "",
        option: "Tread & Shred",
        youHearAboutUs:"",
        message: "",

    });
    const handelInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setContactForm({ ...contactForm, [name]: value })
    }
    const handelSubmit = (e) => {
        e.preventDefault();
        setsuccessFrom('');
        setBtnDisable(true);

        if (isCaptcha === null) {
            setBtnDisable(false);
            setsuccessFrom('');
            setErrorCaptcha('Please verify that you are not a robot.');
            return false;
        }

        axios.post(process.env.REACT_APP_ADMIN_API_BASE_URL + '/contact-form', {
            name: contactForm.name,
            email: contactForm.email,
            subject: contactForm.option,
            youHearAboutUs: contactForm.youHearAboutUs,
            message: contactForm.message
        })
            .then((response) => {
                setsuccessFrom(response.data.success);
                setBtnDisable(false);
                setIsCaptcha(null);
                contactForm.name = "";
                contactForm.email = "";
                contactForm.message = "";

                window.grecaptcha.reset();
            })
            .catch((error) => {
                setsuccessFrom('');
                setBtnDisable(false);
                window.grecaptcha.reset();
                console.log(error.response.data.error.message[0]);

                if (error.response.data.error.name[0]) {
                    setnameError(error.response.data.error.name[0]);
                } else {
                    setnameError('');
                }
                if (error.response.data.error.email[0]) {
                    setemailError(error.response.data.error.email[0]);
                } else {
                    setemailError('');
                }
                if (error.response.data.error.subject[0]) {
                    setoptionError(error.response.data.error.subject[0]);
                } else {
                    setoptionError('');
                }
                if (error.response.data.error.message[0]) {
                    setmessageError(error.response.data.error.message[0]);
                } else {
                    setmessageError('');
                }

                console.log(nameError);

            });




    }




    return (
        <>
            <form action="" onSubmit={handelSubmit} className="cpContact">
                <FormControl isRequired >

                    <Stack direction={['column']} spacing='24px' position='relative' marginTop='25px'>
                        <FormLabel
                            fontSize='18px'
                            textTransform='uppercase'
                            lineHeight='1'
                            width='fit-content'
                            position='relative'
                            marginBottom='-11px'
                            background='white'
                            zIndex='11'
                            left='12px'
                            padding='0px 5px'

                        >Name</FormLabel>
                        <Input _placeholder={{ color: 'Grey', fontFamily: 'BlenderProBold , sans-serif', fontSize: '18px', textTransform: 'uppercase' }} border='1px solid #000' height='50px' borderRadius='0px' color='black' fontSize='18px' margin='0px 0px 0px 0px' name="name" value={contactForm.name} onChange={handelInput} />
                        {/* <FormErrorMessage>{nameError}</FormErrorMessage> */}
                        {/* <Text title={nameError} fontSize="14px" color="red"/> */}
                    </Stack>


                    <Stack direction={['column']} spacing='24px' position='relative' marginTop='25px'>
                        <FormLabel
                            fontSize='18px'
                            textTransform='uppercase'
                            lineHeight='1'
                            width='fit-content'
                            position='relative'
                            marginBottom='-11px'
                            background='white'
                            zIndex='11'
                            left='12px'
                            padding='0px 5px'

                        >Email</FormLabel>
                        <Input type='email' _placeholder={{ color: 'Grey', fontFamily: 'BlenderProBold , sans-serif', fontSize: '18px', textTransform: 'uppercase' }} border='1px solid #000' height='50px' borderRadius='0px' color='black' fontSize='18px' margin='0px 0px 0px 0px' name="email" value={contactForm.email} onChange={handelInput} />
                        {/* {emailError && <FormErrorMessage>{emailError}</FormErrorMessage>} */}
                    </Stack>

                    <Stack direction={['column']} spacing='24px' position='relative' marginTop='25px'>
                        <Select placeholder='Select option' borderRadius='0' height='50px' color='black' fontSize='18px' textTransform='uppercase' name="option" value={contactForm.option} onChange={handelInput} >
                            <option value='Tread & Shred' padding='8px 0px'>Tread & Shred</option>
                            <option value='Ride'>Ride</option>
                            <option value='Rumble'>Rumble</option>
                            <option value='CrossFit'>CrossFit</option>
                            <option value='Personal Training'>Personal Training</option>
                            <option value='JustGYM'>JustGYM</option>
                            <option value='Online Program'>Online Program</option>
                            <option value='Perpetua Training'>Perpetua Training</option>
                            <option value='Pre/Post Natal Training'>Pre/Post Natal Training</option>
                            <option value='Nutrition'>Nutrition</option>
                            <option value='Ladies Group'>Ladies Group</option>
                            <option value='HYROX'>HYROX </option>
                            <option value='Careers'>Careers</option>
                            <option value='Corporate'>Corporate</option>
                            <option value='Private Hire'>Private Hire</option>
                            <option value='Mojo'>Mojo</option>
                            <option value='InBody Analysis'>InBody Analysis</option>
                        </Select>
                        {/* {optionError && <FormErrorMessage>{optionError}</FormErrorMessage>} */}
                    </Stack>
                    <Stack direction={['column']} spacing='24px' position='relative' marginTop='25px'>
                        <Select placeholder='How did you hear about us?' borderRadius='0' height='50px' color='black' fontSize='18px' textTransform='uppercase' name="youHearAboutUs" value={contactForm.youHearAboutUs} onChange={handelInput} >
                            <option value='Search Engine' padding='8px 0px'>Search Engine</option>
                            <option value='Social Media'>Social Media</option>
                            <option value='Advertisement'>Advertisement</option>
                            <option value='Recommended by a friend or colleague'>Recommended by a friend or colleague</option>
                            <option value='Other'>Other</option>
                        </Select>
                    </Stack>

                    <Stack direction={['column']} spacing='24px' position='relative' marginTop='25px'>
                        <FormLabel
                            fontSize='18px'
                            textTransform='uppercase'
                            lineHeight='1'
                            width='fit-content'
                            position='relative'
                            marginBottom='-11px'
                            background='white'
                            zIndex='11'
                            left='12px'
                            padding='0px 5px'

                        >Message</FormLabel>
                        <Textarea
                            _placeholder={{ color: 'Grey', fontFamily: 'BlenderProBold , sans-serif', fontSize: '18px', textTransform: 'uppercase' }}
                            height='120px' borderRadius='0px' border='1px solid #000' color='black' fontSize='18px' margin='0px 0px 0px 0px' name="message" value={contactForm.message} onChange={handelInput}
                        ></Textarea>
                        {messageError && <FormErrorMessage>{messageError}</FormErrorMessage>}
                    </Stack>
                    <Stack direction={{ sm: 'column', md: 'column', lg: 'row' }} spacing={{ md: '25px', lg: '30px', xl: '50px' }} position='relative' marginTop='25px'>
                        <Suspense fallback={"Loading.."}>
                            <ReCAPTCHA sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITEKEY} onChange={getReCaptchaVal} className='captcha' />
                        </Suspense>
                        <Button btnDisable={btnDisable}  submit="submit" title='Contact' width='208px' margin='30px 0px 0px' color='black'></Button>
                    </Stack>
                    {errorCaptcha &&  <Text title={errorCaptcha} fontSize="14px" color="red"/>}
                    <Flex justifyContent="center" margin="30px 0 0"> {successFrom && <Text title={successFrom} fontSize="16px" color="green" />}</Flex>
                    
                </FormControl>
            </form>

        </>
    );
}

export default ContactusForm;
