import React from 'react';
import { Box, Text,Link } from '@chakra-ui/react';
import Heading from '../Heading/Heading';
import * as Style from './BlogCard.style';
import ImagePalce from '../Image/Image';


function BlogCard(props) {
  return (
    <Style.BlogCardWrapper>
      <Style.Blogtag href={props.taglink} >
          {props.tag}
      </Style.Blogtag>
      <Style.BlogListingInfo>
        {/* <Style.BlogListingImage 
          height={props.height}
          backgroundImage={props.uri}
          href={props.link} 
        >
          
        </Style.BlogListingImage> */}
          <Link href={props.link} maxH='298px' minH='298px' overflow='hidden'>
            <ImagePalce alt={props.alt} src={props.uri} />
          </Link>
          <Style.BlogListingContent > 
              
            <Heading title={props.date} lineHeight='1' variant='smallheading' cfSize={{sm:'xs' , md:'xs'}}></Heading>
            <Link href={props.link} >
              <Heading title={props.title} lineHeight='1' variant='extrasmall' cfSize={{sm:'xs' , md:'sm2'}}></Heading>
            </Link>
            <Link textAlign='right' marginRight='15px' href={props.link} >
              <Text  textDecoration='underline' textTransform='uppercase' padding="0px">
                Read More
              </Text>
            </Link>
          </Style.BlogListingContent>
        {/* </Box> */}
      
      </Style.BlogListingInfo>
    </Style.BlogCardWrapper>
    
  );
}
export default BlogCard;
