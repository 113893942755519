import React from 'react';
import MetaTitle from './MetaTitle';
import InnerBanner from '../component/InnerBanner/InnerBanner';
import { Container, GridItem, SimpleGrid, Box, Flex, HStack, Stack } from '@chakra-ui/react';
import Heading from '../component/Heading/Heading';
import Text from '../component/SubHeading/SubHeading';
import { MdOutlineStarPurple500, MdOutlineStarHalf } from "react-icons/md";


const Testimonials = () => {

    const metaTitle = "Testimonials | Perpetua Fitness";

    return (
        <>
            <MetaTitle title={metaTitle} />
            <InnerBanner
                backgroundColor='black'
                backgroundImage={{ sm: 'url(./images/first_timer_n.webp)', md: 'url(./images/first_timer_n.webp)' }}
                title='What our members say about us?'
                isButton3={true}
                buttontitle2='windmill lane'
                Link='https://g.page/r/CSHsWPbqtZdJEBM/review' 
                buttontitle4='lennox street'
                Link4='https://g.page/r/CbaMTKIX3kBCEBM/review'
                issubtextpara={true}
                subtextpara='Leave & Read Our Google Reviews'
                isSubtext={false}
                isButton={false}
                isButton2={false}
                issubtitleRight={false}
                width={{ sm: '100%', md: '100%' }}
                height={{ sm: '450px', md: '500px', lg: '550px', xl: '767px' }}
            />

            <Container maxW='container.xl' padding={{ sm: '60px 30px 0px', md: '80px 30px 0px' }}>
                <Stack direction={{ base: 'column', md: 'row' }} spacing='30px' textAlign={{ base: 'left', lg: 'left' }} alignItems='top' paddingBottom={{ sm: '20px', md: '70px' }}>
                    <Box w={{ md: '30%' }} position={{ base: 'relative', md: 'sticky' }} top={{ base: '0', md: '100px' }} height={{ base: 'auto', md: '150px' }} textAlign={{ sm: 'center', md: 'left' }}>
                        <Heading title='Google Reviews' lineHeight='1'></Heading>
                    </Box>
                    <Box width={{ md: '70%' }}>

                        <SimpleGrid
                            columns={{ sm: '1', md: "2" }}
                            spacing={8}
                        >
                            <GridItem>
                                <HStack paddingBottom='10px'>
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                </HStack>
                                <Text
                                    title="Perpetua has become my go-to fitness spot in Dublin. I'm a huge fan of their HYROX classes – they push me to my limits! The Tread & Shred classes are also fantastic. The coaches are super supportive and clearly know their stuff. Plus, the facilities are always spotless and the protein shakes are a delicious way to refuel. "
                                />
                            </GridItem>
                            <GridItem>
                                <HStack paddingBottom='10px'>
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarHalf fontSize='30px' color='#FF8000' />
                                </HStack>
                                <Text
                                    title="From the second you walk through the door at Perpetua you feel welcome and part of a community. The facilities are excellent and the attention to detail is evident in every area of the gym. The coaches are next level! All of the classes - CrossFit and Studio are challenging and fun and you are pushed to your limit. Can't recommend highly enough. "
                                />
                            </GridItem>
                            <GridItem>
                                <HStack paddingBottom='10px'>
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                </HStack>
                                <Text
                                    title="The best CrossFit gym I’ve ever been part of- fun programming, gorgeous facilities, social events outside of class, and awesome coaches! They keep adding new CrossFit class times, and have even added Olympic lifting and gymnastics!  "
                                />
                            </GridItem>
                            <GridItem>
                                <HStack paddingBottom='10px'>
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                </HStack>
                                <Text
                                    title="Tread and Shred is my absolute most favorite workout! It's intense in the best way, and I always leave feeling incredible and accomplished. Love the vibe, the front desk, the coaches… Would definitely recommend it!"
                                />
                            </GridItem>
                            <GridItem>
                                <HStack paddingBottom='10px'>
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarHalf fontSize='30px' color='#FF8000' />
                                </HStack>
                                <Text
                                    title="Loved dropping in to this CF gym while I was in Dublin for a work trip. The coaching was excellent for all levels, the members and coaches were super welcoming and friendly - making me feel so included! I came three times because of this! The workouts were fun and challenging, too. They have a coffee/MOJO Health Bar which was so cool (wish I had one of those in my home gym!). The overall layout and space was really well utilized too."
                                />
                            </GridItem>
                            <GridItem>
                                <HStack paddingBottom='10px'>
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarHalf fontSize='30px' color='#FF8000' />
                                </HStack>
                                <Text
                                    title="This gym is top notch. I was in Dublin for a week on a business trip and dropped in every day I could. Simply put, the coaches are fantastic. Everyone was very welcoming and embodied the CrossFit community that I am a huge fan of. I highly recommend this gym to anyone in the area! They will help you get where you want to be regardless of your fitness level, and you will have a ton of fun doing it. "
                                />
                            </GridItem>
                            <GridItem>
                                <HStack paddingBottom='10px'>
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                </HStack>
                                <Text
                                    title="Superb coaches, great variety of classes and workouts, great fun, rocking tunes, lovely class mates and great results!. What more could you ask for? Well except for  great food and shakes and coffee for after....oh wait....they have that too!"
                                />
                            </GridItem>
                        </SimpleGrid>
                    </Box>
                </Stack>
            </Container>

            <Box backgroundColor='#000'
                padding='35px 0' marginBottom='50px'>

            </Box>

            <Container maxW='container.xl' padding={{ sm: '60px 30px 0px', md: '80px 30px 0px' }}>
                <Stack direction={{ base: 'column', md: 'row' }} spacing='30px' textAlign={{ base: 'left', lg: 'left' }} alignItems='top' paddingBottom={{ sm: '20px', md: '70px' }}>
                    <Box w={{ md: '30%' }} position={{ base: 'relative', md: 'sticky' }} top={{ base: '0', md: '100px' }} height={{ base: 'auto', md: '150px' }} textAlign={{ sm: 'center', md: 'left' }}>
                        <Heading title='Perpetua Fitness App' lineHeight='1'></Heading>
                    </Box>
                    <Box width={{ md: '70%' }}>

                        <SimpleGrid
                            columns={{ sm: '1', md: "2" }}
                            spacing={8}
                        >
                            <GridItem>
                                <HStack paddingBottom='10px'>
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                </HStack>
                                <Text
                                    title="It’s the best class I’ve ever done!"
                                />
                            </GridItem>
                            <GridItem>
                                <HStack paddingBottom='10px'>
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarHalf fontSize='30px' color='#FF8000' />
                                </HStack>
                                <Text
                                    title="Amazing coach and very mindfully led class - thoroughly enjoyed it"
                                />
                            </GridItem>
                            <GridItem>
                                <HStack paddingBottom='10px'>
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                </HStack>
                                <Text
                                    title="Great experience however lots of the lockers didn't work hence I had to leave my stuff in an open locker."
                                />
                            </GridItem>
                            <GridItem>
                                <HStack paddingBottom='10px'>
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                     <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                </HStack>
                                <Text
                                    title="Really loved the intensity of the class and the variety of the exercises. Left me exhausted afterwards which is what I look for in a class."
                                />
                            </GridItem>
                             <GridItem>
                                <HStack paddingBottom='10px'>
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarHalf fontSize='30px' color='#FF8000' />
                                </HStack>
                                <Text
                                    title="Denise was super motivating, and created a really safe non judgemental space for all. I was really nervous going for my first class as I’m overweight and unfit and haven’t worked out in ages but I was so glad I went, can’t wait for next class! "
                                />
                            </GridItem>
                           
                             <GridItem>
                                <HStack paddingBottom='10px'>
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarHalf fontSize='30px' color='#FF8000' />
                                </HStack>
                                <Text
                                    title="I attended my first ever class in Perpetua on Thursday with Fin who was an absolute amazing coach he really knew how to work the room and keep the group motivated. I’ll definitely be back"
                                />
                            </GridItem>
                            <GridItem>
                                <HStack paddingBottom='10px'>
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarHalf fontSize='30px' color='#FF8000' />
                                </HStack>
                                <Text
                                    title="Perpetua always provides quality. The coaches, the programme, the facilities, the community and everything else in between. The coaches and particular the head coach go above and beyond. Love the activities/workshops/events planned outside of class and the members only discounts. Highly recommended for CrossFit! "
                                />
                            </GridItem>
                           
                            <GridItem>
                                <HStack paddingBottom='10px'>
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                   <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                </HStack>
                                <Text
                                    title="Absolutely adore classes with Denise & David. They are really motivating instructors who take a keen interest in everyone’s journey. They make me want to show up and push harder for each class. I definitely cannot recommend their classes enough. Denise is definitely the queen of Rhythm ride and equally David is such a fantastic boxing coach & tread and shred coach. What a team! "
                                />
                            </GridItem>
                            <GridItem>
                                <HStack paddingBottom='10px'>
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                    <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                     <MdOutlineStarPurple500 fontSize='30px' color='#FF8000' />
                                </HStack>
                                <Text
                                    title="I've been training at Perpetua for a while now, and I like the community and the vibe. Big shoutout to Craig for organizing more community events and workshops and bringing everyone together. If there is one thing I'd suggest for improvement, it would be to teach some gym etiquette potentially during the academy. I'm noticing people not putting the equipment back after the class or not paying attention to where they are during the workout and ending up using someone else's weights/spot, which can be a bit annoying sometimes :) Overall, I am excited about what's next for the CrossFit classes."
                                />
                            </GridItem>
                        </SimpleGrid>
                    </Box>
                </Stack>
            </Container>
        </>
    )
}

export default Testimonials;