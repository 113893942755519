import React from 'react';
import { Container, Box, SimpleGrid, Image, GridItem, Flex, Link } from '@chakra-ui/react';
import ClassCard from '../component/Classcard/ClassCard';
import MetaTitle from './MetaTitle';
import InnerBanner from '../component/InnerBanner/InnerBanner';
import Heading from '../component/Heading/Heading';
import Text from '../component/SubHeading/SubHeading';
import { NavLink } from 'react-router-dom';
import Button from '../component/Button/CustomeButton';

function FirstTimers() {

  const metaTitle = "First timers | Perpetua Fitness";

  return (
    <Box backgroundColor='#000'>
      <MetaTitle title={metaTitle} />
      <InnerBanner
        backgroundColor='black'
        backgroundImage={{ sm: 'url(./images/first_timer_n.webp)', md: 'url(./images/first_timer_n.webp)' }}
        subtitle='EXCLUSIVE FIRST TIMER OFFER'
        title='NEW TO PERPETUA'
        subtextpara='Get started with our 3 for 3 class pack'
        buttontitle2="Book A Class"
        issubtextpara={true}
        isButton={false}
        isButton2={true}
        issubtitleRight={false}
        Link2='/timetable'
        margin='20px 0 0'
        width={{ sm: '100%', md: '100%' }}
        height={{ sm: '450px', md: '500px', lg: '550px', xl: '767px' }}
      />
      <Box padding='50px 0' backgroundColor='#fff'>
        <Container maxW='container.xl'>

          <Heading
            title="First Timer’s Process"
            variant="large"
            textAlign='center'
            padding='0 0 20px'
            color='#000'
          />
          <SimpleGrid
            columns={{ sm: "1", md: "3" }}
            gap={5}
          >
            <GridItem>
              <Image src='./images/firstTimersPurchase1.png' alt='firstTimersPurchase' width='100px' margin='0 auto' />
              <Heading
                title="Buy our Intro Credit Pack"
                variant="smallmid"
                textAlign='center'
                padding='20px 0'
                color='#000'
              />
            </GridItem>
            <GridItem>
              <Image src='./images/firstTimersBook1.png' alt='firstTimersBook' width='100px' margin='0 auto' />
              <Heading
                title=" Book a class and visit our first timers page"
                variant="smallmid"
                textAlign='center'
                padding='20px 0'
                color='#000'
              />
            </GridItem>
            <GridItem>
              <Image src='./images/firstTimersIntro1.png' alt='firstTimersIntro' width='100px' margin='0 auto' />
              <Heading
                title="Receive a introduction from our team when you arrive"
                variant="smallmid"
                textAlign='center'
                padding='20px 0'
                color='#000'
              />
            </GridItem>
          </SimpleGrid>
        </Container>
      </Box>

      <Heading
        title="Read Our First Timers Guide"
        variant="large"
        textAlign='center'
        padding='50px 0 0'
        color='#fff'
      />

      <Container maxW='container.xl' padding={{ sm: '50px 20px 30px', md: "60px 15px" }}>
        <Flex
          width='100%'
          flexDirection='row'
          flexWrap='wrap'
          alignItems='flex-start'
          justifyContent='center'
          gap='30px'
        >
          <Box width={{ lg: '31%', md: '46%', sm: '100%' }} className="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.7s">
            <ClassCard
              title=''
              subtitle=''
              bg="url('/images/TreadAndShread/WarmUp.webp')"
              isButton={false}
              marginBottom='30px'
              transform="uppercase"
              minHeight={{ sm: '300px', md: '450px', lg: '500px' }}
              linkBg='/tread-and-shred-first-timers'
            />
            <Heading title="Tread & Shred" variant='medium' padding='20px 0 5px' color='#fff' />
            <Text title='Our signature group fitness experience' padding={{ sm: '0 0 10px', md: "0" }} color='#fff' />
          </Box>
          <Box width={{ lg: '31%', md: '46%', sm: '100%' }} className="wow fadeInUp" marginTop={{ md: "80px", lg: "80px" }} data-wow-duration="1s" data-wow-delay="0.9s">
            <ClassCard
              title=''
              subtitle=''
              bg="url('/images/Rumble/Cooldown.webp')"
              isButton={false}
              marginBottom='30px'
              transform="uppercase"
              minHeight={{ sm: '300px', md: '450px', lg: '500px' }}
              linkBg='/rumble-first-timers'
            />
            <Heading title="Rumble Boxing " variant='medium' padding='20px 0 5px' color='#fff' />
            <Text title=' Irelands newest boxing experience which is unmatched' padding={{ sm: '0 0 10px', md: "0" }} color='#fff' />
          </Box>
          <Box width={{ lg: '31%', md: '46%', sm: '100%' }} className="wow fadeInUp" marginTop={{ lg: "160px" }} data-wow-duration="1s" data-wow-delay="0.9s">
            <ClassCard
              title=''
              subtitle=''
              bg="url('./images/RhythmRide/Playlist.webp')"
              isButton={false}
              marginBottom='30px'
              transform="uppercase"
              minHeight={{ sm: '300px', md: '450px', lg: '500px', }}
              linkBg='/ride-first-timers'
            />
            <Heading title="Rhythm Ride" variant='medium' padding='20px 0 5px' color='#fff' />
            <Text title='More than a spin class. An experience ' padding={{ sm: '0 0 10px', md: "0" }} color='#fff' />
          </Box>
          <Box width={{ lg: '31%', md: '46%', sm: '100%' }} className="wow fadeInUp" marginTop={{ md: "80px", lg: "0" }} data-wow-duration="1s" data-wow-delay="0.7s">
            <ClassCard
              title=''
              subtitle=''
              bg="url('./images/Ride45/Music.webp')"
              isButton={false}
              marginBottom='30px'
              transform="uppercase"
              minHeight={{ sm: '300px', md: '450px', lg: '500px' }}
              linkBg='/ride45-first-timers'
            />
            <Heading title="Ride 45" variant='medium' padding='20px 0 5px' color='#fff' />
            <Text title='Dublin best spin class' padding={{ sm: '0 0 10px', md: "0" }} color='#fff' />
          </Box>
          <Box width={{ lg: '31%', md: '46%', sm: '100%' }} className="wow fadeInUp" marginTop={{ lg: "80px" }} data-wow-duration="1s" data-wow-delay="0.9s" >
            <ClassCard
              title=''
              subtitle=''
              bg="url('./images/Hyrox/Strength.jpg')"
              isButton={false}
              marginBottom='30px'
              transform="uppercase"
              minHeight={{ sm: '300px', md: '450px', lg: '500px' }}
              linkBg='/hyrox-first-timers'
            />
            <Heading title="Hyrox " variant='medium' padding='20px 0 5px' color='#fff' />
            <Text title='Classes preparing you for a HYROX event' padding={{ sm: '0 0 10px', md: "0" }} color='#fff' />
          </Box>
          <Box width={{ lg: '31%', md: '46%', sm: '100%' }} className="wow fadeInUp" marginTop={{ md: "80px", lg: "160px" }} data-wow-duration="1s" data-wow-delay="0.9s">
            <ClassCard
              title=''
              subtitle=''
              bg="url('./images/TreadAndShread/Treadmill.webp')"
              isButton={false}
              marginBottom='30px'
              transform="uppercase"
              minHeight={{ sm: '300px', md: '450px', lg: '500px', }}
              linkBg='/crossfit-dublin/beginners'
            />
            <Heading title="Crossfit beginners" variant='medium' padding='20px 0 5px' color='#fff' />
            <Text title='CrossFit Academy Course' padding={{ sm: '0 0 10px', md: "0" }} color='#fff' />
          </Box>
          <Box width={{ lg: '31%', md: '46%', sm: '100%' }} className="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.7s">
            <ClassCard
              title=''
              subtitle=''
              bg="url('/images/TreadAndShread/Floorwork.webp')"
              isButton={false}
              marginBottom='30px'
              transform="uppercase"
              minHeight={{ sm: '300px', md: '450px', lg: '500px' }}
              linkBg='/crossfit-dublin/drop-in'
            />
            <Heading title="CrossFit Drop In" variant='medium' padding='20px 0 5px' color='#fff' />
            <Text title='Drop-In + Visitors' padding={{ sm: '0 0 10px', md: "0" }} color='#fff' />
          </Box>
        </Flex>
      </Container>
      <Flex
        flexDirection='column'
        padding={{ sm: '0px 0px 50px', md: '90px 0px 100px' }}
        textAlign='center'
        justifyContent='center'
        justifyItems='center'
      >
        <Container maxW='container.xl'>
          <Box>
            <Heading
              as="h2"
              title="ARE YOU READY?"
              variant='extralarge'
              textAlign="center"
              lineHeight="1"
              color='#fff'
              padding={{ base: '0px 0px 15px', sm: '0px 0px 30px' }}
            />
            <Text
              title="Try 3 classes for €33. All fitness levels welcome "
              as='p'
              color='#fff'
            />
            <NavLink to='/contact-us' _hover={{ textDecoration: 'none' }}><Button title='Get Started' className="wow fadeInUp" color='#fff' margin='40px 0px 0px' /> </NavLink>
            <Box >
              <Heading
                as="h5"
                title="Download our app "
                variant='extrasmall'
                textAlign="center"
                lineHeight="1"
                padding={{ sm: '15px 0px 0px', md: '30px 0px 0px' }}
                display='inline-block'
                color='#fff'
              />

              <Link href="https://play.google.com/store/apps/details?id=com.fitnessmobileapps.perpetuafitness" target='_blank' >
                <Heading
                  as="h5"
                  title="Here"
                  variant='extrasmall'
                  textAlign="center"
                  lineHeight="1"
                  padding={{ sm: '15px 0px 0px', md: '30px 0px 0px' }}
                  display='inline-block'
                  margin='0px 0px 0px 5px'
                  color='Red'
                  textDecoration='underline'
                />
              </Link>
            </Box>
          </Box>
        </Container>
      </Flex>
    </Box>
  )
}

export default FirstTimers;
