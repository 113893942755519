import { Box, Flex, Link,Text } from '@chakra-ui/react'
import styled from "@emotion/styled/macro";


export const BlogCardWrapper = styled(Box)(props =>({
    width:'100%',
    maxWidth:'300px',
    position:'relative',
    "@media screen and (max-width:767px)": {
        width:'100%',
        maxWidth:'100%',
    },
})) 

export const Blogtag = styled(Link)(props =>({
    padding:'5px 10px',
    borderRadius:'50px',
    background:'#ecf1f6d1',
    color:'#000',
    fontSize:'14px',
    fontFamily:' Poppins',
    display:'inline-block',
    fontWeight:500,
    position:'absolute',
    top: '8px',
    left: '8px',
}))

export const BlogListingInfo = styled(Flex)(props =>({
    flexDirection: 'column',
    border: '1px solid',
    borderColor:'LightGrey',
    width:'100%'
}))

export const BlogListingImage = styled(Link)(props =>({
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width:'100%'
}))

export const BlogListingContent = styled(Flex)(props =>({
    flexDirection: 'column',
    gridGap: '10px' ,
    padding:'20px 10px',
    backgroundColor:'white'
}))

export const ViewAll = styled(Link)(props =>({
    padding:'8px 20px',
    borderRadius:'30px',
    background:'#D12C41',
    color:'#fff!important',
    fontSize:'14px',
    fontFamily:' Poppins',
    fontWeight:500,
    height:45,
    display:'inline-flex',
    alignItems:'center',
    justifyContent:'center',

}))