import { Box, Flex, Link } from "@chakra-ui/react";
import styled from "@emotion/styled/macro";
// import { device } from "../../Device.types";


export const Banner = styled(Box)(props => ({
    padding: 0,
    margin: 0,
    position: 'relative',
    backgroundPosition: 'center',
    backgroundRepeat: 'repeat',
    backgroundSize: 'cover',
}))
export const BannerWrapper = styled(Flex)(props => ({
    padding: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    height: '650px',
    alignItems: 'center',
    justifyContent: 'center',
    "@media screen and (max-width:1366px)": {
        height: '550px'
    },
    "@media screen and (max-width:991px)": {
        height: '450px'
    },
    "@media screen and (max-width:767px)": {
        height: '700px'
    },
}))

export const BannerCaption = styled(Flex)(props => ({
    padding: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    alignItems: 'center',
}))

export const ButtonGrup = styled(Flex)(props => ({
    padding: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'row',
    gap: '15px',
    alignItems: 'center',
    justifyContent: 'center',
}))

export const BannerButton = styled(Link)(props => ({
    padding: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    alignItems: 'center',
    width: '150px',
    padding: '10px 5px',
    textTransform: 'uppercase',
    color: '#fff',
    border: '2px solid #d12c41',
    borderRadius: 100,
    "&:hover": {
        background: '#d12c41',
        color: '#fff'
    }
}))

export const BannerButtonbg = styled(Link)(props => ({
    padding: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    alignItems: 'center',
    width: '150px',
    padding: '10px 5px',
    textTransform: 'uppercase',
    color: '#fff',
    background: '#d12c41',
    borderRadius: 100,
    "&:hover": {
        background: '#fff',
        color: '#d12c41'
    }
}))