import React from 'react';
import InnerBanner from '../component/InnerBanner/InnerBanner';
import { Box,  Container, Flex, Text, Heading, Stack, Button, Link} from '@chakra-ui/react';
import ZigzagCard from '../component/Mojo/ZigzagCard';
import MetaTitle from './MetaTitle';
function Mojo(){
    return(
        <>
            <MetaTitle title='Mojo | Perpetua Fitness'/>
            <InnerBanner 
            backgroundColor='black' 
            backgroundImage={{sm:"url(./images/mojo_m.jpg)", md:'url(./images/Mojo-banner.jpg)' }}
            subtitle='NEW TO PERPETUA'
            title='RIDE 45 AND RYTHM RIDE'
            isHeading={false}
            isSubtext={false}
            issubtextpara={false} 
            isButton={false} 
            issubtitleRight={false} 
            width={{sm:"100%"}}
            height={{ sm:'300px', md:'450px', lg:'500px', xl:'600px'}}
            /> 
          
            <Box padding={{sm:'35px 0px 30px', md:'60px 0px' }}   background={"#000000"} >
                <Container maxW='container.xl' >
                    <Flex  margin="auto" textAlign={'center'} width={{sm:'100%', md:'70%' }} flexDirection="column">
                        <Heading lineHeight={"1"} fontSize='40px' color='white' textTransform='uppercase' marginBottom='60px'>Clean, mourishing fuel for your body and mind</Heading>
                        <Text 
                            color='grey'
                            padding={{sm:'0px 30px',md:"0px 50px"}}
                            fontSize='16px'
                            fontFamily='poppins'
                        >Mojo Health Bar at Perpetua specialises in delicious, nutrition-forward meals, shakes, and drinks designed to help you refuel and recover after your workout.</Text> 
                    </Flex>
                </Container>
            </Box> 
            <Box height={{sm:'100%',lg:'300px'}} padding={{sm:'40px 0px'}} background={"#0a0d0e"} position='relative'>
                <Container maxW='container.xl' >
                    <Flex  margin="auto"  textAlign={'center'} flexDirection="column"  bottom='0'>
                        <Box  position='relative' zIndex='1' textAlign='center' alignItems='center' justifyContent='center' >
                            <Stack margin={{lg:'65px 0px 0px 20px'}} direction={{sm:"column",md:'row'}} spacing={6}  alignItems='center' justifyContent='center'>
                                <Link href={`https://deliveroo.ie/menu/dublin/st-stephens-green/mojo-dcs`} isExternal  width={{sm:'90%',md:'280px'}}>
                                    <Button backgroundColor='white' color='black' width={{sm:'100%',md:'280px'}} height={{sm:'50px',md:'90px'}} fontSize='18px'  _hover={{bg:'rgba(0,0,0, 0.4)',color:'white', border:'2px solid white' }}>
                                      ORDER ON DELIVEROO!
                                    </Button>
                                </Link> 
                                <Link href='/images/healthbar-menu.pdf' isExternal  width={{sm:'90%',md:'280px'}}>
                                    <Button width={{sm:'100%',md:'280px'}} height={{sm:'50px',md:'90px'}}  backgroundColor='grey' color='white' fontSize='18px'  _hover={{bg:'rgba(0,0,0, 0.4)', border:'2px solid white' }}>
                                        VIEW MENU
                                    </Button>
                                </Link>
                            </Stack>
                        </Box>

                    </Flex>
                </Container>
            </Box>
         
            <ZigzagCard />
             {/* 
             <SimpleGrid columns={{sm:'1',md:'2'}} padding="0px 30px" spacing={6}>
                    <Box  width='100%' height='50px'  border='2px solid grey' color='grey'
                        display='flex' justifyContent='center' alignItems='center' _hover={{bg:'grey', color:'white'}} borderRadius='5px'>
                        <Link> BUY STUDIO CREDITS</Link> 
                    </Box>
                    
                    <Box  width='100%'  height='50px' border='2px solid grey' display='flex' color='grey'
                        justifyContent='center' alignItems='center' _hover={{bg:'grey', color:'white'}} borderRadius='5px'>
                        <Link> MEMBERSHIPS</Link>
                    </Box>
                
                </SimpleGrid> */}
        </>
    )
}
export default Mojo;