import React from 'react';
import { SimpleGrid, Heading as Heading2, Box, GridItem } from '@chakra-ui/react';
import PriceCard3 from './PriceCard3';
import Text from '../SubHeading/SubHeading';
import Heading from '../Heading/Heading';

const GiftCardWrapper = (props) => {
    return (
        <>
            {props.isHeading &&
                <Box padding={{ sm: "0 0 30px", md: '20px 0 50px' }}>
                    <Heading2 textTransform="uppercase" letterSpacing='0px' fontSize={{ sm: "30px", md: "60px", lg: "90px", }} lineHeight="76%" fontFamily='Poppins'>Perpetua Gift Cards</Heading2>

                    {props.isColums &&
                        <SimpleGrid columns={{ sm: "1", md: "2" }} spacing={10} margin='30px 0px'>
                            <GridItem>
                                <Heading title='Flexible' variant='smallmid' padding='10px 0' />
                                <Text title='Use the credits for any of our studio class within their expiry date' color='#000' />
                            </GridItem>
                            <GridItem>
                                <Heading title='Access To ALL Studio Class' variant='smallmid' padding='10px 0' />
                                <Text title='Attend Tread & Shred, Rumble, Rythm Ride, Ride 45 & HYROX class' color='#000' />
                            </GridItem>
                        </SimpleGrid>
                    }
                </Box>
            }

            <SimpleGrid columns={{ sm: "1", md: "2", lg: "3" }} spacing={12} >
                <PriceCard3 key="42" currency="€22" planname="Kris Kindle Pack - 2 for €22" offer="Digital Gift Card" link="https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=42&sLoc=0&giftCardID=108036" targetBlank='true' description="" buttonTitle='Buy Now' giftCard='true' />

                <PriceCard3 key="47" currency="€33" planname="Intro Offer 3 for €33" offer="a gift for a newbie to Perpetua" link="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=104433" targetBlank='true' description="T&Cs applied" buttonTitle='Buy Now' />

                <PriceCard3 key="43" currency="€70" planname="3 CREDITS Studio Pack" offer="Digital Gift Card" link="https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=42&sLoc=0&giftCardID=108037" targetBlank='true' description="" buttonTitle='Buy Now' />

                <PriceCard3 key="44" currency="€112" planname="5 CREDITS Studio Pack" offer="Digital Gift Card" link="https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=42&sLoc=0&giftCardID=108038" targetBlank='true' description="" buttonTitle='Buy Now' />

                <PriceCard3 key="45" currency="€215" planname="10 CREDITS Studio Pack" offer="Digital Gift Card" link="https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=42&sLoc=0&giftCardID=108039" targetBlank='true' description="" buttonTitle='Buy Now' />

                <PriceCard3 key="46" currency="€215" planname="CROSSFIT ACADEMY" offer="Digital Gift Card" link="https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=42&sLoc=0&giftCardID=108040" targetBlank='true' description="" buttonTitle='Buy Now' />

                <PriceCard3 key="40" currency="Custom Amount" planname="GIFT CARD" offer="best intro offer" link="https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=42&sLoc=0&giftCardID=106558" targetBlank='true' description='Instructions**' buttonTitle='Buy Now' tagTitle='Give the Gift of Fitness' />

            </SimpleGrid>

            <Box paddingTop={{ sm: "50px", md: "60px", lg: "70px" }}>
                <Text title='**Simply load a gift card with a custom amount and print it out or email it directly to the recipient. They can then redeem the credit with a product or service of their choice. Alternatively, you can purchase at Front of House.' />
            </Box>
        </>
    )
}

export default GiftCardWrapper;