import React from 'react';
import { Box, Text, VStack, Stack, Link } from '@chakra-ui/react';
import LinkButton from '../Button/LinkButton';
import { NavLink } from "react-router-dom";
import { defaultClassCardProps } from './ClassCard.type';
import Heading from '../Heading/Heading';
function ClassCard(props) {
  return (
    <NavLink to={props.linkBg}>
      <Box maxW={{ md: 'full' }}
        p='20px'
        alignItems="flex-start"
        textAlign="left"
        backgroundImage={props.bg}
        width={props.width}
        margin={props.margin}
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        minHeight={props.minHeight}
        height={props.height}
        backgroundSize={"cover"}
        position="relative"
        className="wow fadeInUp boxoverlay"
      >
        {props.isHeading || props.isSubheading || props.isButton ? (
          <VStack spacing={2} align='stretch' width={"full"} position="absolute" bottom="0" left="0" padding='0px 20px'
            marginBottom={props.marginBottom} zIndex='1'
          >

            {props.isHeading &&
              <Heading p="0" m="0" lineHeight={"1"} as="h3" color='white' variant="medium" title={props.title} marginBottom='10px'></Heading>
            }
            {props.isSubheading &&
              <Text as="p" color='white' fontFamily={'poppins'} fontSize='13px' fontWeight='500' textAlign='justify' textTransform={props.transform} width='100%' >{props.subtitle}</Text>
            }
            {props.isButton &&
              <Stack align='stretch' width={"full"} alignItems={{ sm: 'flex-start', md: 'flex-end' }} paddingRight='35px' >
                <Link href={props.link} isExternal={props.targetBlank}>
                  <LinkButton title={props.buttonTitle} color='white' Imguri='/images/Arrow.svg' padding="0px" />
                </Link>
              </Stack>
            }

          </VStack>
        ) : null
        }

      </Box>
    </NavLink>
  )
}

export default ClassCard;
ClassCard.defaultProps = defaultClassCardProps