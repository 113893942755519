import React, { lazy, Suspense, useState } from 'react';
import {
    FormControl,
    FormLabel,
    Stack,
    FormErrorMessage, Select,
    Input,
    Textarea,
    Box,
    Flex,
    Center
} from '@chakra-ui/react'
import Button from '../Button/CustomeButton';
import axios from 'axios';
import Text from '../SubHeading/SubHeading';

const DropInForm = () => {


    const [successFrom, setsuccessFrom] = useState('');
    const [btnDisable, setBtnDisable] = useState(false);
    const [nameError, setnameError] = useState('');
    const [emailError, setemailError] = useState('');
    const [messageError, setmessageError] = useState('');

    // const [isCaptcha, setIsCaptcha] = useState(null);
    // const [errorCaptcha, setErrorCaptcha] = useState('');

    // const getReCaptchaVal = (value) => {
    //     setIsCaptcha(value);
    //     setErrorCaptcha('');
    // }

    const [contactForm, setContactForm] = useState({
        name: "",
        email: "",
        phoneNumber: "",
        message: "",
    });
    const handelInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setContactForm({ ...contactForm, [name]: value })
    }
    const handelSubmit = (e) => {
        e.preventDefault();
        setsuccessFrom('');
        setBtnDisable(true);

        // if (isCaptcha === null) {
        //     setBtnDisable(false);
        //     setsuccessFrom('');
        //     setErrorCaptcha('Please verify that you are not a robot.');
        //     return false;
        // }

        axios.post(process.env.REACT_APP_ADMIN_API_BASE_URL + '/inner-page-contact', {
            name: contactForm.name,
            email: contactForm.email,
            subject: 'CrossFit Drop In',
            phone_no: contactForm.phoneNumber,
            message: contactForm.message,
            page_name: 'CrossFit'

        })
            .then((response) => {
                setsuccessFrom(response.data.success);
                setBtnDisable(false);
                // setIsCaptcha(null);
                contactForm.name = "";
                contactForm.email = "";
                contactForm.phoneNumber = "";
                contactForm.message = "";

                // window.grecaptcha.reset();
            })
            .catch((error) => {
                setsuccessFrom('');
                setBtnDisable(false);
                // window.grecaptcha.reset();
                console.log(error.response.data.error.message[0]);

                // if (error.response.data.error.name[0]) {
                //     setnameError(error.response.data.error.name[0]);
                // } else {
                //     setnameError('');
                // }
                // if (error.response.data.error.email[0]) {
                //     setemailError(error.response.data.error.email[0]);
                // } else {
                //     setemailError('');
                // }
             
                if (error.response.data.error.message[0]) {
                    setmessageError(error.response.data.error.message[0]);
                } else {
                    setmessageError('');
                }
            });
    }

    return (
        <form action="" onSubmit={handelSubmit} className="cpContact">
            <FormControl isRequired >
                <Stack position='relative' marginTop='40px'>
                    <FormLabel
                        fontSize='18px'
                        textTransform='uppercase'
                        lineHeight='1'
                        width='fit-content'
                        position='absolute'
                        top="0"
                        background='white'
                        zIndex='2'
                        left='12px'
                        padding='0px 5px'
                    >Name</FormLabel>
                    <Input _placeholder={{ color: 'Grey', fontFamily: 'BlenderProBold , sans-serif', fontSize: '18px', textTransform: 'uppercase' }} border='1px solid #000' height='50px' color='black' fontSize='18px' name="name" value={contactForm.name} onChange={handelInput} />
                </Stack>


                <Stack position='relative' marginTop='40px'>
                    <FormLabel
                        fontSize='18px'
                        textTransform='uppercase'
                        lineHeight='1'
                        width='fit-content'
                        position='absolute'
                        top="0"
                        background='white'
                        zIndex='2'
                        left='12px'
                        padding='0px 5px'

                    >Email</FormLabel>
                    <Input type='email' _placeholder={{ color: 'Grey', fontFamily: 'BlenderProBold , sans-serif', fontSize: '18px', textTransform: 'uppercase' }} border='1px solid #000' height='50px' color='black' fontSize='18px' name="email" value={contactForm.email} onChange={handelInput} />
                </Stack>
                <Stack  position='relative' marginTop='40px'>
                    <FormLabel
                        fontSize='18px'
                        textTransform='uppercase'
                        lineHeight='1'
                        width='fit-content'
                        position='absolute'
                        top="0"
                        background='white'
                        zIndex='2'
                        left='12px'
                        padding='0px 5px'

                    >Phone Number</FormLabel>
                    <Input type='number' _placeholder={{ color: 'Grey', fontFamily: 'BlenderProBold , sans-serif', fontSize: '18px', textTransform: 'uppercase' }} border='1px solid #000' height='50px' color='black' fontSize='18px' name="phoneNumber" value={contactForm.phoneNumber} onChange={handelInput} />
                </Stack>

                <Stack  position='relative' marginTop='40px'>
                    <FormLabel
                        fontSize='18px'
                        textTransform='uppercase'
                        lineHeight='1'
                        width='fit-content'
                        position='absolute'
                        top="0"
                        background='white'
                        zIndex='2'
                        left='12px'
                        padding='0px 5px'

                    >CrossFit Experience</FormLabel>
                    <Textarea
                        _placeholder={{ color: 'Grey', fontFamily: 'BlenderProBold , sans-serif', fontSize: '18px', textTransform: 'uppercase' }}
                        height='120px' border='1px solid #000' resize='none' color='black' fontSize='18px' name="message" value={contactForm.message} onChange={handelInput}
                    ></Textarea>
                    {messageError && <FormErrorMessage>{messageError}</FormErrorMessage>}
                </Stack>
                <Center>
                    <Button btnDisable={btnDisable} submit="submit" title='Contact' width='208px' margin='40px 0 0' color='black' />
                </Center>
                {/* <Stack direction={{ sm: 'column', md: 'column', lg: 'row' }} spacing={{ md: '25px', lg: '30px', xl: '50px' }} position='relative' marginTop='25px'>
                        <Suspense fallback={"Loading.."}>
                            <ReCAPTCHA sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITEKEY} onChange={getReCaptchaVal} className='captcha' />
                        </Suspense>
                        <Button btnDisable={btnDisable}  submit="submit" title='Contact' width='208px' margin='30px 0px 0px' color='black'></Button>
                    </Stack>
                    {errorCaptcha &&  <Text title={errorCaptcha} fontSize="14px" color="red"/>} */}
                <Flex justifyContent="center" margin="30px 0 0"> {successFrom && <Text title={successFrom} fontSize="16px" color="green" />}</Flex>

            </FormControl>
        </form>
    )
}

export default DropInForm;