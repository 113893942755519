import React from 'react';
import Heading from '../Heading/Heading';
import { Box, Stack, Image, HStack, Link } from '@chakra-ui/react'
import Text from '../SubHeading/SubHeading';

function OurLocation() {
    return (
        <>
            <Stack borderBottom='3px solid #D9D9D9' paddingLeft='8px' position='relative' _after={{ position: 'absolute', content: '""', width: '40%', height: '3px', top: 'auto', bottom: '-3px', left: '0', background: 'Red', zIndex: '1' }}>
                <Heading title='Our Locations' variant='medium' margin='0px 0px 15px' lineHeight='1'></Heading>
            </Stack>
            <Stack paddingLeft='8px' marginTop='30px'>
                <HStack spacing='5px' alignItems='top'>
                    <Heading as='h4' variant='smallmid' title="PERPETUA FITNESS WINDMILL LANE"></Heading>
                </HStack>
                <HStack spacing='5px' alignItems='top'>
                    <Box as='span' paddingTop='5px'>
                        <Image src='../images/location.svg'></Image>
                    </Box>

                    {/* <Text as='span' 
                    title='2 WINDMILL LANE Windmill Quarter Dublin 2' 
                    width={{sm:'100%',md:'50%'}} 
                    fontFamily="'BlenderProBold', sans-serif"
                    textTransform='uppercase'
                    fontSize='sm2'
                    lineHeight='1.2'
                > </Text> */}

                    <Text as='span'
                        title={<Box lineHeight='1.5'>CrossFit Dublin,<br />
                            2 Windmill Lane,<br />
                            Windmill Quarter,<br />
                            Dublin 2.<br />
                            D02 kX66</Box>}
                        width={{ base: '100%' }}
                        fontFamily="'BlenderProBold', sans-serif"
                        textTransform='uppercase'
                        fontSize='sm2'
                        lineHeight='1.2'
                    > </Text>
                </HStack>
                <HStack spacing='10px' alignItems='center' paddingTop='10px'>
                    <Box as='span' paddingTop='5px'>
                        <Image src='../images/phone.svg'></Image>
                    </Box>
                    <Link href="tel:01 524 26 35">
                        <Text as='span'
                            title='01 524 26 35'
                            width={{ base: '100%' }}
                            fontFamily="'BlenderProBold', sans-serif"
                            textTransform='inherit'
                            fontSize='xs2'
                            lineHeight='1.2'
                        > </Text>
                    </Link>
                </HStack>
                <HStack spacing='10px' alignItems='center' paddingTop='10px'>
                    <Box as='span' paddingTop='5px'>
                        <Image src='../images/email.svg' ></Image>
                    </Box>
                    <Link href="mailto:windmilllane@perpetua.ie">
                        <Text as='span'
                            title='windmilllane@perpetua.ie'
                            width={{ base: '100%' }}
                            fontFamily="'BlenderProBold', sans-serif"
                            textTransform='inherit'
                            fontSize='xs2'
                            lineHeight='1.2'
                        > </Text></Link>
                </HStack>
            </Stack>
            <Stack paddingLeft='8px' marginTop='30px' borderTop='1px solid #D9D9D9' paddingTop='30px'>
                <HStack spacing='5px' alignItems='top'>
                    <Heading as='h4' variant='smallmid' title="PERPETUA FITNESS LENNOX STREET"></Heading>
                </HStack>
                <HStack spacing='5px' alignItems='top'>
                    <Box as='span' paddingTop='5px'>
                        <Image src='../images/location.svg'></Image>
                    </Box>
                    {/* <Text as='span' 
                    title='2 WINDMILL LANE Windmill Quarter Dublin 2' 
                    width={{sm:'100%',md:'50%'}} 
                    fontFamily="'BlenderProBold', sans-serif"
                    textTransform='uppercase'
                    fontSize='sm2'
                    lineHeight='1.2'
                > </Text> */}

                    <Text as='span'
                        title={<Box lineHeight='1.5'>The Lennox Building,<br />
                            Lennox St,<br />
                            Saint Kevin's,<br />
                            Dublin 2<br />
                            D02 FK02<br /></Box>}
                        width={{ base: '100%' }}
                        fontFamily="'BlenderProBold', sans-serif"
                        textTransform='uppercase'
                        fontSize='sm2'
                        lineHeight='1.2'
                    > </Text>
                </HStack>
                <HStack spacing='10px' alignItems='center' paddingTop='10px'>
                    <Box as='span' paddingTop='5px'>
                        <Image src='../images/phone.svg'></Image>
                    </Box>
                    <Link href="tel:01 524 26 35">
                        <Text as='span'
                            title='01 524 26 35'
                            width={{ base: '100%' }}
                            fontFamily="'BlenderProBold', sans-serif"
                            textTransform='inherit'
                            fontSize='xs2'
                            lineHeight='1.2'
                        > </Text></Link>
                </HStack>
                <HStack spacing='10px' alignItems='center' paddingTop='10px'>
                    <Box as='span' paddingTop='5px'>
                        <Image src='../images/email.svg' ></Image>
                    </Box>
                    <Link href="mailto:lennox@perpetua.ie">
                        <Text as='span'
                            title='lennox@perpetua.ie'
                            width={{ base: '100%' }}
                            fontFamily="'BlenderProBold', sans-serif"
                            textTransform='inherit'
                            fontSize='xs2'
                            lineHeight='1.2'
                        > </Text></Link>
                </HStack>
            </Stack>
            <Stack paddingLeft='8px' marginTop='39px' paddingTop='39px' borderTop='1px solid #D9D9D9'>
                <Heading title='Other ways to get in touch' variant='smallmid' margin='0px 0px 5px' lineHeight='1' color='black'></Heading>
                <HStack spacing='10px' alignItems='top'>
                    <Box as='span' paddingTop='0px'>
                        <Image src='../images/email.svg' width='30px'></Image>
                    </Box>
                    <Link href="mailto:hello@perpetua.ie">
                        <Text as='span'
                            title='hello@perpetua.ie'
                            width={{ base: '100%' }}
                            fontFamily="'BlenderProBold', sans-serif"
                            textTransform='inherit'
                            fontSize='sm2'
                            lineHeight='1.2'
                        > </Text></Link>
                </HStack>
            </Stack>
            <Stack paddingLeft='8px' marginTop='39px' paddingTop='39px' borderTop='1px solid #D9D9D9'>
                <Heading title='WINDMILL LANE OPENING HOURS:' variant='smallmid' margin='0px 0px 5px' lineHeight='1' color='black'></Heading>
                <HStack spacing='10px' alignItems='top'>
                    <Box as='span' paddingTop='5px'>
                        <Image src='../images/clock-outline.svg' color='#000'></Image>
                    </Box>
                    <Box display='flex' flexDirection='column' gap='8px'>
                        <Text as='span'
                            title='Monday - Thursday: 5:30am - 9:00pm'
                            width={{ sm: '100%' }}
                            fontFamily="'BlenderProBold', sans-serif"
                            textTransform='uppercase'
                            fontSize='sm2'
                            lineHeight='1.2'
                        > </Text>
                        <Text as='span'
                            title='Friday: 5:30am - 8:00pm'
                            width={{ sm: '100%' }}
                            fontFamily="'BlenderProBold', sans-serif"
                            textTransform='uppercase'
                            fontSize='sm2'
                            lineHeight='1.2'
                        > </Text>
                        <Text as='span'
                            title='Saturday & Sunday: 8:00am - 2:00pm'
                            width={{ sm: '100%' }}
                            fontFamily="'BlenderProBold', sans-serif"
                            textTransform='uppercase'
                            fontSize='sm2'
                            lineHeight='1.2'
                        > </Text>
                    </Box>
                </HStack>
            </Stack>
            <Stack paddingLeft='8px' marginTop='39px' paddingTop='39px' borderTop='1px solid #D9D9D9'>
                <Heading title='LENNOX STREET OPENING HOURS:' variant='smallmid' margin='0px 0px 5px' lineHeight='1' color='black'></Heading>
                <HStack spacing='10px' alignItems='top'>
                    <Box as='span' paddingTop='5px'>
                        <Image src='../images/clock-outline.svg' width='30px'></Image>
                    </Box>
                    <Box display='flex' flexDirection='column' gap='8px'>
                        <Text as='span'
                            title='Monday - Thursday: 6:00am - 2:30pm 4:00pm - 8.30pm'
                            width={{ sm: '100%' }}
                            fontFamily="'BlenderProBold', sans-serif"
                            textTransform='uppercase'
                            fontSize='sm2'
                            lineHeight='1.2'
                        > </Text>
                        <Text as='span'
                            title='Tuesday - Thursday: 6:00am - 8.30pm'
                            width={{ sm: '100%' }}
                            fontFamily="'BlenderProBold', sans-serif"
                            textTransform='uppercase'
                            fontSize='sm2'
                            lineHeight='1.2'
                        > </Text>
                        <Text as='span'
                            title='Friday: 6:00am - 8.00pm'
                            width={{ sm: '100%' }}
                            fontFamily="'BlenderProBold', sans-serif"
                            textTransform='uppercase'
                            fontSize='sm2'
                            lineHeight='1.2'
                        > </Text>
                        <Text as='span'
                            title='Saturday: 7:30am - 1:00pm'
                            width={{ sm: '100%' }}
                            fontFamily="'BlenderProBold', sans-serif"
                            textTransform='uppercase'
                            fontSize='sm2'
                            lineHeight='1.2'
                        > </Text>
                        <Text as='span'
                            title='Sunday: 8:00am - 1:00pm'
                            width={{ sm: '100%' }}
                            fontFamily="'BlenderProBold', sans-serif"
                            textTransform='uppercase'
                            fontSize='sm2'
                            lineHeight='1.2'
                        > </Text>
                    </Box>
                </HStack>
            </Stack>
        </>
    );
}

export default OurLocation;