import React from 'react';
import { TextProps, defaultTextProps } from './SubHeading.type';
import { Text as ChakraText} from "@chakra-ui/react"


const Text = (props) => {

    return (
        <>

            <ChakraText 
                as={props.as} 
                fontSize={props.fontSize} 
                color={props.color} 
                fontFamily='poppins'
                p={props.padding} 
                m={props.margin} 
                display={props.display}
                width={props.width}
                textAlign={props.textAlign}
                textTransform={props.textTransform}
                fontWeight='normal'
                cursor={props.cursor}
                lineHeight={props.lineHeight}
                background={props.background}
                textDecoration={props.textDecoration}
                //textAlign={props.align}
                className={props.className}
                borderRadius={props.borderRadius}
                {...props}
                >
                {props.title}
            </ChakraText>

        </>
    );
}

export default Text;
Text.propTypes = TextProps;
Text.defaultProps = defaultTextProps