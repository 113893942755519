import * as React from 'react'
import { Button} from '@chakra-ui/react'
import { ButtonProps } from './Button.type';
import {Image,} from '@chakra-ui/react';
function LinkButton(props) {
  return (

      <Button 
      color={props.color} fontSize={"sm2"} flexDirection={props.flexDirection} textTransform='uppercase' background='transparent' p={props.padding}
      _hover={{color:'Red'}} _focusVisible={{background:'transparent'}} _focus={{background:'transparent'}}
      margin={props.margin} border={props.border} to={props.to} borderRadius='50px' borderColor={props.borderColor}
      onClick={props.onClick}
      >
          {props.title}
          {props.Imguri && <Image  alt='arrow' src={props.Imguri} width='20px' marginLeft={'5px'}/>}
      </Button>

  );
}

export default LinkButton;
Button.propTypes = ButtonProps;