import React from 'react'
import {Container} from '@chakra-ui/react';
import * as Style from './GuestListing.style';
import Heading from "../../Heading/Heading";
import Text from "../../SubHeading/SubHeading";

const PageBanner = (props) => {
  return (
    <Style.PageBanner backgroundImage={{ sm:props.bannerImage2, md:props.bannerImage}}>
      <Container maxW={{ md: 'container.lg', xl: 'container.xl' }}>
        <Style.CaptionWrapper>
          <Style.Caption>
          {props.bannerHeading && <Heading  title={props.bannerHeading} variant='extralarge' margin='0px 0px 15px' lineHeight='0.8'/>}
          {props.bannerDescription && <Text title={props.bannerDescription} color='Deepgre' lineHeight='1.2' margin='0px'/>}
          </Style.Caption>
        </Style.CaptionWrapper>
      </Container>
    </Style.PageBanner>
  )
}

export default PageBanner