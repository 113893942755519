import React from 'react'
import {Link} from '@chakra-ui/react';
import * as Style from './ThankyouCard.style';
import {FaCheckCircle} from "react-icons/fa";

const ThankyouCard = (props) => {
  return (
    <Style.ThankyouCard>
        <Style.Icon color='#D12C41' fontSize={{sm:"sm", md:"md"}}>
            <FaCheckCircle />
        </Style.Icon>
        <Style.HeadingText as='h1'  fontSize={{sm:"lg", md:"xl"}}>
            {props.heading}
        </Style.HeadingText>
        <Style.Description as='p'fontSize={{md:"xs"}} fontFamily="poppins" >{props.description} </Style.Description>
        <Link href='/'>
            <Style.BackButton >{props.buttontext}</Style.BackButton>
        </Link>
        

    </Style.ThankyouCard>
  )
}

export default ThankyouCard