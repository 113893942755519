import { Box, Button, Link } from "@chakra-ui/react";
import styled from "@emotion/styled/macro";
import { NavLink } from 'react-router-dom';

export const ButtonModal = styled(Button)(props => ({

    background: 'transparent',
    padding: '0px 18px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '20px',
    fontFamily: 'BlenderProBold',
    color: 'white',
    textTransform: ' uppercase',
    border: '2px solid #D12C41',
    borderRadius: '50px',
    height: '50px',
    minWidth: '180px',
    ":hover&": {
        background: '#D12C41',

    },

    "@media screen and (max-width:767px)": {
        fontSize: '16px',
        padding: '0px 10px',
        minWidth: '100px',
    },

    "@media screen and (max-width:480px)": {
        fontSize: '16px',
        padding: '0px 10px',
        minWidth: '100px',
    }

}))

export const CaptionBox = styled(Box)(props => ({
    position: 'relative',
    zIndex: '111'
}))

export const VideoBanner = styled(Box)(props => ({
    position: 'relative', backgroundColor: '#000'
}))

export const VideoBox = styled(Box)(props => ({
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: '0',
    top: '0',
    margin: 'auto',
    zIndex: '0',
    display: 'flex',

    "@media screen and (max-width:480px)": {
        width: 'auto',
        left: '-500px',
    }

}))


export const ButtonGrup = styled(Box)(props => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    gap: '10px',
    margin: "10px 0",
}))

export const Button1 = styled(NavLink)(props => ({
    padding: 0,
    margin: 0,
    marginRight: '20px',
    padding: '9px 20px',
    color: '#fff',
    border: '2px solid #d12c41',
    width: '100%',
    maxWidth: '208px',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: 20,
    borderRadius: 300,
    '&:hover': {
        backgroundColor: '#d12c41',
        color: '#fff'
    },
    "@media screen and (max-width:480px)": {
        fontSize: 17,
    },
}))


export const externalBtn = styled(Link)(props => ({
    padding: 0,
    margin: 0,
    marginRight: '20px',
    padding: '9px 20px',
    color: '#fff',
    border: '2px solid #d12c41',
    width: '100%',
    maxWidth: '208px',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: 20,
    borderRadius: 300,
    '&:hover': {
        backgroundColor: '#d12c41',
        color: '#fff'
    },
    "@media screen and (max-width:480px)": {
        fontSize: 17,
    },
}))