import React from "react";
import {  Image, Box, SimpleGrid, GridItem,} from '@chakra-ui/react';
import Heading from '../Heading/Heading';
import Text from '../SubHeading/SubHeading';

function WhyMembershipCard(props) {
    return(
        <>
            <Box padding="20px 0px">
                {/* {props.Imguri && <Image src={props.Imguri} width='50px' height='50px' />} */}
                    <Heading title={props.title} cfSize='20px' padding='10px 0px' />
                    <Text title={props.paragraph} /> 
            </Box> 
           
        </>
    )
}

export default WhyMembershipCard;