import React from 'react'
import * as Style from './GuestListing.style';
import {Container} from '@chakra-ui/react';

const CenterContent = (props) => {
  return (
    <Style.CenterContent>
      <Container maxW={{ md: 'container.lg', xl: 'container.xl' }}>
          <Style.CenterContentWrapper>
              <Style.CenterContentHeader>
              {props.heading && <Style.h2 variant='medium' title={props.heading}/>}
                  {props.subHeading && <Style.p title={props.subHeading} fontSize={{lg:'16px', xl:'18px'}}/>}
              </Style.CenterContentHeader>
              {/* <Style.p title={props.description}></Style.p> */}
          </Style.CenterContentWrapper>
      </Container>
    </Style.CenterContent>
  )
}

export default CenterContent