import React, {useEffect} from 'react';
// import InnerBanner from '../component/InnerBanner/InnerBanner';
import Heading from '../component/Heading/Heading';
import Text from '../component/SubHeading/SubHeading';
import {Box,Stack,Container,Flex,SimpleGrid,VStack,Accordion, AccordionButton,AccordionItem, AccordionPanel, AccordionIcon,Link,Image, GridItem} from '@chakra-ui/react';
// import WhatsincludedMembershipCard from '../component/Whats Included Membership card/WhatsincludedMembership';
import WhatsincludedCard from '../component/WhatsIncluded Card/WhatsincludedCard';
import Testimonial from '../component/Testimonial/Testimonial';
import PriceBox from '../component/Price Component/PriceBox';
import MarqueLoop from '../component/Marque Loop/MarqueLoop';
import ContactForm from '../component/Form/ContactForm';
import LinkButton from '../component/Button/LinkButton';
import WhyMembershipCard from '../component/WhyMemberShip/WhyMembershipCard';
import MetaTitle from './MetaTitle';
import WOW from 'wowjs';
import VideoSection from '../component/VideoSection/VideoSection';
import VideoBanner from '../component/VideoBanner/VideoBanner';
import { Helmet } from "react-helmet";

import BlogSection from '../component/Blog/BlogSection';


//import GetstartedCardfullWidth from '../component/GetstartedCard/GetstartedCardfullWidth';
//import WhyChoose from '../component/WhyChooseSection/WhyChoose';

const metaTitle="Personal Training Dublin | Fitness Trainer Dublin"
const metaDesc="Personal training in Dublin with Perpetua Fitness. Find a fitness trainer in Dublin. We know what it takes."
// document.title =metaTitle; 
// document.getElementsByTagName("META")[2].content=metaDesc;
// document.getElementsByTagName("META")[6].content=metaTitle;
// document.getElementsByTagName("META")[7].content=metaDesc;


function PersonalTraining(props) {

//   const whyChooseData = [
//     {
//       id: '1',
//       title: 'EXPERIENCED COACHES',
//       description: "We would love to meet you at our facility and show you around our facility. We can set up your membership for you or you can purchase a membership by downloading the Perpetua Fitness app."
       
//     },
//     {
//       id: '2',
//       title: 'WE KNOW WHAT IT TAKES',
//       description: 'We have been working with clients all over Ireland for the past 15 years. Our team of experienced coaches know what it takes to get results in a safe and fun environment.',
//     },
//     {
//       id: '3',
//       title: 'PROFESSIONAL SYSTEMS',
//       description: 'We have systems in place to make sure you are receiving the best service available. Each session will delivered online, you can track results, see your progress, communicate with your coach and receive daily accountability.'
//     },

// ]

// const personalTrainingData = [
//   {
//     id: '1',
//     title: 'EXPERT COACHING',
//     description: 'You can choose between 1-1 or 2-1 personal training session which are one hour long',
//     image:  '/images/weight-icon.svg'
//   },
//   {
//     id: '2',
//     title: 'MOVEMENT ASSESSMENT AND IN-BODY ANALYSIS',
//     description: 'When you begin our sessions, you start with a movement assessment so you can find out everything about you and also perform an in-body analysis to get a starting point on what we want to work towards.',
//     image: '/images/justgym-access.svg'
//   },
//   {
//     id: '3',
//     title: 'PERSONAL PROGRAM',
//     description: 'You will receive your weekly program via an app so you can understand exactly what you are working towards, log your numbers and stats and see the progress you are making with the team.',
//     image: '/images/Specialclasses.svg'
//   },
//   {
//     id: '4',
//     title: 'NUTRITION AND FOLLOW UP',
//     description: 'If you would like accountability when it comes to nutrition, then the coach will provide a plan for you to follow via the program app where you can log your daily food intake, upload progress photos and see the progress timeline.',
//     image: '/images/justgym-access.svg'
//   }

// ]

  useEffect(() => {
    new WOW.WOW({
      live: true,
      offset: 0,
      mobile: true,
    }).init();
  }, [])

  return (
    <>
      <MetaTitle title={metaTitle} content={metaDesc}/>
      {/* <InnerBanner
        backgroundColor='black' 
        backgroundImage={{sm:'url(./images/personal_training_banner_m.jpg)', md:'url(./images/personal_training_banner.jpg)'}} 
        title='PERSONAL TRAINING'
        subtitle="We know exactly what it takes to get results"        
        isButton={true} 
        issubtitleRight={true} 
        subtitleRight={<Box display='inline-block'><Image src='../images/map-white.svg' display='inline-block' top='12px' position='relative'></Image>Available at our Windmill Lane & Lennox Street Facility</Box>} 
        buttontitle="Enquire now"
        width={{sm:"100%"}}
        height={{ sm:'450px', md:'500px', lg:'550px', xl:'767px'}}
        margin="0px 0 0"
        Link="/contact-us"
        ButtonModalmarginTop={{sm:'10px', md:'0'}}
        videoModal='https://www.youtube.com/embed/FxuKfzfqP6A'
      ></InnerBanner> */}

      <VideoBanner 
        videobanner='../video/PT-banner.mp4'
        subtitle='We know exactly what it takes to get results'
        title='PERSONAL TRAINING DUBLIN'
        subtitleRight={<Box display='inline-block'><Image src='../images/map-white.svg' display='inline-block' top='12px' position='relative'></Image>Available at our Windmill Lane & Lennox Street Facility</Box>} 
        isButton={true} 
        issubtextpara={false} 
        buttontitle="Book a consult"
        margin="0px 0 0"
        Link="/join-today"
        videoModal='https://www.youtube.com/embed/FxuKfzfqP6A' 
        targetBlank={false}
        modalButtontitle="View The Experience"
        issubtitleRight={true} 
      />

      <Flex  backgroundColor='black' padding="55px 0px 0px">
        <Container maxW='container.xl'>
          <MarqueLoop></MarqueLoop>
        </Container>
      </Flex>
      <Flex  backgroundColor='black' padding="80px 0px">
        <Container maxW='container.xl'>
          <Stack direction={{base:'column',md:'row'} } spacing='30px' textAlign={{base:'left', lg:'left'}} alignItems='center'>
              <Box w={{ base:'100%' ,lg:'50%'}} padding={{md:'0px 20px',lg:'0px 0px'}}>
                <PriceBox 
                  color='white'
                  currency='€'
                  priceValue='From 60'
                  time='/Session'
                  description='Arrange a call with our Head Coach to discuss the options available'
                  buttonText='Book a consult'
                  link='/join-today'
                  buttonPading='0px'
                  issubHeading={false}
                  manheadind='1-1 Personal Training'
                  width={{base:'100%', sm:'70%'}}
                >
                </PriceBox>
              </Box>
              <Box w={{ base:'100%' ,lg:'50%'}} padding={{md:'0px 20px',lg:'0px 0px'}}>
                <Heading title='WHATS INCLUDED ' variant='small' color='white' margin='0px 0px 30px' padding={{sm:'0px 0px 0px 0px', md:'0px 0px 0px 31px'}} textAlign={{sm:'center', ms:'left'}}></Heading>
                <SimpleGrid columns={{base:'1',lg:'2'}} spacing='0px' className='_whatsinclude_box_body'>
                  <Box className='_whatsinclude_box' padding={{base:'15px 10px',xl:'20px'}}>
                      <WhatsincludedCard
                      //Imguri='/images/weight-icon.svg' 
                      title='1-1 or 2-1 PERSONAL TRAINING ' 
                      headingColor='white'
                      buttonColor='white'
                      ButtonMargin='0px 0px 0px auto'
                      padding='0px'
                      spacing={props.padding}
                      alt="Personal Training Dublin - arrow icon"
                          >
                      </WhatsincludedCard >
                  </Box>
                  <Box className='_whatsinclude_box' padding={{base:'15px 10px',xl:'20px'}}>
                      <WhatsincludedCard
                      //Imguri='/images/gym.svg' 
                      title='1 HOUR SESSIONS' 
                      headingColor='white'
                      buttonColor='white'
                      ButtonMargin='0px 0px 0px auto'
                      padding='0px'
                      spacing={props.padding}
                      alt="Personal Training Dublin - arrow icon"
                          >
                      </WhatsincludedCard >
                  </Box>
                  <Box className='_whatsinclude_box' padding={{base:'15px 10px',xl:'20px'}}>
                      <WhatsincludedCard
                      //Imguri='/images/madel.svg' 
                      title='MOVEMENT ASSESSMENT AND
                      IN-BODY ANALYSIS' 
                      headingColor='white'
                      buttonColor='white'
                      ButtonMargin='0px 0px 0px auto'
                      padding='0px'
                      spacing={props.padding}
                      alt="Personal Training Dublin - arrow icon"
                          >
                      </WhatsincludedCard >
                  </Box>
                  <Box className='_whatsinclude_box' padding={{base:'15px 10px',xl:'20px'}}>
                      <WhatsincludedCard
                      //Imguri='/images/open-gym.svg' 
                      title='FREE 14 DAY TRIAL TO OUR ONLINE PROGRAMMING' 
                      headingColor='white'
                      buttonColor='white'
                      ButtonMargin='0px 0px 0px auto'
                      padding='0px'
                      spacing={props.padding}
                      alt="Personal Training Dublin - arrow icon"
                          >
                      </WhatsincludedCard >
                  </Box>
                </SimpleGrid>
              </Box>
          </Stack>
        </Container>
      </Flex>
      {/* <Flex  backgroundColor='black'>
        <Container maxW='container.xl'>
          <Stack direction={{base:'column',md:'row'} } spacing='30px' textAlign={{base:'left', lg:'left'}} alignItems='center' borderTop='1px solid rgba(255,255,255,0.15)'paddingTop='50px' marginTop='50px'>
              <Box w={{ base:'100%' ,lg:'50%'}} padding={{md:'0px 20px',lg:'0px 0px'}}>
                <PriceBox 
                  color='white'
                  currency='€'
                  priceValue='From 280'
                  time='/6 week Block'
                  description='Arrange a call with our Head Coach to discuss the options available'
                  buttonText='Enquire now'
                  link='/join-today'
                  buttonPading='0px'
                  issubHeading={false}
                  manheadind='Small Group Personal Training'
                  width={{base:'100%', sm:'70%'}}
                >
                </PriceBox>
              </Box>
              <Box w={{ base:'100%' ,lg:'50%'}} padding={{md:'0px 20px',lg:'0px 0px'}}>
                <Heading title='WHATS INCLUDED ' variant='small' color='white' margin='0px 0px 30px' padding={{sm:'0px 0px 0px 0px', md:'0px 0px 0px 31px'}} textAlign={{sm:'center', ms:'left'}}></Heading>
                <SimpleGrid columns={{base:'1',lg:'2'}} spacing='0px' className='_whatsinclude_box_body'>
                  <Box className='_whatsinclude_box' padding={{base:'15px 10px',xl:'20px'}}>
                      <WhatsincludedCard
                      //Imguri='/images/weight-icon.svg' 
                      title='Max 4 People Per Group' 
                      headingColor='white'
                      buttonColor='white'
                      ButtonMargin='0px 0px 0px auto'
                      padding='0px'
                      spacing={props.padding}
                      alt="Personal Training Dublin - arrow icon"
                          >
                      </WhatsincludedCard >
                  </Box>
                  <Box className='_whatsinclude_box' padding={{base:'15px 10px',xl:'20px'}}>
                      <WhatsincludedCard
                      //Imguri='/images/gym.svg' 
                      title='Personalised Training Plan' 
                      headingColor='white'
                      buttonColor='white'
                      ButtonMargin='0px 0px 0px auto'
                      padding='0px'
                      spacing={props.padding}
                      alt="Personal Training Dublin - arrow icon"
                          >
                      </WhatsincludedCard >
                  </Box>
                  <Box className='_whatsinclude_box' padding={{base:'15px 10px',xl:'20px'}}>
                      <WhatsincludedCard
                      //Imguri='/images/madel.svg' 
                      title='Online Nutrition Workshop' 
                      headingColor='white'
                      buttonColor='white'
                      ButtonMargin='0px 0px 0px auto'
                      padding='0px'
                      spacing={props.padding}
                      alt="Personal Training Dublin - arrow icon"
                          >
                      </WhatsincludedCard >
                  </Box>
                  <Box className='_whatsinclude_box' padding={{base:'15px 10px',xl:'20px'}}>
                      <WhatsincludedCard
                      //Imguri='/images/open-gym.svg' 
                      title='Inbody Scan' 
                      headingColor='white'
                      buttonColor='white'
                      ButtonMargin='0px 0px 0px auto'
                      padding='0px'
                      spacing={props.padding}
                      alt="Personal Training Dublin - arrow icon"
                          >
                      </WhatsincludedCard >
                  </Box>
                </SimpleGrid>
              </Box>
          </Stack>
        </Container>
      </Flex> */}

      

      {/* <Container maxW='container.xl'>
        <Box padding={{base:'50px 0px', lg:'70px 0px 100px',xl:'130px 0px 200px'}}>
          <Heading 
            variant="extralarge" 
            title='WHY CHOOSE PERPETUA' 
            margin={{sm:'0px auto 10px' ,md:'0px auto 86px'}} 
            width={{base:'90%',md:'70%'}}
            textAlign='center'
            lineHeight='1'
            >

          </Heading>
          <GetstartedCardfullWidth 
            position='absolute' 
            textAlign='right' 
            cardwidth={{base:'90%', md:'80%'}}
            right={{base:'-35px',md:'-50px'}}  
            cardposition="relative"
            minHeight='auto'
            Number='1'
            Heading='EXPERIENCED COACHES'
            Paragraph='We would love to meet you at our facility and show you around our facility. We can set up your membership 
            for you or you can purchase a membership by downloading the Perpetua Fitness app.'
            padding={{base:'20px 50px 20px 20px', md:'50px 140px 50px 40px'}}
            iconBg='Red'
            iconOutline={{base:'0px 0px 0px 6px rgba(209,44,65,0.3)', md:'0px 0px 0px 12px rgba(209,44,65,0.3)'}}
            cardMargin={'50px auto 0px 0px'}
            top='0'
            bottom='0'
            IconMargin='auto'
            >
          </GetstartedCardfullWidth>
          <GetstartedCardfullWidth 
            position='absolute' 
            textAlign='left' 
            cardwidth={{base:'90%', md:'80%'}} 
            left={{base:'-35px',md:'-50px'}}  
            cardposition="relative"
            minHeight='auto'
            Number='2'
            Heading='WE KNOW WHAT IT TAKES'
            Paragraph='We have been working with clients all over Ireland for the past 15 years. 
            Our team of experienced coaches know what it takes to get results in a safe and fun environment.'
            padding={{base:'20px 40px 20px 50px', md:'50px 40px 50px 140px'}}
            iconBg='Red'
            iconOutline={{base:'0px 0px 0px 6px rgba(209,44,65,0.3)', md:'0px 0px 0px 12px rgba(209,44,65,0.3)'}}
            cardMargin='50px 0px 0px auto'
            IconMargin='auto'
            top='0'
            bottom='0'
            >
          </GetstartedCardfullWidth>
          <GetstartedCardfullWidth 
            position='absolute' 
            textAlign='right' 
            cardwidth={{base:'90%', md:'80%'}} 
            right={{base:'-35px',md:'-50px'}} 
            cardposition="relative"
            minHeight='auto'
            Number='3'
            Heading='PROFESSIONAL SYSTEMS'
            Paragraph='We have systems in place to make sure you are receiving the best service available. Each session will delivered online,
            you can track results, see your progress, communicate with your coach and receive daily accountability. '
            padding={{base:'20px 50px 20px 20px', md:'50px 140px 50px 40px'}}
            iconBg='Red'
            iconOutline={{base:'0px 0px 0px 6px rgba(209,44,65,0.3)', md:'0px 0px 0px 12px rgba(209,44,65,0.3)'}}
            cardMargin='50px auto 0px 0px'
            IconMargin='auto'
            top='0'
            bottom='0'
            >
          </GetstartedCardfullWidth>
        </Box>
      </Container> */}
      {/* <WhyChoose title='WHY CHOOSE PERPETUA' data={whyChooseData} padding={{sm:'60px 15px 10px' ,md:'70px 15px 60px'}}/>   
  
      <WhyChoose title='WHAT YOU GET' data={personalTrainingData} padding={{sm:'10px 15px 20px ' ,md:'10px 15px 40px'}}/> */}

      <Box padding={{ sm: '0px 0px 80px', md: '20px 0px 100px' }} backgroundColor='black'>
        <Container maxW='container.xl'>
          <Heading
            variant="extralarge"
            title='WHY CHOOSE PERPETUA'
            margin={{ sm: '0px auto 50px', md: '0px auto 86px' }}
            width={{ base: '90%', md: '60%' }}
            textAlign='center'
            lineHeight='1'
            color='white'
          />
          <SimpleGrid
            columns={{ sm: '1', md: '3' }}
            spacing={5}
            textAlign='center'
            justifyContent='center'
          >
            <GridItem>
              <Heading title='01' color='Red' textAlign='center' />
              <Heading
                title='RELATIONSHIP BUILDING'
                color='white'
                variant='small'
                padding='15px 20px'
              />
              <Text
                title='Perpetuas coaches would love to meet new clients at our facility and hear about your goals and how we can help. From there, the team can organise the sessions for you and work together to achieve the goals you’ve always wanted.'
                color='white'
                padding='15px 20px'

              />
            </GridItem>
            <GridItem>
              <Heading title='02' color='Red' textAlign='center' />
              <Heading
                title='WE KNOW WHAT IT TAKES'
                color='white'
                variant='small'
                padding='15px 20px'
              />
              <Text
                title='We have been working with clients all over Ireland for the past 15 years. Our team of experienced coaches know what it takes to get results in a safe and fun environment.'
                padding='15px 20px'
                color='white'
              />
            </GridItem>
            <GridItem>
              <Heading title='03' color='Red' textAlign='center' />
              <Heading
                title='PROFESSIONAL SYSTEMS '
                color='white'
                variant='small'
                padding='15px 20px'
              />
              <Text
                title='We have systems in place to make sure you are receiving the best service available. Each session will delivered online, you can track results, see your progress, communicate with your coach and receive daily accountability.'
                color='white'
                padding='15px 20px'
              />
            </GridItem>
          </SimpleGrid>
        </Container>
      </Box>

      <Container maxW='container.xl' padding={{ sm: '60px 30px 0px', md: '80px 30px 10px' }}>
        <Stack direction={{ base: 'column', md: 'row' }} spacing='30px' textAlign={{ base: 'left', lg: 'left' }} alignItems='top' paddingBottom={{ sm: '20px', md: '70px' }}>
          <Box w={{ md: '50%' }} position={{ base: 'relative', md: 'sticky' }} top={{ base: '0', md: '100px' }} height={{ base: 'auto', md: '150px' }} textAlign={{ sm: 'center', md: 'left' }}>
            <Heading title='WHAT YOU GET' lineHeight='1'></Heading>
          </Box>
          <Box width={{ md: '50%', lg: '50%' }}>

            <SimpleGrid
              columns={{ sm: '1', lg: '2' }}
              spacing={8}

            >
              <WhyMembershipCard Imguri='/images/check.png' title='EXPERT COACHING' paragraph='We have been working with clients all over Ireland for the past 15 years. Our team of experienced coaches know what it takes to get results in a safe and fun environment' />

              <WhyMembershipCard Imguri='/images/check.png' title='MOVEMENT ASSESSMENT AND IN-BODY ANALYSIS' paragraph='When you begin our sessions, you start with a movement assessment. This allows us to find out everything about you and discuss where you want to start. We also perform an in-body analysis to get a starting point.' />

              <WhyMembershipCard Imguri='/images/check.png' title='PERSONAL PROGRAM ' paragraph='You will receive your weekly program via an app so you can understand exactly what you are working towards, log your numbers and stats and see the progress you are making with the team.' />

              <WhyMembershipCard Imguri='/images/check.png' title='NUTRITION AND FOLLOW UP ' paragraph='If you would like accountability when it comes to nutrition, then the coach will provide a plan for you to follow via the program app where you can log your daily food intake, upload progress photos and see the progress timeline.' />

            </SimpleGrid>

          </Box>
        </Stack>
      </Container>

      <VideoSection heading='Get Started with' headingSub='Personal Training' video1='https://youtube.com/embed/FxuKfzfqP6A?si=ylqL_5KQNHc_-A1f' video2='https://youtube.com/embed/aTJcGjVLKj8?si=wcQaynEXpb6Ixigp' smallHeading='Personal Training' smallHeading2='Inbody Machine' midHeading='' midHeading2=''/>
       
      <Flex flexDirection='column' padding={{sm:'30px 0px 70px', md:'10px 0px 80px' }} position="relative" marginTop='50px' 
      /* _after={{position:'absolute',content:'""',width:'554px', height:'464px', top:'0',left:'0',background:'LightGrey',zIndex:'-1'}}
      _before={{position:'absolute',content:'""',width:'554px', height:'464px', top:'auto', bottom:'0',right:'0',background:'LightGrey',zIndex:'-1',margin:'auto'}} */
      >
        <Container maxW='container.xl'>
          <Testimonial headingWidth={{sm:'97%',md:'90%'}} iconUri='/images/comment-quote-outline.svg' isname={true} 
          title='“100% CROSSFIT DUBLIN IS THE BEST GYM IN IRELAND. THE COACHING AND PROGRAMMING PROVIDED BY THE 
          DEDICATED TEAM IS EXACTLY WHAT THEY PROVIDE FOR EVERY SINGLE MEMBER”' name='- PPERPETUA CLIENT'/>

        </Container>
      </Flex>

      {/* <Flex 
        m={{sm:'50px 0px 50px', md:'120px 0px 120px'}} 
        p={{sm:'50px 0px 50px', md:'100px 0px 100px'}} 
        alignItems='center'
        alignContent='center'
        justifyContent='center'
        justifyItems='center'
        backgroundColor='black' 
      >
        <Container maxW={{ sm:'Container.sm', md:'container.md',lg:'container.lg',xl:'container.xl'}}  >
            <SimpleGrid spacing={5} w={{sm:'80%',md:'100%'}} m='0px auto' display='flex' flexDirection={{sm:'column', md:'row'}} alignItems='flex-start' >
              <Box padding='60px 0px 45px' position='relative' height='auto' width={{sm:'100%',md:'55%'}}
              _after={{w:'65px', h:'50px',position:'absolute', content:'""',top:'0', left:'0', backgroundImage:'url(../images/left-quote.svg)'}}
              _before={{w:'65px', h:'50px',position:'absolute', content:'""',bottom:'0', right:'0', top:'auto', backgroundImage:'url(../images/right-quote.svg)' }}
              >
                  <Heading as='h3' title='This is exactly what I needed. The guidance and coaching in this amazing facility took my training to the next level. 
                  I am delighted within the results' color='white' variant='medium' lineHeight='1'></Heading>
              </Box>
              <Box padding={{ base:'30px 20px',xl:'50px 60px'}} border='2px solid' borderColor='Red' borderRadius='20px' w={{sm:'100%' ,md:'45%'}} marginLeft={{base:'0px',md:'50px'}}>
              <PriceBox 
                  color='white'
                  currency='€'
                  priceValue='60'
                  time='/session'
                  isButton={false}
                  link='/about'
                  buttonPading='20px 25px'
                  issubHeading={true}
                  width={{base:'100%', sm:'70%'}}
                  isDescription={false}
                  subheading='WHAT DO YOU GET'
                  border='2px solid'
                  borderColor='Red'
                >
                </PriceBox>
                <UnorderedList p='0' m='40px 0px 0px'listStyleType='none' color='white' fontFamily='Poppins' fontWeight='300' >
                  <ListItem borderTop='1px solid #2E2E2E' padding='15px 0px' position='relative' paddingLeft="30px"
                    _after={{w:'10px', h:'10px',position:'absolute', content:'""',top:'21px', left:'0', background:'Red', borderRadius:'50px'}}
                   >
                    EXPERT COACHING 
                  </ListItem>

                  <ListItem borderTop='1px solid #2E2E2E' padding='10px 0px'position='relative'paddingLeft="30px"
                  _after={{w:'10px', h:'10px',position:'absolute', content:'""',top:'21px', left:'0', background:'Red', borderRadius:'50px'}}
                  >
                    MOVEMENT ASSESSMENT AND IN-BODY ANALYSIS
                  </ListItem>

                  <ListItem borderTop='1px solid #2E2E2E' padding='10px 0px'position='relative'paddingLeft="30px"
                  _after={{w:'10px', h:'10px',position:'absolute', content:'""',top:'21px', left:'0', background:'Red', borderRadius:'50px'}}
                  >
                    PERSONAL PROGRAM 
                  </ListItem>

                  <ListItem borderTop='1px solid #2E2E2E'borderBottom='1px solid #2E2E2E' padding='10px 0px'position='relative'paddingLeft="30px"
                  _after={{w:'10px', h:'10px',position:'absolute', content:'""',top:'21px', left:'0', background:'Red', borderRadius:'50px'}}
                  >
                   NUTRITION AND FOLLOW UP
                  </ListItem>

                </UnorderedList>
                <Link href='/contact-us' _hover={{textDecoration:'none'}}>
                    <LinkButton 
                     color='white' 
                     title='BOOK A CALL ' 
                     Imguri='/images/red_arrow.svg' 
                     padding='20px 25px' 
                     margin='30px 0px 0px' 
                     border='2px solid' 
                     borderColor='Red' 

                     ></LinkButton>
                </Link>
              </Box>
            </SimpleGrid>
        </Container> */}

        {/* <Box 
        width={{base:'100%',xl:'54%' }}
        backgroundImage='url(./images/CF-page-bottom-bg.jpg)' 
        height={{base:'500px', xl:'558px'}}
        backgroundRepeat='no-repeat'
        backgroundPosition='center'
        backgroundSize='cover'
        ></Box> */}

      {/* </Flex> */}

      {/* Faq */}

      <Flex p={{ sm: '0px 0px 70px', md: '20px 0px 90px' }}>
        <Container maxW={{ sm: 'Container.sm', md: 'container.lg', lg: 'container.lg', xl: 'container.xl' }}>
          <Stack direction={{ sm: 'column', md: 'row' }} spacing='24px'>
            <Box width={{ sm: '100%', md: '50%' }} /*position={{ base: 'relative', md: 'sticky' }} top={{ sm: '0', md: '100px' }} height={{sm:'auto',md:'150px' }} textAlign={{ sm: 'center', md: 'left' }} */>
              <Box paddingLeft='30px' position={'relative'} _after={{ position: 'absolute', content: '""', width: '3px', height: '100%', top: '0', left: '0', background: 'Red' }}>
                <Heading title='FREQUENTLY ASKED QUESTIONS' lineHeight='1' padding='0px 0px 30px' margin='0px auto' ></Heading>
                <Text title='Questions about the workout or our gyms? We got you. Check out the FAQ below or contact us.' width='80%' ></Text>
              </Box>
            </Box>
            <Box width={{ sm: '100%', md: '50%' }}>
              <Accordion defaultIndex={[0]} allowMultiple className='Accor_item _black_acording  white_acording'>
                <AccordionItem >
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title='How do I get Started?' variant='extrasmall'></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title=' To discuss the process, please arrange a time for a call or to meet our head coach in one of our facilities. From there, we will organise a coach who will best suit your needs to get you started on your fitness journey.' margin='0px 0px 20px'></Text>

                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title="What availability do the coaches have?" variant='extrasmall'></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title="New clients let us know their preferred days and times of when they would like to train. We then find a coach to suit your preference. We would ask that clients would have some flexibility around days and times. " margin='0px 0px 20px'></Text>

                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title='What do I get for my personal training payment?' variant='extrasmall'></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title="Each session is one hour long where you receive the best coaching and the results you desire. Receive an In-Body check at the start of each block and monitor training progress through an app where all your training will be delivered." margin='0px 0px 20px'></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title="What are the options with payment?" variant='extrasmall'></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title=' You can choose between purchasing a block of sessions in either 10, 15 or 20 sessions.' margin='0px 0px 20px'></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title="What are your hours of operation?" variant='extrasmall'></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title='Windmill Lane Studio: Monday-Thursday 6am-9pm | Friday 6am-8pm | Saturday-Sunday 8am-2pm' margin='0px 0px 20px'></Text>
                    <Text title='Lennox Street Studio: Monday-Friday 6am-12pm, 4pm-8pm | Saturday-Sunday 9am-12pm' margin='0px 0px 20px'></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title="Do you have a place I can securely leave my stuff while I work out?" variant='extrasmall'></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title="We have lockers in our men’s and women’s changing rooms where you can leave your belongings. Each locker allows you to create a single-use code while you’re in the facility." margin='0px 0px 20px'></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title="Do you have showers?" variant='extrasmall'></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title='Yes, we have showers in both men’s and women’s changing rooms. We also provide shampoo, conditioner, body wash, hair dryers & straighteners (plus a few extra goodies you might need to replenish after your sweaty session with us!)' margin='0px 0px 20px'></Text>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Box>
          </Stack>
        </Container>
      </Flex>


      {/*  */}
      <BlogSection catSlug = 'personal-training' />
      <VStack backgroundColor='black' padding='96px 0px' borderBottom='1px solid #232323'>
        <Container 
          display='flex'
          maxW='container.xl'
          flexDirection={{base:'column',lg:'row' }}
          alignItems='top'
          alignContent='center'
          justifyContent='center'
          justifyItems='center'
        >
          <Box w={{base:'100%', lg:'50%'}} textAlign={{base:'center', lg:'left'}} marginBottom={{base:'50px', lg:'0'}}>
              <Heading title='LOOKING FOR MORE INFORMATION' variant='extralarge' lineHeight='1' margin='0px 0px 40px' color='white'></Heading>
              <Heading title='Chat with a member of the team' variant='smallmid' lineHeight='1' margin='0px 0px 30px' color='white'></Heading>
              <Link href='/contact-us' textDecoration='none' display='inline-block' _hover={{textDecoration:'none' }}>
                  <LinkButton 
                    title='BOOK A CALL' 
                    Imguri='/images/red_arrow.svg' 
                    border='2px solid' borderColor='Red' 
                    padding='20px 25px'
                    color='White'
                  ></LinkButton>
              </Link>
          </Box>
          <Box w={{base:'100%', lg:'50%'}}>
            <ContactForm pageName="PersonalTraining"></ContactForm>
          </Box>
        </Container>
      </VStack>

    </>
  );
}

export default PersonalTraining;