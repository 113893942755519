import React from 'react'
import * as Style from './GuestListing.style';
import {Container} from '@chakra-ui/react';
import FaqBox from './FaqBox';

const FaqContent = (props) => {
  return (
    <Style.faqSection>
      <Container maxW={{ md: 'container.lg', xl: 'container.xl' }}>
      <Style.h2 variant='extralarge' title={props.title} textAlign='center' />
      <Style.p title={props.smallTittle} fontSize='18px' paddingBottom='30px' textAlign='center'/>
        {
          props.faqcontent.map((item,index)=>{
              return(
                <FaqBox key={index[0]}  title={item.title} para={item.para} linkPara={item.linkPara} linkTitle={item.link} pageLink={item.pageLink}/>
              )
          })
        }
      </Container>
    </Style.faqSection>
  )
}

export default FaqContent