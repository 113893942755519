import { Box,Button,Link} from "@chakra-ui/react";
import styled from "@emotion/styled/macro";
// import { device } from "../../Device.types";


export const LocationBox= styled(Box)(props => ({
    padding:0,
    margin:0,
}))

export const LocationInfo= styled(Box)(props => ({
    display:'flex',
    alignItems:'center',
    justifyContent:'flex-start',
    flexDirection:'row',
    flexWrap:'wrap',
    gap:'5px',
    marginBottom:'10px'
}))

export const  ButtonModal = styled(Button)(props => ({

    background: 'transparent',
    padding: '0px 18px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '20px',
    fontFamily: 'BlenderProBold',
    color: 'white',
    textTransform: ' uppercase',
    border: '2px solid #D12C41',
    borderRadius: '50px',
    height: '50px',
    minWidth: '180px',
    ":hover&": {
        background: '#D12C41',
        
    },

    "@media screen and (max-width:480px)": {
        fontSize: '16px',
        padding: '0px 10px',
        minWidth: '100px',
    }

}))

export const  ButtonSection = styled(Box)(props => ({
    display:'flex',
    alignItems:'center',
    justifyContent:'flex-start',
    flexDirection:'row',
    flexWrap:'wrap',
    gap:'5px',
    marginTop:'20px'
}))

export const  LinkButton = styled(Link)(props => ({

    background: 'transparent',
    padding: '0px 18px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '20px',
    fontFamily: 'BlenderProBold',
    color: 'white',
    textTransform: ' uppercase',
    border: '2px solid #D12C41',
    borderRadius: '50px',
    height: '50px',
    minWidth: '180px',
    ":hover&": {
        background: '#D12C41',
        color:'#fff',
        
    },

    "@media screen and (max-width:480px)": {
        fontSize: '16px',
        padding: '0px 10px',
        minWidth: '100px',
    }

}))