import React from 'react'
import { Box, Container, Flex, GridItem, Link, SimpleGrid, Image, Stack, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel } from '@chakra-ui/react';
import * as Style from './LandingPage1.style';
import Heading from '../Heading/Heading';
import Text from '../SubHeading/SubHeading';
import { NavLink } from 'react-router-dom';
import Button from '../Button/CustomeButton';
import VideoBanner from '../VideoBanner/VideoBanner';


const LandingPage5 = () => {
    return (
        <Style.PageWrapper>
            <VideoBanner
                videobanner='../video/Hyrox-banner.mp4'
                subtitle='A GROUP FITNESS EXPERIENCE THAT IS UNMATCHED IN IRELAND'
                title={<Box>HYROX <br /> WHAT TO EXPECT </Box>}
                isButton={true}
                issubtextpara={false}
                margin="0"
                videoModal='https://www.youtube.com/embed/gvlg13lHj68'
                targetBlank={false}
                modalButtontitle="Watch First Timer Video"
                issubtitleRight={false}
            />

            <Style.ContentCard1 className='wow animate fadeIn'>
                <Container maxW="container.xl">
                    <Heading title="Welcome to HYROX " variant="extralarge" margin="0px 0px 25px"></Heading>
                    <Text title="Our signature HYROX class is a group fitness experience that is unmatched in Ireland. It is tailored towards complete beginners as well as professional athletes." as='p' margin="0px auto 15px" fontSize="18px" width="90%" ></Text>
                    <Text title="You’ll alternate between treadmill and floor exercises focused on strength, power, and endurance. You’ll burn calories and get stronger and faster every class." as='p' margin="0px auto 15px" fontSize="18px" width="90%"></Text>
                    <Text title="Each class is different with a mix between upper, lower and full body days so you never get bored. " as='p' margin="0px auto 15px" fontSize="18px" width="90%"></Text>
                    <Text title="If you’re a newbie to HYROX or need a little refresher, watch this short video so you know what to expect ahead of your first class." as='p' margin="0px auto 15px" fontSize="18px" width="90%" ></Text>
                </Container>
            </Style.ContentCard1>

            <Box padding="0 10px 50px">
                <Heading
                    title='Ireland’s Premium HYROX Training Club'
                    textAlign='center'
                    variant='large'
                    padding='0 0 15px'
                />
                <Text
                    title='Preparing you the best way possible for a HYROX Event.'
                    textAlign='center'
                />
            </Box>

            <Container maxW="container.xl">
                <SimpleGrid
                    columns={{ sm: '1', md: '2' }}
                    columnGap={10}
                    rowGap={2}
                    padding={{ sm: "25px 0", md: "50px 0" }}
                    alignItems='center'
                    justifyContent='center'
                >
                    <GridItem>
                        <Image src='./images/ThredAndshredFirsTimers1.webp' />
                    </GridItem>
                    <GridItem padding={{ sm: "20px 0 0", md: "0" }}>
                        <Image src='./images/timer-icon-2.svg' margin={{ sm: "0 auto", md: "0" }} className='firstTimersSvg' />
                        <Heading title='15 Mins Before Class' variant='small' padding='15px 0' textAlign={{ sm: "center", md: "start" }} />
                        <Text title="Make sure you have an account with us, and you’re booked into class (that's important). You can create an account here if you don’t have one." />
                        <Text title='Arrive 15 minutes prior to class time, so you can check in at Front of House, grab your sweat towel and get ready in our changing room. You can also avail of a shower towel for after your session.' padding='5px 0' />
                        <Text title='Please make your way down to the back of the gym to HYROX area and wait for your coach to call you into the section. ' />
                    </GridItem>
                </SimpleGrid>
                <SimpleGrid
                    columns={{ sm: '1', md: '2' }}
                    columnGap={10}
                    rowGap={2}
                    padding={{ sm: "25px 0", md: "50px 0" }}
                    alignItems='center'
                    justifyContent='center'
                >
                    <GridItem>
                        <Image src='./images/ThredAndshredFirsTimers2.webp' />
                    </GridItem>
                    <GridItem padding={{ sm: "20px 0 0", md: "0" }}>
                        <Image src='./images/heart-icon.svg' margin={{ sm: "0 auto", md: "0" }} className='firstTimersSvg' />
                        <Heading title='Coaches Brief' variant='small' padding='15px 0' textAlign={{ sm: "center", md: "start" }} />
                        <Text title="At the start of each session, your coach will discuss the session for the next 60 minutes, so you understand the focus for the day. " />
                    </GridItem>
                </SimpleGrid>

                <SimpleGrid
                    columns={{ sm: '1', md: '2' }}
                    columnGap={10}
                    rowGap={2}
                    padding={{ sm: "25px 0", md: "50px 0" }}
                    alignItems='center'
                    justifyContent='center'
                >
                    <GridItem>
                        <Image src='./images/ThredAndshredFirsTimers4.webp' />
                    </GridItem>
                    <GridItem padding={{ sm: "20px 0 0", md: "0" }}>
                        <Image src='./images/bbbb-removebg-preview.svg' margin={{ sm: "0 auto", md: "0" }} className='firstTimersSvg' />
                        <Heading title='Warm Up' variant='small' padding='15px 0' textAlign={{ sm: "center", md: "start" }} />
                        <Text title="Your coach will take everyone through a warmup so this will include general movement and some stretching. The coach will also cover any movements you will perform during the session so everyone can get prepared correctly. " />
                    </GridItem>
                </SimpleGrid>

                <SimpleGrid
                    columns={{ sm: '1', md: '2' }}
                    columnGap={10}
                    rowGap={2}
                    padding={{ sm: "25px 0", md: "50px 0" }}
                    alignItems='center'
                    justifyContent='center'
                >
                    <GridItem>
                        <Image src='./images/ThredAndshredFirsTimers7.webp' />
                    </GridItem>
                    <GridItem padding={{ sm: "20px 0 0", md: "0" }}>
                        <Image src='./images/strength-icon-1.svg' margin={{ sm: "0 auto", md: "0" }} className='firstTimersSvg' />
                        <Heading title='Strength & Conditioning' variant='small' padding='15px 0' textAlign={{ sm: "center", md: "start" }} />
                        <Text title="The main part of the class will focus on developing your strength and also your endurance so you can prepared to smash your HYROX event. " />
                        <Text title="Our classes each day will rotate between strength work/short conditioning piece and longer conditioning workout." padding='5px 0' />
                    </GridItem>
                </SimpleGrid>

                <SimpleGrid
                    columns={{ sm: '1', md: '2' }}
                    columnGap={10}
                    rowGap={2}
                    padding={{ sm: "25px 0 50px", md: "50px 0 100px" }}
                    alignItems='center'
                    justifyContent='center'
                >
                    <GridItem>
                        <Image src='./images/ThredAndshredFirsTimers6.webp' />
                    </GridItem>
                    <GridItem padding={{ sm: "20px 0 0", md: "0" }}>
                        <Image src='./images/aaaaa-removebg-preview.svg' margin={{ sm: "0 auto", md: "0" }} className='firstTimersSvg' />
                        <Heading title='After Class' variant='small' padding='15px 0' textAlign={{ sm: "center", md: "start" }} />
                        <Text title="Give yourself a pat on the back for completing your first class!" />
                        <Text title="Collect your shake at Mojo Health Bar (look for your name in the fridge)." padding='5px 0' />
                        <Text title="Go to perpetua.ie, purchase your next studio credit pack (if you’re on an Intro Offer, check your emails!) or membership and pre-book for your next class." />
                    </GridItem>
                </SimpleGrid>
            </Container>

            <Flex p={{ sm: '20px 0', md: '0 0 30px' }}>
                <Container maxW={{ sm: 'Container.sm', md: 'container.lg', lg: 'container.lg', xl: 'container.xl' }}>
                    <Stack direction={{ sm: 'column', md: 'row' }} spacing='24px'>
                        <Box width={{ sm: '100%', md: '50%' }}>
                            <Box paddingLeft='30px' position={'relative'} _after={{ position: 'absolute', content: '""', width: '3px', height: '100%', top: '0', left: '0', background: 'Red' }}>
                                <Heading title='FREQUENTLY ASKED QUESTIONS' lineHeight='1' padding='0px 0px 30px' margin='0px auto' ></Heading>
                                <Text title='Questions about the workout or our gyms? We got you. Check out the FAQ below or contact us.' width='80%' ></Text>
                            </Box>
                        </Box>
                        <Box width={{ sm: '100%', md: '50%' }}>
                            <Accordion defaultIndex={[0]} allowMultiple className='Accor_item _black_acording  white_acording'>
                                <AccordionItem >
                                    <h2>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left' >
                                                <Heading title='What is hyrox?' variant='extrasmall'></Heading>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text title=' HYROX is a global fitness race for Every Body. Participants from all around the world compete in the exact same format. The race starts with a 1 km run, followed by 1 functional movement, which repeats 8 times, taking place in large indoor venues, creating a unique stadium like atmosphere for all participants.' margin='0px 0px 20px'></Text>
                                    </AccordionPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left' >
                                                <Heading title=' How do I join a class?' variant='extrasmall'></Heading>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text title="You can choose between buying a studio credit to join the class or you can start a HYROX membership which gives you access to all HYROX classes and online programming. You can book a class through the Perpetua Fitness app." margin='0px 0px 20px'></Text>
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left' >
                                                <Heading title="How many HYROX classes are available?" variant='extrasmall'></Heading>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text title='We have 9 HYROX classes you can choose from. Monday 8.15am, 6.45pm, Tuesday 8.15am, Wednesday 8.15am, 6.45pm, Thursday 8.15am, Friday 8.15am, Saturday 8am and Sunday 10am. Classes range from 45-60mins.' margin='0px 0px 20px'></Text>
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left' >
                                                <Heading title="What are your hours of operation?" variant='extrasmall'></Heading>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text title='Windmill Lane Studio: Monday-Thursday 6am-9pm | Friday 6am-8pm | Saturday-Sunday 8am-2pm.' margin='0px 0px 20px'></Text>
                                        <Text title='Lennox Street Studio: Monday-Friday 6am-12pm, 4pm-8pm | Saturday-Sunday 9am-12pm .' margin='0px 0px 20px'></Text>
                                    </AccordionPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left' >
                                                <Heading title="Do you have a place I can securely leave my stuff while I work out?" variant='extrasmall'></Heading>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text title='We have lockers in our men’s and women’s changing rooms where you can leave your belongings. Each locker allows you to create a single-use code while you’re in the facility.' margin='0px 0px 20px'></Text>
                                    </AccordionPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left' >
                                                <Heading title="Do you have showers?" variant='extrasmall'></Heading>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text title=" Yes, we have showers in both men’s and women’s changing rooms. We also provide shampoo, conditioner, body wash, hair dryers & straighteners (plus a few extra goodies you might need to replenish after your sweaty session with us!)" margin='0px 0px 20px'></Text>
                                    </AccordionPanel>
                                </AccordionItem>

                            </Accordion>
                        </Box>
                    </Stack>
                </Container>
            </Flex>

            <Style.tremsandcondition className='wow animate fadeIn'>
                <Container maxW="container.xl">
                    <Style.tremsandconditionWrapper>
                        <Heading title="TERMS & CONDITIONS" variant="large" margin="0px 0px 5px" color='#000' />
                        <Text title="Offer valid only for first-time referral clients on an Intro Offer 3 for €33. Promotion can only be used for one #PlusOne at a time. First-timer must purchase an Intro Offer online and take a class with the existing client. Both parties must be present and check in at the front of house together to be eligible for the promotion. Not applicable for private events or corporate buy-outs, including full and/or partial buy-outs. All complimentary class credits expire 30 days from the date credited. Promotion ends 30/09/2023." as='p' fontSize="18px" color='#000' />
                    </Style.tremsandconditionWrapper>
                </Container>
            </Style.tremsandcondition>

            <Flex
                flexDirection='column'
                padding={{ sm: '0 0 50px', md: '50px 0 100px' }}
                textAlign='center'
                justifyContent='center'
                justifyItems='center'
            >
                <Container maxW='container.xl'>
                    <Box>
                        <Heading
                            as="h2"
                            title="ARE YOU READY?"
                            variant='extralarge'
                            textAlign="center"
                            lineHeight="1"
                            padding={{ base: '0px 0px 15px', sm: '0px 0px 30px' }}
                        />
                        <Text
                            title="Try 3 classes for €33. All fitness levels welcome "
                            as='p'
                       />
                        <NavLink to='/contact-us' _hover={{ textDecoration: 'none' }}><Button title='Get Started' className="wow fadeInUp" color='black' margin='40px 0px 0px' /> </NavLink>
                        <Box>
                            <Heading
                                as="h5"
                                title="Download our app "
                                variant='extrasmall'
                                textAlign="center"
                                lineHeight="1"
                                padding={{ sm: '15px 0px 0px', md: '30px 0px 0px' }}
                                display='inline-block'
                            />
                            <Link href="https://play.google.com/store/apps/details?id=com.fitnessmobileapps.perpetuafitness" target='_blank' >
                                <Heading
                                    as="h5"
                                    title="Here"
                                    variant='extrasmall'
                                    textAlign="center"
                                    lineHeight="1"
                                    padding={{ sm: '15px 0px 0px', md: '30px 0px 0px' }}
                                    display='inline-block'
                                    margin='0px 0px 0px 5px'
                                    color='Red'
                                    textDecoration='underline'
                                />
                            </Link>
                        </Box>
                    </Box>
                </Container>
            </Flex>
        </Style.PageWrapper>
    )
}

export default LandingPage5;