import React from 'react'
import { Box, Container,Flex } from '@chakra-ui/react';
import * as Style from './LandingPage1.style';
import Heading from '../Heading/Heading';
import Text from '../SubHeading/SubHeading';
import Accordian from '../Accordion/Accordian';
const accoData = [
    {
        "id": 1,
        "title": "When should I arrive for class?",
        "description": "We recommend arriving at the gym 15 minutes before class for check-in. This will allow time for our Front Desk staff to get you checked into class and show you around the facility.",
    },
    {
        "id": 2,
        "title": "What do I do when I get there?",
        "description": "Say hello to whoever is at the front desk and mention that it is your first time. They’ll get you taken care of.",
    },
    {
        "id": 3,
        "title": "What if I'm running late?",
        "description": "To ensure that your spot is not released to the waitlist, please arrive at least 5 minutes prior to the start of your class.",
    },
    {
        "id": 4,
        "title": "Good Vibes Zone",
        "description": "We want you to bring your good vibes! We truly believe in making our gyms a space where members can check their stress at the door. In our gyms you’ll find a community built on sweat and support, all are welcome here. All we ask is that you show up, bring a smile, and get in a great workout. It’s that simple.",
    }
  
]
const accoData2 = [
    {
        "id": 1,
        "title": "What is a RHYTHM RIDE class like?",
        "description": "In a RHYTHM RIDE class, you can expect sprints, hills and jogs like any other spin class but.. with a little flavour on top. In RHYTHM RIDE you will mindfully move connecting to the beat and switching off any distractions. In this class you will move through choreographed sequences to the best tunes and even better motivation. Get ready to leave feeling inspired and excited for more.",
      },
      {
        "id": 2,
        "title": "I've never done this style of training before, can I keep up?",
        "description": "Absolutely! Our workouts are beginner friendly and designed to allow all athletes to workout at their own pace. Our Coaches are trained to modify and make adjustments to the workout on the fly to meet you where you are.",
    },
    {
        "id": 3,
        "title": "What should I wear?",
        "description": "Wear athletic clothes that are comfy for you to sweat and move in.",
    },
    {
        "id": 4,
        "title": "What shoes should I wear?",
        "description": "Most athletes choose to wear normal trainers or you can wear clip on cycling shoes",
    },
    {
        "id": 5,
        "title": "Do you guys provide water?",
        "description": "Each of our gyms are equipped with filtered water stations. Bring a reusable water bottle with you and fill up before class!",
    }
]
const accoData3 = [
    {
        "id": 1,
        "title": "Do you have showers?",
        "description": "Yes! Each of the gyms have showers that you are free to use. Towels and bath & body products are also provided.",
    },
    {
        "id": 2,
        "title": "That was fun! What's next?",
        "description": "Awesome! Schedule your next workout through the Perpetua Fitness app. If you have any questions, please feel free to reach out to our team at any facility or through hello@perpetua.ie",
    }
  
  ]
  
  

const LandingPage1 = (props) => {
  return (
    <Style.PageWrapper>
        <Box
            alignItems="center"
            justifyContent="center"
            backgroundImage={{sm:'url(./images/ridelandingpage.png)' ,md:'url(./images/ridelandingpage.png)'}}
            backgroundColor='black'
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            height={{ sm: '40vh', md: '450px', lg: '500px', xl: '500px', xxl: '550px' }}
            backgroundSize={"cover"}
            display='flex'
            flexDirection='column'
            position='relative'
        />

        <Style.ContentCard1 className='wow animate fadeIn'>
            <Container maxW="container.xl">
                <Heading title="Welcome to RIDE  " variant="extralarge" margin="0px 0px 25px"></Heading>
                <Text title="More than a spin class. RIDE is an experience. Our RIDE Studio is fully equipped with Keiser M3i bikes, signature lighting, the best beats.. and ENERGY!  " as='p' margin="0px auto 15px" fontSize="18px" width="90%" ></Text>
                <Text title="We offer two types of RIDE – RIDE45 and Rhythm Ride which both offer something for everyone. You’ll find out more here." as='p' margin="0px auto 15px" fontSize="18px" width="90%"></Text>
                <Text title="During your first class, do what feels comfortable for you. Take as many breaks as you need, sit whenever you need and always come back to the legs if the choreography feels overwhelming. This is a judgement free zone, it's a place you are going to grow and learn, and you will always be supported by your coach and fellow attendees. " as='p' margin="0px auto 15px" fontSize="18px" width="90%"></Text>
                <Text title="If you’re a newbie to RIDE or need a little refresher, watch this short video and get ready for the ride of your life. " as='p' margin="0px auto 15px" fontSize="18px" width="90%" ></Text>
                <Style.CnmButton href="https://www.youtube.com/watch?v=UvvyDWQpeDs" target='_blank'>
                    Watch Video
                </Style.CnmButton>
            </Container>
        </Style.ContentCard1>

        <Style.ContentCardBlack>
            <Container maxW="container.xl">
                <Style.ContentCardBlackWrapper className='wow animate fadeIn'>
                    <Style.ContentHeading>
                        <Heading title="BEFORE CLASS" variant="large" margin="0px 0px 5px" color='#fff'/>
                        <Heading title="WHAT I NEED" variant="medium" margin="0px" color='#fff'/>
                    </Style.ContentHeading>
                    <Style.ContentDescription>
                        <Style.ContentDescriptionWrpper>
                            <Text title="1" as='span' fontSize="18px" fontWeight='bold' color='#fff'/>
                            <Text title="Make sure you have an account with us, and you’re booked into class (that's important). You can create an account here if you don’t have one." as='p' fontSize="18px" color='#fff'/>
                        </Style.ContentDescriptionWrpper>
                        <Style.ContentDescriptionWrpper>
                            <Text title="2" as='span' fontSize="18px" fontWeight='bold' color='#fff'/>
                            <Text title="Arrive 15 minutes prior to class time so you can check in at Front of House, get your bike number (so listen up!), pre-order a shake & make yourself comfortable! " as='p' fontSize="18px" color='#fff'/>
                        </Style.ContentDescriptionWrpper>
                        <Style.ContentDescriptionWrpper>
                            <Text title="3" as='span' fontSize="18px" fontWeight='bold' color='#fff'/>
                            <Text title="10 minutes before class you’ll receive an introduction to the RIDE Studio from your coach where you’ll cover bike set up, resistance and hand positions you’ll need to know before getting started. Please let the coach know of any injuries, illnesses or pregnancies while they are helping you set up. " as='p' fontSize="18px" color='#fff'/>
                        </Style.ContentDescriptionWrpper>
                    </Style.ContentDescription>
                </Style.ContentCardBlackWrapper>
            </Container>
        </Style.ContentCardBlack>

        <Style.ContentCardWhite>
            <Container maxW="container.xl" className='wow animate fadeIn'>
                <Style.ContentCardBlackWrapper>
                    <Style.ContentHeading>
                        <Heading title="DURING CLASS" variant="large" margin="0px 0px 5px" color='#000'/>
                        <Heading title="I'M READY TO RIDE!" variant="medium" margin="0px" color='#000'/>
                    </Style.ContentHeading>
                    <Style.ContentDescription>
                        <Style.ContentDescriptionWrpper>
                            <Text title="1" as='span' fontSize="18px" fontWeight='bold' color='#000'/>
                            <Text title="All you need is your training gear (cycling shorts can improve comfort), appropriate shoes or cycling shoes with SPD cleats (small/mountain bike) and water bottle (essential!). If you forget water, Mojo Health Bar is here to save the day! " as='p' fontSize="18px" color='#000'/>
                        </Style.ContentDescriptionWrpper>
                        <Style.ContentDescriptionWrpper>
                            <Text title="2" as='span' fontSize="18px" fontWeight='bold' color='#000'/>
                            <Text title="We'll provide you with shower and sweat towels" as='p' fontSize="18px" color='#000'/>
                        </Style.ContentDescriptionWrpper>
                    </Style.ContentDescription>
                </Style.ContentCardBlackWrapper>
            </Container>
        </Style.ContentCardWhite>

        <Style.ContentCardBlack className='wow animate fadeIn'>
            <Container maxW="container.xl">
                <Style.ContentCardBlackWrapper>
                    <Style.ContentHeading>
                        <Heading title="AFTER CLASS" variant="large" margin="0px 0px 5px" color='#fff'/>
                        <Heading title="WHAT NOW? " variant="medium" margin="0px" color='#fff'/>
                    </Style.ContentHeading>
                    <Style.ContentDescription>
                        <Style.ContentDescriptionWrpper>
                            <Text title="1" as='span' fontSize="18px" fontWeight='bold' color='#fff'/>
                            <Text title="After your cool down with your refreshing eucalyptus towel, give yourself a pat on the back for completing your first class!" as='p' fontSize="18px" color='#fff'/>
                        </Style.ContentDescriptionWrpper>
                        <Style.ContentDescriptionWrpper>
                            <Text title="2" as='span' fontSize="18px" fontWeight='bold' color='#fff'/>
                            <Text title="Collect your shake at Mojo Health Bar (look for your name in the fridge). " as='p' fontSize="18px" color='#fff'/>
                        </Style.ContentDescriptionWrpper>
                        <Style.ContentDescriptionWrpper>
                            <Text title="3" as='span' fontSize="18px" fontWeight='bold' color='#fff'/>
                            <Text title="Go to perpetua.ie, purchase your next studio credit pack (if you’re on an Intro Offer, check your emails!) or membership and pre-book for your next class. " as='p' fontSize="18px" color='#fff'/>
                        </Style.ContentDescriptionWrpper>
                    </Style.ContentDescription>
                </Style.ContentCardBlackWrapper>
            </Container>
        </Style.ContentCardBlack>
        <Box padding={{sm:'35px 0px 30px', md:'80px 0px' }} background={"#eeeef1"} className='wow animate fadeIn'>
            <Container maxW='container.xl' >
            <Flex  margin="auto" textAlign={'center'} width={{sm:'100%', md:'50%' }} flexDirection="column" paddingBottom="30px">
                <Heading 
                lineHeight={"1"} 
                variant="large" 
                title="FREQUENTLY ASKED QUESTIONS" 
                marginBottom='30px' 
                />
                <Box mb="40px">
                    <Text 
                        title="Questions about the classes or next steps? We got you. If you need more information, please feel free to contact us hello@perpetua.ie"
                    /> 
                </Box>
            </Flex>
            
            <Flex flexDirection={{sm: "column", md:"row"}}>

                <Box width={{sm:'100%', md:'33.33%' }} paddingBottom={{sm: "30px", md: 0}}>
                <Heading 
                    lineHeight={"1"} 
                    variant="smallmid" 
                    title="BEFORE CLASS" 
                    marginBottom='20px' 
                    padding="0 15px"
                />
                <Accordian data={accoData} />
                </Box>
                <Box width={{sm:'100%', md:'33.33%' }} paddingBottom={{sm: "30px", md: 0}}> 
                <Heading 
                    lineHeight={"1"} 
                    variant="smallmid" 
                    title="DURING CLASS" 
                    marginBottom='20px' 
                    padding="0 15px"
                />
                <Accordian data={accoData2} />
                </Box>
                <Box width={{sm:'100%', md:'33.33%' }} paddingBottom={{sm: "30px", md: 0}}>
                <Heading 
                    lineHeight={"1"} 
                    variant="smallmid" 
                    title="AFTER CLASS" 
                    marginBottom='20px' 
                    padding="0 15px"
                />
                <Accordian data={accoData3} />
                </Box>
            </Flex>
            </Container>
        </Box>
        <Style.tremsandcondition className='wow animate fadeIn'>
            <Container maxW="container.xl">
                <Style.tremsandconditionWrapper>
                    <Heading title="TERMS & CONDITIONS" variant="large" margin="0px 0px 5px" color='#000'/>
                    <Text title="Offer valid only for first-time referral clients on an Intro Offer 3 for €33. Promotion can only be used for one #PlusOne at a time. First-timer must purchase an Intro Offer online and take a class with the existing client. Both parties must be present and check in at the front of house together to be eligible for the promotion. Not applicable for private events or corporate buy-outs, including full and/or partial buy-outs. All complimentary class credits expire 30 days from the date credited. Promotion ends 30/09/2023." as='p' fontSize="18px" color='#000'/>
                </Style.tremsandconditionWrapper>
            </Container>
        </Style.tremsandcondition>
    </Style.PageWrapper>
  )
}

export default LandingPage1