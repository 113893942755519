import React, { useEffect, useState } from 'react';
import { Box, Flex, Container } from '@chakra-ui/react';
import Heading from '../Heading/Heading';
import BlogCard from './BlogCard';
import axios from 'axios';
import moment from 'moment';
import { useParams } from "react-router-dom";
import * as Style from './BlogCard.style';

function BlogSection(props) {
    const [isLoading, setIsLoading] = useState(true);
    const { slug } = useParams();
    const [latestBlog, setLatestBlog] = useState([]);

    

    useEffect(() => {

        setIsLoading(true);
        if(props.catSlug !==''){
            
            axios({

                method: 'get',
                url: process.env.REACT_APP_ADMIN_API_BASE_URL + '/blogs/latest',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json'
                },
                params: {
                    num_of_post: 3,
                    cat_slug: props.catSlug
                },
    
            }).then(response => {
                if (response.data.status) {
                    setLatestBlog(response.data.data);
                    setIsLoading(false);
                }
    
            }).catch(error => {
                console.log(error);
                setIsLoading(false);
            });
        }
        
        

    }, [props.catSlug]);

    if(props.catSlug === ''){ return null; }
    if(latestBlog.length<1){ return null; }
    

    return (
        <Box padding={{ sm: '20px 0px', md: '50px 0px' }} borderTop='1px solid #d8d8d8'>
            <Container maxW='container.xl'>
                <Box textAlign='center' marginBottom='30px'>
                    <Heading title="Latest Blog" lineHeight='1' variant='medium'></Heading>
                </Box>
                <Flex justifyContent={{ sm: 'center' }} alignItems={{ sm: 'center' }} gap={{ sm: 15, md: 30 }} flexDirection={{ sm: 'column', md: 'row' }}>
                    {latestBlog.map((item, index) => {
                        var img_path = '/images/blog-box.jpg';
                        if (item.image_path !== 'null') { img_path = item.image_path; }
                        return (
                            <BlogCard
                                key={index}
                                uri={img_path}
                                height='260px'
                                tag={item.category.title}
                                date={moment(item.created_at).format('MMMM Do YYYY')}
                                title={item.title}
                                link={'/blog/' + item.slug}
                                taglink={'/blog/category/' + item.category.slug}
                            />
                        )
                    })
                    }
                </Flex>
                <Box textAlign='center' marginTop='30px'>
                    <Style.ViewAll href={'/blog/category/' + props.catSlug}>View All Blogs</Style.ViewAll>
                </Box>
            </Container>
        </Box>

    );
}
export default BlogSection;
