import {Flex} from "@chakra-ui/react";
import styled from "@emotion/styled/macro";

export const  ClasslistingBody = styled(Flex)(props => ({
    width:'100%',
    flexDirection:'row',
    flexWrap:'wrap',
    alignItems:'flex-start',
    justifyContent:'space-between',
    gap:'30px',
}))