import React from 'react';
import LandingPage5 from '../component/LandingPage/LandingPage5';


const HyroxFirstTimers = () => {
  return (
    <LandingPage5 />
  )
}

export default HyroxFirstTimers;