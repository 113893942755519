import { Flex,Box,} from "@chakra-ui/react";
import styled from "@emotion/styled/macro";

export const InBodyWraper = styled(Box)(props => ({
padding:'0',
margin:'0',
    
}))

export const BodyCompositionAnalysers = styled(Box)(props => ({
    padding:'50px 0px',
    margin:'0',
        
}))
export const BodyCompositionAnalysersHeader = styled(Box)(props => ({
    padding:'0px',
    margin:'0',
    textAlign:'center'
        
}))
export const BodyCompositionAnalysersBody = styled(Flex)(props => ({
    padding:'0px',
    margin:'0',
    alignItems:'center',
    justifyContent:'center',
    
    "@media screen and (max-width:767px)": {
        flexDirection:'column',
        },
        
}))
export const BodyCompositionAnalysersBodyLeft = styled(Box)(props => ({
    padding:'0px',
    margin:'0',
    width:'70%',
    marginTop:'30px',
    "ul":{
        padding:'0',
        margin:'0',
        marginTop:'25px',
        "li":{
            display:'flex',
            marginBottom:'10px',
        }
    },

    "@media screen and (max-width:767px)": {
        width:'100%',
        },
        
}))
export const BodyCompositionAnalysersBodyRight = styled(Box)(props => ({
    padding:'0px',
    margin:'0',
    width:'30%' ,   
    ".background-image":{
        width:'100%',
        height:'350px',
        margin:'30px auto 0px',
        backgroundPosition:'center',
        backgroundRepeat:'no-repeat',
        backgroundSize:'cover',
    },

    "@media screen and (max-width:767px)": {
        width:'100%',
        },
}))

export const BodyCompositionVideo = styled(Flex)(props => ({
    padding:'50px 0px',
    margin:'0',
    width:'100%' ,  
    alignItems:'center',
    justifyContent:'center',
    textAlign:'center',
    background:`(${props.background})`, 
    flexDirection:'column',
    "iframe":{
        height:'450px',
        margin:'20px auto'
    }

}))

export const BodyCompositionForm = styled(Flex)(props => ({
    padding:'50px 0px',
    margin:'0',
    alignItems:'center',
    justifyContent:'center',
        
}))

export const FormBoxWraper = styled(Flex)(props => ({
    padding:'0px',
    margin:'0px auto',  
    width:'100%' ,
    boxShadow:'1px 2px 9px #0000002b',
    overflow:'hidden',
    borderRadius:'20px',
    "@media screen and (max-width:767px)": {
        flexDirection:'column',
        },
}))

export const FormImage = styled(Flex)(props => ({
    padding:'0px',
    margin:'0',
    width:'40%',     
    alignItems:'center',  
    justifyContent:'center',
    backgroundPosition:'center',
    backgroundRepeat:'no-repeat',
    backgroundSize:'cover',
    "@media screen and (max-width:767px)": {
        height:'250px',
        width:'100%',
        },
}))
export const FormContent = styled(Flex)(props => ({
    padding:'50px',
    margin:'0px auto',
    width:'60%',   
    alignItems:'center',  
    justifyContent:'center',

    ".inbody-form":{
        width:'100%',
    },

    ".chakra-button":{
            width:'130px',
            minWidth:'130px',
            margin:'0px'
    },
    ".-requer-fild":{
        fontFamily: 'poppins',
        fontSize:'12px',
        fontStyle:'italic',
        color:'#8d8d8d',
        fontWeight:'normal',
        position:'absolute',
        letterSpacing:'0.8px',
        left:'-3px',
    
    },

    "@media screen and (max-width:767px)": {
        width:'100%',
        padding:'35px 20px',
        },
    
    "@media screen and (max-width:480px)": {
            ".css-17n73mc":{
              flexDirection:'column',
            },
            ".chakra-button":{
              marginTop:'20px',
              width:'100%'
            },
        },

}))

export const BodyCompositionBoottom = styled(Flex)(props => ({
    padding:'50px 0px',
    margin:'0',
    alignItems:'center',
    justifyContent:'center',
    background:`(${props.background})`, 
        
}))

export const BodyCompositionBoottomWraper = styled(Flex)(props => ({
    padding:'0',
    margin:'0',
    alignItems:'center',
    justifyContent:'center',
    gap:'30px',

    "@media screen and (max-width:767px)": {
        gap:'25px',
        flexDirection:'column',
        },
}))


