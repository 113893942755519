import { Flex, Box } from "@chakra-ui/react";
import styled from "@emotion/styled/macro";
import { device } from "../Device.types";

export const CorporateOffering = styled(Flex)(props => ({
   background: 'black',
   padding: '50px 0px',
   textAlign: 'center',

   [`@media ${device.tablet}`]: {
      padding: '50px 20px 100px',

   },

}))

export const Corporateimage = styled(Box)(props => ({
   margin: '0px',
   padding: '0px',
   backgroundPosition: 'center',
   backgroundRepeat: 'no-repeat',
   backgroundSize: 'cover',
   width: '100%',
   height: '350px',
   marginBottom: '30px',
}))

export const VideoSection = styled(Box)(props => ({
   margin: '0px',
   padding: '100px 0px',
   borderBottom: '1px solid #232323',
   textAlign: 'center',
   background: '#000'
}))
export const VideoWrapper = styled(Box)(props => ({
   margin: '0px auto',
   display: 'inline-flex',
   alignItems: 'center',
   justifyContent: 'center',
   border: '2px solid #d12c41',
   borderRadius: '17px'
}))

export const CorporateWellness_wrap = styled(Box)(props => ({
   position: "absolute",
   left: 0,
   top: 0,
   right: 0,
   bottom: 0,
   display: "flex",
   alignItems: "center",
   justifyContent: "center",
   flexDirection: "column",
}));