import { Box , UnorderedList, ListItem, Flex } from "@chakra-ui/react";
import styled from "@emotion/styled/macro";


export const PrivacyPolicyContent= styled(Box)(props => ({
   
    padding:'12px 0px'

}))

export const PrivacyUnorderedList = styled(UnorderedList)(props =>({
    padding: '0',
    margin: '10px 0px 0px',
    listStyleType: 'none',
    color: 'black' ,
    fontFamily: 'Poppins',
    fontWeight: '400' 
}))
export const PrivacyListItems = styled(ListItem)(props =>({
    padding: '10px 0px',
    position: "relative",
    fontSize: "16px",
    paddingLeft: "30px",
    ":after": {
        width: '9px',
        height: '9px',
        position: 'absolute',
        content: '""',
        top: '15px',
        left: '0',
        background: '#D12C41',
        borderRadius: '50px'
    }
   
}))
export const PrivacySubText = styled(Flex)(props => ({
    padding: '10px 0px 0px 20px' ,
}))
