import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const MyImage = (props) => (
  <div>
    <LazyLoadImage
      alt={props.alt}
      height={props.height}
      src={props.src} // use normal <img> attributes as props
      width={props.width}
      effect="blur"
      objectFit={props.objectFit}
      objectPosition={props.objectPosition}
       />
      
  </div>
);

export default MyImage;