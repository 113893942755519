import React, { useEffect, useRef } from 'react';
import { Container, Link, Text, Box } from '@chakra-ui/react';
import * as Style from './LandingStyle.style';
import MetaTitle from '../pages/MetaTitle';


const UnbrokenPoweredbyRAD = () => {

    const videoEl = useRef(null);
    const attemptPlay = () => {
        videoEl &&
            videoEl.current &&
            videoEl.current.play().catch(error => {
                console.error("Error attempting to play", error);
            });
    };

    useEffect(() => {
        attemptPlay();
    }, []);

    return (
        <>
            <MetaTitle title='Unbroken Powered by R.A.D Global | Perpetua Fitness' />

            <Style.PageBanner backgroundImage={{ sm: 'url(./images/rad_banner_webp.webp)', md: 'url(./images/rad_banner_webp.webp)' }} href="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=107997" target='_blank'></Style.PageBanner>

            <Style.CenterContent>
                <Container maxW={{ md: 'container.lg', xl: 'container.xl' }}>
                    <Style.CenterContentWrapper marginBottom='20px'>
                        <Style.CenterContentHeader>
                            <Style.h2 variant='medium' title='Get ready to go ‘Unbroken Powered by R.A.D Global’' />
                            <Style.h2 variant='medium' title='OCT 12-19 ' margin='0 auto' />
                            <Style.p title='8 Studio Classes. 8 Days. A reward from R.A.D & Perpetua for all your hard work.' fontSize={{ lg: '16px', xl: '18px' }} />
                        </Style.CenterContentHeader>
                        <Style.p title='Complete the challenge and take home your very own reward.'></Style.p>
                        <Style.p title='This is one you don’t want to miss.'></Style.p>
                    </Style.CenterContentWrapper>
                    <Style.pageButton href='https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=107997' target='_blank'>I’m ready</Style.pageButton>
                </Container>
            </Style.CenterContent>

            <Style.midContent>
                <Container maxW={{ md: 'container.lg', xl: 'container.xl' }}>
                    <Style.midcontentWrapper>
                        <Style.contentBox1>
                            <Style.h2 variant='small' title='Want to know what it takes to complete the challenge and earn your reward?' marginBottom='20px' />
                            <Style.pointList>
                                <Style.pointlistItem>Grab your Unbroken Challenge Pack <Link href="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=107997" textDecoration='underline' target='_blank'>here</Link> and use it to book into class every day from Oct 12-19. </Style.pointlistItem>
                                <Style.pointlistItem>Once you’ve purchased your Challenge Pack, register for your Finisher T-Shirt by completing the form <Link href="https://6ys5dtrn9e0.typeform.com/unbrokenrad" textDecoration='underline' target='_blank'> here</Link> </Style.pointlistItem>
                                <Style.pointlistItem>Collect your Challenge Stamp Card Day 1, HIIT the studio every day for 8 days and get your Unbroken stamp card marked for each class that you do (the important part!).</Style.pointlistItem>
                                <Style.pointlistItem>During the challenge, we’ll have spot prizes up for grabs including R.A.D Global’s best-selling footwear.  </Style.pointlistItem>
                                <Style.pointlistItem>On Day 8, if you’ve completed the challenge, you’ll receive your finisher t-shirt along with an email from Perpetua Fitness with a special gift just for you. </Style.pointlistItem>
                                <Style.pointlistItem>For everyone that takes part, we’ll have a Recovery Station with C11 Recovery from 9am in Windmill Lane. Train hard, recover harder, right? </Style.pointlistItem>
                            </Style.pointList>
                            {/* <Style.pageButton href='https://6ys5dtrn9e0.typeform.com/UnbrokenJune24' target='_blank' marginTop='25px'>Kit me out</Style.pageButton> */}
                        </Style.contentBox1>
                        <Style.VideoWrapper>
                            <video
                                style={{ maxWidth: "100%", margin: "0 auto", objectFit: 'cover' }}
                                playsInline
                                loop
                                muted
                                controls={false}
                                alt="All the devices"
                                src='../video/UnbrokenDay8.mp4'
                                ref={videoEl}
                                className='UnbrokenDay8_rai_video'
                            />
                        </Style.VideoWrapper>
                    </Style.midcontentWrapper>
                </Container>
            </Style.midContent>

            <Style.faqSection>
                <Container maxW={{ md: 'container.lg', xl: 'container.xl' }}>
                    <Style.h2 variant='extralarge' title='FAQS' textAlign='center' />
                    <Style.p title='Check out our FAQ below for all the juicey details:' fontSize='18px' paddingBottom='30px' textAlign='center' />

                    <Style.faqBox allowToggle>
                        <Style.faqItem >
                            <Style.itemButton>
                                <Style.h2 variant='extrasmall' title="What is Unbroken?" />
                                <Style.itemIcon />
                            </Style.itemButton>
                            <Style.itemArea>
                                <Text fontFamily='Poppins' fontSize='16px'>A challenge for your mind & body (with a spicey reward!). A challenge that originated back in 2020 which encourages you to smash 8 studio classes in 8 days. Your Unbroken Challenge pack includes Tread & Shred, Ride, Rumble and HYROX! The perfect way to add some motivation into your fitness routine. Get sweaty and challenge yourself in different classes and get to know new coaches.</Text>

                                <Text fontFamily='Poppins' fontSize='18px' fontWeight='bold' marginTop='15px'>How it works:  </Text>
                                <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px'>Grab your UNBROKEN Class pack - 8 credits for €98 <Link href="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=107997" textDecoration='underline' target='_blank'> here </Link> and use it to book into one class each day from Oct 12-19. This is the perfect chance to try out different classes and coaches. Commit to yourself and smash it!</Text>
                                <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px'><span style={{ fontWeight: "600" }}>See how far you can get in the 8-day challenge!</span> All Participants who take part will still be in with a chance to win our daily spot prizes, including R.A.D’s best selling footwear, and will also receive a ticket to our Recovery Station with C11 Recovery on Day 8 in Windmill Lane.</Text>
                                <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px'>Those who complete all 8 days, will receive a gift of a discounted Studio Packs or 50% off your first/next month of a Studio Membership and a Finisher T-Shirt!</Text>
                                <Text fontFamily='Poppins' fontSize='16px' fontWeight='600' marginBottom='10px'>To participate in the challenge, you must have an Unbroken class pack. </Text>
                            </Style.itemArea>
                        </Style.faqItem>

                        <Style.faqItem >
                            <Style.itemButton>
                                <Style.h2 variant='extrasmall' title="Can I use my regular credits to take part?" />
                                <Style.itemIcon />
                            </Style.itemButton>
                            <Style.itemArea>
                                <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px'>Only classes attended under your 8 unbroken credits will count towards the challenge. All other studio credit packs and studio memberships will be put on hold while you complete the challenge. </Text>
                                <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px'>Save your regular credits for after!</Text>
                            </Style.itemArea>
                        </Style.faqItem>

                        <Style.faqItem >
                            <Style.itemButton>
                                <Style.h2 variant='extrasmall' title="What do I get for completing all 8 Days?" />
                                <Style.itemIcon />
                            </Style.itemButton>
                            <Style.itemArea>
                                <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px'>(Apart from bragging rights)</Text>
                                <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px'>Those who complete all 8 days, will receive a discounted Studio Credit Pack or Studio Membership, a Finisher T-Shirt and a ticket to our Recovery Station with C11 Recovery on Day 8 in Windmill Lane. </Text>
                                <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px' fontWeight='600'>All Participants who take part will still be in with a chance to win our daily spot prizes, including R.A.D’s best selling footwear, and will also receive a ticket to our Recovery Station with C11 Recovery on Day 8 in Windmill Lane.  </Text>
                            </Style.itemArea>
                        </Style.faqItem>
                    </Style.faqBox>

                </Container>
            </Style.faqSection>

            <Box padding={{sm:"0px 0 60px", lg:'10px 0 70px'}}>
                <Container maxW={{ md: 'container.lg', xl: 'container.xl' }}>
                    <Style.CenterContentWrapper>
                        <Style.CenterContentHeader>
                            <Style.h2 variant='medium' title='Terms & Conditions ' />
                            <Text fontFamily='Poppins' fontSize='18px' marginBottom='10px' marginTop='20px' fontWeight='700'>Do you accept the challenge?</Text>
                            <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px'>UNBROKEN Class Packs are valid for use from 12-19 Oct. Credits cannot be extended or paused. You must use the UNBROKEN Class Credits to participate in the challenge and register <Link href="https://6ys5dtrn9e0.typeform.com/unbrokenrad" textDecoration='underline' target='_blank'>here</Link> to avail of your gift. Any other studio credit packs and studio memberships will be paused while you complete the challenge. </Text>
                            <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px'>Challenge pack valid for use in Ride, Tread & Shred, Rumble & HYROX classes in both Windmill Lane and Lennox Street locations.</Text>
                            <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px'>Challenge pack valid for use in Ride, Tread & Shred, Rumble & HYROX classes in both Windmill Lane and Lennox Street locations.</Text>
                        </Style.CenterContentHeader>
                    </Style.CenterContentWrapper>
                </Container>
            </Box>
        </>
    )
}

export default UnbrokenPoweredbyRAD;