import React from 'react';
import {Box,Stack} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import Heading from '../Heading/Heading';
import Text from '../SubHeading/SubHeading';
function JointeamCard(props){
  return (
        <>

            <Stack direction={{base:'column'} }  fontFamily='Poppins' spacing='0px' textAlign={{base:'left'}} alignItems={{base:'left'}} w={{base:'auto'}} >
                <Box width='100%' height='259px' backgroundImage={props.Imguri}></Box>
                <Box width={{base:'100%'}} padding={props.spacing}>
                   {props.istext && <Text title={props.subtitle} as='small' color={props.subheadingColor} fontSize={props.subheadingfontSize} ></Text>}
                    <Heading variant='small' title={props.heading} as='h3' color={props.headingColor} margin='0px 0px 10px'></Heading>
                    <Link  
                        to={props.to}   
                    >
                        {props.linktitle}
                    </Link>
                </Box>
            </Stack>

        </>
  )
}
export default JointeamCard;