import { Box, Flex, UnorderedList, ListItem } from "@chakra-ui/react";
import styled from "@emotion/styled/macro";
import { device } from "../../Device.types";

export const TermsConditionHeading = styled(Box)(props => ({
    paddingTop: '30px',
}))
export const TermsConditionCrossFit = styled(Box)(props => ({
    textAlign: 'center',
    paddingTop: '20px',
    paddingTop: '30px',
}))
export const TermsUnorderedList = styled(UnorderedList)(props =>({
    padding: '0',
    margin: '10px 0px 0px',
    listStyleType: 'none',
    color: 'black' ,
    fontFamily: 'Poppins',
    fontWeight: '400' 
}))
export const TermsListItems = styled(ListItem)(props =>({
    padding: '10px 0px',
    position: "relative",
    fontSize: "16px",
    paddingLeft: "30px",
    ":after": {
        width: '9px',
        height: '9px',
        position: 'absolute',
        content: '""',
        top: '15px',
        left: '0',
        background: '#D12C41',
        borderRadius: '50px'
    }
   
}))
export const TermsSubText = styled(Flex)(props => ({
    padding: '10px 0px 0px 20px' ,
}))