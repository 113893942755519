import React, { useEffect } from 'react';
import InnerBanner from '../component/InnerBanner/InnerBanner';
import * as Style from './Corporate.style';
import { Container, SimpleGrid, VStack, Box, GridItem, Image, Link, Button, Flex, HStack } from '@chakra-ui/react';
import Heading from '../component/Heading/Heading';
import Text from '../component/SubHeading/SubHeading';
import CorporateCard from '../component/CorporateCard/CorporateCard';
import CorporateContent from '../component/CorporateCard/CorporateContent';
import CorporateimageBox from '../component/CorporateCard/CorporateimageBox';
import CorporateimageCard from '../component/CorporateCard/CorporateimageCard';
import PrivateHireAndCorporateForm from '../component/Form/PrivateHireAndCorporateForm';
import MetaTitle from './MetaTitle';
import WOW from 'wowjs';
import VideoCard from '../component/VideoCard/VideoCard';
import { Helmet } from 'react-helmet';
import VideoBanner from '../component/VideoBanner/VideoBanner';
import Testimonial from '../component/Testimonial/Testimonial';
import VideoSection from '../component/VideoSection/VideoSection';
import * as Style1 from './Crossfit.style';
import LazyLoad from "react-lazy-load";
import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react'

function Corporate() {


  useEffect(() => {
    new WOW.WOW({
      live: true,
      offset: 0,
      mobile: true,
    }).init();
  }, [])


  let CorporateimageBoxcontent = [
    {
      Heading: 'PRIVATE CLASSES',
      paragraph: 'Host your own group or corporate class in our RIDE or SWEAT studios. You can hire our studios to schedule a weekly or monthly private class for your employees. The Perpetua team can assist with any special requirements and will provide any of our in house instructors upon request.',
      list: [
        "Food & Bevarage Options from MOJO Health Bar.",
        "Recovery Station with C11 Recovery"
      ]

    }
  ]

  let CorporateCardcontent = [
    {
      Heading: 'SHARED CREDIT POOL',
      paragraph: 'Set up a shared credit pool that your employees can use to book into our studio classes. This offers your team members flexibility and autonomy and is our most popular corporate perk by far!',
      list: [
        "Monthly usage report available ",
        "Train at peak times. "
      ]
    },
  ]

  let CorporateimageCardcontent = [
    {
      Heading: 'Perpetua Training',
      paragraph: "With a hybrid working model taking shape, it's the perfect moment to reconsider how to support your team's wellbeing. Perpetua Training offers interactive fitness solutions that work ANYWHERE. Our programs are flexible, inclusive, social, and tailored to fit your employees and their work environment.",
    },
    {
      Heading: 'EMPLOYEE GIFTING ',
      paragraph: 'Celebrate work anniversaries or a job well-done by giving the gift of fitness! We offer corporate gift vouchers as well as bespoke gift packages to include in your employee recognition programs.',
    },
  ]
  const scrollToBottom = () => {
    const maxScroll = document.documentElement.scrollHeight - window.innerHeight - 700;
    const scrollPosition = Math.min(maxScroll, document.documentElement.scrollHeight);

    window.scrollTo({
      top: scrollPosition,
      behavior: 'smooth'
    });
  };
  return (
    <>
      <Helmet
        script={[
          {
            innerHTML: `window.lintrk('track', {
              conversion_id: 15193380
            });`,
          },
        ]}
      />

      <MetaTitle title='Corporate Wellness & Fitness - Dublin | Perpetua Fitness' />

      <VideoBanner
        videobanner='../video/video-corporat.mp4'
        title="Elevate Your Team’s Well-being"
        isSubtext={false}
        isButton={false}
        issubtextpara={true}
        subtextpara='Train with your team'
        isButton2={true}
        buttontitle2="Book A Consult"
        margin="0px 0 0"
        videoModal='https://www.youtube.com/embed/t3x47LUlTog'
        targetBlank={false}
        modalButtontitle="View The Experience"
        issubtitleRight={false}
        marginTop='20px 0px 0px'
      />
      <Box backgroundColor='black' padding="80px 0" >
        <Container maxW='container.xl'>
          <Heading
            as="h3"
            title="In today's fast-paced corporate world, prioritising employee wellness is essential. At Perpetua Fitness, we offer bespoke wellness programmes designed to enhance the health, happiness, and productivity of your workforce. Our comprehensive services cater to the evolving needs of modern businesses, ensuring a thriving workplace environment. "
            variant='medium'
            textAlign="center"
            lineHeight="1"
            width={{ lg: '100%', xl: '100%' }}
            className='wow animate fadeInUp'
            color='#fff'
          />
        </Container>
      </Box>

      {/* <CorporateCard bg="url('/images/corpotare1.jpg')">
        <CorporateContent width={{ md: '55%', lg: '60%', xl: '50%' }} Heading={CorporateCardcontent[0].Heading} paragraph={CorporateCardcontent[0].paragraph} list={CorporateCardcontent[0].list} btnColor='#000'></CorporateContent>
      </CorporateCard>

      <CorporateimageBox bg="url('/images/corporate-bg.jpg')">
        <CorporateContent listHeading='Exclusive add ons available to enhance your experience including: ' width={{ md: '55%', lg: '60%', xl: '50%' }} Heading={CorporateimageBoxcontent[0].Heading} paragraph={CorporateimageBoxcontent[0].paragraph} list={CorporateimageBoxcontent[0].list} color='#fff'></CorporateContent>
      </CorporateimageBox> 

      <VideoSection heading='Get Started with' headingSub='corporate' video1='https://youtube.com/embed/8sFoRVcyE5s?si=2lFEnruhzUCByqXE' video2='https://youtube.com/embed/mmEJ_gFx0Gw?si=YXWPdD4g0FShjKb5 ' smallHeading='Corporate Wellness with Gym+Coffee' smallHeading2='Corporate Wellness with C11 Recovery at Perpetua' midHeading='' midHeading2='' /> 

      <Container maxW='container.xl' padding={{ sm: '50px 20px', md: '50px 20px', lg: '50px 35px', xl: '100px 20px' }}>
        <SimpleGrid columns={{ sm: '1', md: '2' }} spacing={10}>
          <CorporateimageCard Heading={CorporateimageCardcontent[0].Heading} paragraph={CorporateimageCardcontent[0].paragraph} btnColor='#000'>
            <Style.Corporateimage backgroundImage="url('/images/corporate4.jpg')" ></Style.Corporateimage>
          </CorporateimageCard>

          <CorporateimageCard Heading={CorporateimageCardcontent[1].Heading} paragraph={CorporateimageCardcontent[1].paragraph} btnColor='#000'>
            <Style.Corporateimage backgroundImage="url('/images/corporate5.jpg')" ></Style.Corporateimage>
          </CorporateimageCard>

        </SimpleGrid>
      </Container>
      <Style.VideoSection>
        <Container maxW='container.xl'>
          <Style.VideoWrapper>
            <VideoCard src='https://www.youtube.com/embed/6AfQQs0neAU?si=8nefcVpsH9ZAlfrZ' width={{ sm: '100%', md: '800px' }} maxHeight={{ sm: '420px', md: '600px' }}></VideoCard>
          </Style.VideoWrapper>
        </Container>
      </Style.VideoSection> */}

      <Box padding={{ sm: "50px 0 0", md: '80px 0 0' }}>
        <Container maxW='container.xl'>
          <Heading title='Why Perpetua Fitness?' variant='large' textAlign='center' />
          <Text title='Located in the heart of Dublin, our premium facilities are equipped with the latest fitness technology, offering a dynamic environment that energizes and inspires. With world-class instructors and a range of amenities, Perpetua Fitness ensures a holistic wellness experience. ' textAlign='center' padding='10px 0 0' />
          <SimpleGrid
            columns={{ sm: "1", md: "3" }}
            gap={7}
            justifyContent='center'
            alignItems='center'
            paddingTop={{ sm: "35px", md: "50px" }}
          >
            <GridItem>
              <Image src='./images/pin.png' margin='0 auto' width= "50px" /> 
              <Heading title='Prime Location' variant='small' textAlign='center' padding='10px 0 0' />
            </GridItem>
            <GridItem>
              <Image src='./images/badge.png' margin='0 auto'  width= "50px" /> 
              <Heading title='Cutting-Edge Facilities' variant='small' textAlign='center' padding='10px 0 0' />
            </GridItem>
            <GridItem>
              <Image src='./images/group.png' margin='0 auto'  width= "50px" />
              <Heading title='Expert Coaching ' variant='small' textAlign='center' padding='10px 0 0' />
            </GridItem>
          </SimpleGrid>
        </Container>
      </Box>

      <Box padding={{ sm: "50px 0", md: '80px 0' }}>
        <Container maxW='container.xl'>
          <Heading title='What we offer' variant='large' textAlign='center' />
          <SimpleGrid
            columns={{ sm: "1", md: "2" }}
            gap={7}
            justifyContent='center'
            alignItems='center'
            paddingTop={{ sm: "35px", md: "50px" }}
          >
            <GridItem>
              <Image src='./images/corpotare1.jpg' alt='corpotare page' />
            </GridItem>
            <GridItem>
              <Heading title='Studio Private Hire' variant='medium' />
              <Text title='Host exclusive group classes in our RIDE or SWEAT studios, tailored to your team’s preferences. Our state-of-the-art facilities and experienced instructors guarantee an unforgettable fitness experience. ' padding='5px 0 0' />
              <Flex padding='5px 0 0'>
                <i class="bi bi-circle-fill" style={{ fontSize: "10px", paddingRight: "10px", paddingTop: "6px" }}></i>
                <Text title='Food & Beverage Options with MOJO Health Bar.' />
              </Flex>
              <Flex padding='5px 0 0'>
                <i class="bi bi-circle-fill" style={{ fontSize: "10px", paddingRight: "10px", paddingTop: "6px" }}></i>
                <Text title='Recovery Station with C11 Recovery.' />
              </Flex>
            </GridItem>
          </SimpleGrid>

          <Flex gap={7} alignItems='center' flexDirection={{ sm: "column-reverse", md: "row" }} paddingTop={{ sm: "35px", md: "50px" }}>
            <Box width={{ md: '50%' }}>
              <Heading title='Studio Credit Bundles' variant='medium' />
              <Text title='Provide your employees with the flexibility to book classes using a shared credit pool, promoting autonomy and well-being.  ' padding='5px 0 0' />
            </Box>
            <Box width={{ md: '50%' }}>
              <Image src='./images/corporate_img12.webp' alt='corpotare page' />
            </Box>
          </Flex>

          <SimpleGrid
            columns={{ sm: "1", md: "2" }}
            gap={7}
            justifyContent='center'
            alignItems='center'
            paddingTop={{ sm: "35px", md: "50px" }}
          >
            <GridItem>
              <Image src='./images/corporate_img13.webp' alt='corpotare page' />
            </GridItem>
            <GridItem>
              <Heading title='Perpetua Training' variant='medium' />
              <Text title="Embrace the hybrid working model with our interactive fitness programs, accessible from anywhere. Our on-demand training app is inclusive, social, and tailored to your team's needs. " padding='5px 0 0' />
            </GridItem>
          </SimpleGrid>
        </Container>
      </Box>

      <Box padding={{ sm: "50px 0", md: '80px 0 40px' , xxl:"80px 0"}} backgroundColor='#f7f7f7'>
        <Container maxW='container.xl'>
          <Heading title='Corporate Wellness Stats' variant='large' textAlign='center' />
          <SimpleGrid
            columns={{ sm: "1", md: "2", lg: "3" }}
            gap={7}
            justifyContent='center'
            alignItems='center'
          >
            <GridItem position='relative'>
              <CircularProgress className='CorporateWellnessCircle' value={85} size='450px'>
              </CircularProgress>
              <Style.CorporateWellness_wrap className='CorporateWellness_wrap'>
                <Image src='./images/corporateTriangke_img1.png' alt='DEMONSTRATED CARE' width="35px" />
                <Heading title='DEMONSTRATED CARE' variant='extrasmall' textAlign='center' />
                <Heading title='85%' variant='largheading' textAlign='center' />
                <Text title='of employees would be more likely to stay in a role if their employer took better care of their wellbeing.' textAlign='center' fontSize={{ sm: "10px", md: '12px', xl: "14px" }} width={{ sm: "35%", md: '60%' }} lineHeight='1.2' margin={{ lg: '-20px 0 0 0' }} />
              </Style.CorporateWellness_wrap>
            </GridItem>
            <GridItem position='relative'>
              <CircularProgress className='CorporateWellnessCircle CorporateCircle_color1' value={77} size='450px'>
              </CircularProgress>
              <Style.CorporateWellness_wrap className='CorporateWellness_wrap'>
                <Image src='./images/corporate_img2.png' alt='PSYCHOLOGICAL SAFETY' width="35px" />
                <Heading title='PSYCHOLOGICAL SAFETY' variant='extrasmall' textAlign='center' />
                <Heading title='77%' variant='largheading' textAlign='center' />
                <Text title="say they would consider leaving a company that didn't focus on their wellbeing." textAlign='center' fontSize={{ sm: "10px", md: '12px', xl: "14px" }} width={{ sm: "35%", md: '60%' }} lineHeight='1.2' margin={{ lg: '-20px 0 0 0' }} />
              </Style.CorporateWellness_wrap>
            </GridItem>
            <GridItem position='relative'>
              <CircularProgress className='CorporateWellnessCircle CorporateCircle_color2' value={83} size='450px'>
              </CircularProgress>
              <Style.CorporateWellness_wrap className='CorporateWellness_wrap'>
                <i class="bi bi-circle" style={{ fontSize: "24px" }}></i>
                <Heading title='SHARED VALUES' variant='extrasmall' textAlign='center' />
                <Heading title='83%' variant='largheading' textAlign='center' />
                <Text title='believe their wellbeing is just as important as their salary at work.' textAlign='center' fontSize={{ sm: "10px", md: '12px', xl: "14px" }} width={{ sm: "33%", md: '60%' }} lineHeight='1.2' margin={{ lg: '-20px 0 0 0' }} />
              </Style.CorporateWellness_wrap>
            </GridItem>
          </SimpleGrid>
        </Container>
      </Box>

      <Box padding={{ sm: "50px 0", md: '40px 0 80px' }} backgroundColor='#000'>
        <Container maxW='container.xl'>
          <Heading title='Join these industry leaders who offer Perpetua’s Corporate Offerings to their employees' variant='small' textAlign='center' color='#fff' />
          <SimpleGrid
            columns={{ sm: "1", md: "5" }}
            gap={7}
            justifyContent='center'
            alignItems='center'
            paddingTop={{ sm: "30px", md: "50px" }}
          >
            <GridItem>
              <Image src='./images/corporate_compayLogo1.png' alt='Join these industry' margin="0 auto" />
            </GridItem>
            <GridItem>
              <Image src='./images/corporate_compayLogo2.png' alt='Join these industry' margin="0 auto" />
            </GridItem>
            <GridItem>
              <Image src='./images/corporate_compayLogo3.png' alt='Join these industry' margin="0 auto" />
            </GridItem>
            <GridItem>
              <Image src='./images/53six.png' alt='Join these industry' margin="0 auto" />
            </GridItem>
            <GridItem>
              <Image src='./images/corporate_compayLogo5.png' alt='Join these industry' width={{ sm: "50%", md: "100%" }} margin="0 auto" />
            </GridItem>
          </SimpleGrid>
        </Container>
      </Box>

      <Flex flexDirection='column' padding={{ sm: '50px 0', md: '80px 0' }} position="relative">
        <Container maxW='90%'>
          <Testimonial headingWidth={{ sm: '97%', md: '90%', lg: '87%', xl: '85%' }} iconUri='/images/comment-quote-outline.svg' name='- Stephen Quinlivan (Partner A&L Goodbody) ' title={<Box fontSize={{ sm: "18px", lg: "26px" }}>
            We find there is definitely a desire amongst modern employees for more social outlets that do not involve alcohol and late nights – and these classes are perfect! The whole set up in Perpetua is first class – modern studios and equipment, great sound systems and lights, world class instructors and really friendly and helpful staff. We would highly recommend for your next team activity
          </Box>} />
        </Container>
      </Flex>

      <Box backgroundColor='black' padding={{ sm: '50px 0', md: '80px 0' }} borderBottom='1px solid #232323'>
        <Container maxW='container.xl'>
          <Style1.Wrapper>
            <Style1.LeftBox textAlign={{ sm: 'center', md: 'left' }}>
              <Heading
                variant="large"
                title='Get Started with'
                lineHeight='1'
                color='white'
              />
              <Heading
                variant="large"
                title='corporate'
                lineHeight='1'
                color='white'
              />
            </Style1.LeftBox>
            <Style1.RightBox>
              <Style1.VideoWrapper>
                <LazyLoad offset={300}>
                  <Box as='iframe' src='https://www.youtube.com/embed/8sFoRVcyE5s?feature=share' width={{ sm: '100%', md: '100%' }} height='550px' />
                </LazyLoad>
                <Style1.TextWrapper>
                  <Heading
                    variant="smallheading"
                    title='Gym + Coffee'
                    lineHeight='1'
                    color='white'
                    marginBottom='5px'
                  />
                </Style1.TextWrapper>
              </Style1.VideoWrapper>
              <Style1.VideoWrapper>
                <LazyLoad offset={300}>
                  <Box as='iframe' src='https://www.youtube.com/embed/6AfQQs0neAU?si=4rin6c11B1r5o5HQ' width={{ sm: '100%', md: '100%' }} height='550px' />
                </LazyLoad>
                <Style1.TextWrapper>
                  <Heading
                    variant="smallheading"
                    title='53Six'
                    lineHeight='1'
                    color='white'
                    marginBottom='5px'
                  />
                </Style1.TextWrapper>
              </Style1.VideoWrapper>
              <Style1.VideoWrapper>
                <LazyLoad offset={300}>
                  <Box as='iframe' src='https://www.youtube.com/embed/auOHN0DV5eM?si=Y9Ng9EeIHhKvyibG' width={{ sm: '100%', md: '100%' }} height='550px' />
                </LazyLoad>
                <Style1.TextWrapper>
                  <Heading
                    variant="smallheading"
                    title='Sculpted by Aimee'
                    lineHeight='1'
                    color='white'
                    marginBottom='5px'
                  />
                </Style1.TextWrapper>
              </Style1.VideoWrapper>
            </Style1.RightBox>
          </Style1.Wrapper>
        </Container>
      </Box>
      <VStack backgroundColor='black' padding={{ sm: '50px 0', md: '80px 0' }} borderBottom='1px solid #232323'>
        <Container
          display='flex'
          maxW='container.xl'
          flexDirection={{ sm: 'column', md: 'row' }}
          alignItems='top'
          alignContent='center'
          justifyContent='center'
          justifyItems='center'
        >
          <Box w={{ sm: '100%', md: '50%', lg: '40%' }} textAlign={{ sm: 'center', md: 'left' }} marginBottom={{ base: '50px', lg: '0' }} position={{ sm: 'relative', md: 'sticky' }} top={{ sm: '0px', md: '100px' }} height={{ sm: 'auto', md: '500px' }}>
            <Heading title='LOOKING FOR MORE INFORMATION' variant='extralarge' lineHeight='1' margin='0px 0px 40px' color='white'></Heading>
            <Heading title='The sky is the limit! We can create a bespoke package that suits the needs of your team. We are here to help – so get in touch and let’s get moving!' variant='smallheading' lineHeight='1.4' margin='0px 0px 30px' color='white'></Heading>
          </Box>
          <Box w={{ sm: '100%', md: '50%', lg: '60%' }}>
            <PrivateHireAndCorporateForm />
          </Box>
        </Container>
      </VStack>
    </>
  );
}

export default Corporate;