import React from 'react';
import {Flex,Container,Box} from '@chakra-ui/react';
import Heading from '../Heading/Heading';
// import ClassCard from '../Classcard/ClassCard';
import ClasscardV2 from '../Classcard/ClasscardV2';
import * as Style from './ClassListing.style';

function ClassListing(){

  return (
   <>
        <Flex 
            p={{sm:'30px 0px', md:'50px 0px'}}
            backgroundColor='black'
            borderTop='1px solid #232323'
            borderBottom='1px solid #232323'
        >
            <Container maxW={"container.xl"}>
                <Box textAlign='left'>
                    <Heading 
                        marginBottom='50px' 
                        variant="medium"
                        title="Make Your Move"
                        className='wow animate fadeInUp' 
                        color='#fff'
                        >
                    </Heading>
                </Box>
                <Style.ClasslistingBody>
                    <ClasscardV2
                        width={{lg:'31%',md:'46%',sm:'100%'}}
                        title='CROSSFIT'
                        subtitle='COACHING. COMMUNITY. PASSION'
                        imageUri='/images/homeCrossfit.webp'
                        link="/crossfit-dublin"
                        margin="0"
                        transform="uppercase"
                        alt="crossfit - perpetua fitness"
                        height='10%'
                        objectFit='cover'
                        objectPosition='center'
                    />
                    <ClasscardV2
                        width={{lg:'31%',md:'46%',sm:'100%'}}
                        title='STUDIOS'
                        subtitle='DUBLINS BEST BOUTIQUE STUDIO EXPERIENCE'
                        imageUri='/images/homeStudio.webp'
                        link="/fitness-classes-dublin"
                        margin={{lg:'60px 0px 0px', md:'60px 0px 0px', sm:'30px 0px 0px'}}
                        transform="uppercase"
                        alt="studios - perpetua fitness"
                        height='10%'
                        objectFit='cover'
                        objectPosition='center'
                    />
                    <ClasscardV2
                        width={{lg:'31%',md:'46%',sm:'100%'}}
                        title='Personal Training '
                        subtitle='WE KNOW EXACTLY WHAT IT TAKES TO GET RESULTS'
                        imageUri='/images/online_training.webp'
                        link="/personal-training-dublin"
                        margin={{lg:'120px 0px 0px', md:'0px', sm:'30px 0px 0px'}}
                        transform="uppercase"
                        alt="personal - perpetua fitness"
                        height='10%'
                        objectFit='cover'
                        objectPosition='center'
                    />
                    <ClasscardV2
                        width={{lg:'31%',md:'46%',sm:'100%'}}
                        title='JUSTGYM'
                        subtitle='FULLY EQUIPPED GYM'
                        imageUri='/images/just_gym_new.webp'
                        link="/luxury-gym-dublin-city-centre"
                        margin={{lg:'0px 0px 0px', md:'60px 0px 0px', sm:'30px 0px 0px'}}
                        transform="uppercase"
                        alt="justgym - perpetua fitness"
                        height='10%'
                        objectFit='cover'
                        objectPosition='center'
                    />
                    <ClasscardV2
                        width={{lg:'31%',md:'46%',sm:'100%'}}
                        title='Hyrox'
                        subtitle='THE WORLD SERIES OF FITNESS RACING'
                        imageUri='/images/homeHyrox.webp'
                        link="/hyrox-dublin"
                        margin={{lg:'60px 0px 0px', md:'60px 0px 0px', sm:'30px 0px 0px'}}
                        transform="uppercase"
                        alt="hyrox - perpetua fitness"
                        height='10%'
                        objectFit='cover'
                        objectPosition='center'
                    />
                    <ClasscardV2
                        width={{lg:'31%',md:'46%',sm:'100%'}}
                        title='Online Training'
                        subtitle='The Best Online Program By Experienced Coaches'
                        imageUri='/images/iphone.webp'
                        link="/online-gym-programming"
                        margin={{lg:'120px 0px 0px', md:'60px 0px 0px', sm:'30px 0px 0px'}}
                        transform="uppercase"
                        alt="online-programing - perpetua fitness"
                        height='10%'
                        objectFit='cover'
                        objectPosition='center'
                    />
                </Style.ClasslistingBody>

                {/* <Text 
                    textAlign="center" 
                    color='Grey' 
                    maxWidth= {{base:'95%', sm:'90%', xl:'70%'}}
                    fontFamily='poppins' 
                    m='0px auto'
                    className='wow animate fadeInUp' 
                    >
                   It is our strong belief that fitness and wellbeing is for everyone and should be inclusive and welcoming. We are committed to enhancing the lives of everyone who walks through our doors.
                </Text> */}

                {/* <SimpleGrid 
                    columns={{sm:'1',md:'2'}} 
                    columnGap={10} 
                    rowGap={2} 
                    width="full" 
                    marginTop={{base:'30px', sm:'50px', md:'77px'}}

                    >

                    <GridItem>
                        <ClassCard
                            title='CROSSFIT'
                            subtitle='COACHING. COMMUNITY. PASSION'
                            bg="url('/images/crossfit_new.jpg')"
                            link="/crossfit"
                            minHeight={{base:'350px', sm:'450px', md:'450px', lg:'550px',xl:'652px'}}
                        />
                      
                    </GridItem>

                    <GridItem mt={{base:'20px', sm:'40px', xl:'84px'}}>
                        <ClassCard
                            title='STUDIOS'
                            subtitle='Dublins best boutique studio experience'
                            bg="url('/images/studio.jpg')"
                            link="/studios"
                            transform="uppercase"
                            minHeight={{base:'350px', sm:'450px', md:'450px', lg:'550px',xl:'652px'}}
                        />
                    </GridItem>

                    <GridItem  mt={{base:'20px', sm:'40px', xl:'-40px'}}>
                        <ClassCard 
                            title='JUSTGYM'
                            subtitle=' FULLY EQUIPPED GYM '
                            bg="url('/images/just_gym_new.jpg')" 
                            link="/justgym"
                            minHeight={{base:'350px', sm:'450px', md:'450px', lg:'550px',xl:'652px'}}
                        />
                    </GridItem>

                    <GridItem mt={{base:'20px', sm:'40px', xl:'84px'}}>
                        <ClassCard 
                            title='PERSONAL TRAINING'
                            subtitle='Professional coaches with years of experience and dedicated to helping you get results '
                            bg="url('/images/personal_training.jpg')"
                            link="/personal-training"
                            transform="uppercase"
                            minHeight={{base:'350px', sm:'450px', md:'450px', lg:'550px',xl:'652px'}}
                         />
                    </GridItem>
                    
                </SimpleGrid> */}


            </Container>
        </Flex>
   </>
  )


}

export default ClassListing;
