export const defaultPriceBoxProps = {
    title: 'Heading',
    subheading:'Defult Subheading',
    currency:'€',
    priceValue:'000',
    time:'/Mo',
    buttonText:'Defult Button',
    color:'#fff',

    isHeading: true,
    issubHeading: true,
    isButton: true,
    isDescription:true,
}