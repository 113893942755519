import { Flex, Button, Box, Link } from "@chakra-ui/react";
import styled from "@emotion/styled/macro";

export const FilterWrapper = styled(Flex)((props) => ({
  flexDirection: "column",
  padding: "90px 12%",
  "@media screen and (max-width:1850px)": {
    padding: "90px 10%",
  },
  "@media screen and (max-width:1440px)": {
    padding: "90px 8%",
  },

  "@media screen and (max-width:1280px)": {
    padding: "90px 7%",
  },

  "@media screen and (max-width:991px)": {
    padding: "90px 30px",
  },
  "@media screen and (max-width:767px)": {
    padding: "20px 20px",
  },
}));
export const WraperBox = styled(Flex)((props) => ({
  position: "relative",
  "@media screen and (max-width:400px)": {
    justifyContent: "center",
  },
}));
export const ButtonWrapper = styled(Flex)((props) => ({
  gap: "0px",
  padding: "0px",
  justifyContent: "flex-start",
  background: "#ffffff",
  boxShadow: "0px 0px 61px -29px rgba(0,0,0,0.40)",
  margin: "50px 0 0",
  width: "23.5%",
  "@media screen and (max-width:1536px)": {
    width: "31.8%",
  },
  "@media screen and (max-width:991px)": {
    width: "48%",
  },
  "@media screen and (max-width:480px)": {
    width: "100%",
  },
}));

export const FilterButon = styled(Button)((props) => ({
  background: "transparent",
  display: "flex",
  alignItems: "center",
  fontFamily: "BlenderProBold",
  color: "black",
  textTransform: " uppercase",
  width: "100%",
  borderRadius: "0px",
  border: "0px",
  borderBottom: "1px solid #e9e9e9",
  padding: "0px 30px",
  height: "50px",
  justifyContent: "flex-start",
  fontSize: "20px",
  ":hover&": {
    background: "#D12C41",
    color: "white",
  },

  "@media screen and (max-width:480px)": {
    fontSize: "16px",
    padding: "0px 10px",
    minWidth: "100px",
  },
}));

export const TeamWrapper = styled(Box)((props) => ({
  gap: "30px",
  alignItems: "center",
  width: "100%",
  padding: "40px 0",
  // display: "grid",
  justifyContent:"center",
   alignContent: "center",
   flexWrap:"wrap",
  gridTemplateColumns: "1fr 1fr 1fr 1fr",

  "@media screen and (max-width:1536px)": {
    gridTemplateColumns: "1fr 1fr 1fr",
  },
  "@media screen and (max-width:991px)": {
    gridTemplateColumns: "1fr 1fr",
  },
  "@media screen and (max-width:480px)": {
    gridTemplateColumns: "1fr",
  },
}));

export const TeamCardWrapper = styled(Flex)((props) => ({
  flexDirection: "column",
  transition: "all 0.2s ease-in-out",
  position: "relative",
  ":after": {
    position: "absolute",
    left: "0",
    top: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "#d12c418c",
    content: '""',
    zIndex: "0",
    opacity: "0",
    transition: "all 0.2s ease-in-out",
  },
  ":hover": {
    ":after": {
      opacity: "1",
      transition: "all 0.2s ease-in-out",
    },
    ".LinkWraper": {
      visibility: "visible",
      transition: "all 0.2s ease-in-out",
      zIndex: "1",
    },

    ".TeamCardContent": {
      marginBottom: "60px",
      transition: "all 0.2s ease-in-out",
      position: "relative",
      zIndex: "1",
    },
  },
}));

export const TeamCard = styled(Link)((props) => ({
  // width: "100%",
  height: "380px",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
  display: "flex",
  alignItems: "end",
  transition: "all 0.2s ease-in-out",

  "@media screen and (max-width:1536px)": {
    height: "420px",
  },

}));

export const TeamCardContent = styled(Box)((props) => ({
  textDecoration: "none",
  padding: "20px",
  width: "100%",
  marginBottom: "0px",
  textAlign: "center",
  transition: "all 0.2s ease-in-out",
  p: {
    display: "inline-block",
    padding: "3px 5px",
  },
}));

export const LinkWraper = styled(Box)((props) => ({
  position: "absolute",
  bottom: "0px",
  margin: "auto",
  width: "100%",
  textAlign: "center",
  padding: "10px 0px",
  background: "#000",
  visibility: "hidden",
  ":hover": {
    background: "#D12C41",
    transition: "all 0.2s ease-in-out",
  },
  button: {
    ":hover": {
      color: "white",
    },
  },
}));

export const SmallWraper = styled(Box)((props) => ({
  width: "23.5%",
  ".-small-dropdown-button": {
    background: " #000",
    color: " #fff",
    fontSize: "16px",
    borderRadius: "0",
    ":hover": {
      background: "#D12C41",
    },
    span: {
      color: "#fff",
      fontSize: "20px",
    },
  },
  "@media screen and (max-width:767px)": {
    ".-small-dropdown-button": {
      height: "50px",
    },
  },
  "@media screen and (max-width:1536px)": {
    width: "31.8%",
  },
  "@media screen and (max-width:991px)": {
    width: "48%",
  },
  "@media screen and (max-width:480px)": {
    width: "100%",
  },

}));
